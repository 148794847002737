import { Box, Stack, Typography } from '@mui/material'
import { ReactComponent as ConfirmCalendarIcon } from 'assets/ConfirmCalendar.svg'

import UpcomingAppointmentCard from './UpcomingAppointmentCard'
import NoRecords from './NoRecords'
import { Strings } from 'utils/Strings'
import IconGrid from 'components/data-display/IconGrid/IconGrid'
import InfoAccordion from 'components/accordions/InfoAccordion'

import Plus from 'assets/Plus'
import Minus from 'assets/Minus'
import User from 'assets/User'
import { useEffect, useState } from 'react'
import ConfirmDialog from 'components/ConfirmDialog'
import TrashIcon from 'assets/TrashIcon'
import AppointmentDetails from './AppointmentDetails'
import { useLazyPostLocationBySiteIdQuery } from 'store/services/locationSiteIdApi'
import Text from 'components/Text'
import { usePutUpdateAppointmentDetailsMutation } from 'store/services/appointmentApi'
import Toast from 'components/feedback/toast/Toast'
import { AppointmentStatus } from 'utils/constants/status-constants'
import { useUpdateDeviceHistoryMutation } from 'store/services/deviceApi'
import { DEVICE_HISTORY_STATUSES } from 'utils/constants/common.constants'

const AppointmentList = ({ appointments = [], getData }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const onClickHandlerExpand = (event, isExpanded) => {
		setIsExpanded(isExpanded)
	}

	const [openDialog, setOpenDialog] = useState(false)
	const [openToast, setOpenToast] = useState(false)

	const handleCloseToast = () => {
		setOpenToast(false)
	}

	const handleCancelAppointment = (appointment) => {
		setOpenDialog(true)
		setSelectedAppointment(appointment)
	}

	const onClickHandlerClose = () => {
		setOpenDialog(false)
	}

	const [putCancelAppointment] = usePutUpdateAppointmentDetailsMutation();
	const [updateDeviceHistory] = useUpdateDeviceHistoryMutation();

	const onClickHandlerSubmit = () => {
		putCancelAppointment({
			id: selectedAppointment?.activityId,
			appointmentStatus: AppointmentStatus.CUSTOMER_CANCELLATION,
		}).then(() => {
			setOpenDialog(false)
			setOpenToast(true);
			if (selectedAppointment?.deviceHistoryId) {
				updateDeviceHistory({
					id: selectedAppointment?.deviceHistoryId,
					StatusReason: DEVICE_HISTORY_STATUSES.Delete,
				})
			}
			setTimeout(() => {
				getData();
			}, 1000)
		})
	}

	const [showApptDetails, setShowApptDetails] = useState(false)
	const [selectedAppointment, setSelectedAppointment] = useState()

	const handleClickApptDetails = (appointment) => {
		setShowApptDetails(true)
		setSelectedAppointment(appointment)
	}

	const [postLocationBySiteId, { data }] = useLazyPostLocationBySiteIdQuery('')

	useEffect(() => {
		if (showApptDetails && selectedAppointment?.locations?.siteIdGuid) {
			postLocationBySiteId({
				site_id: [selectedAppointment.locations.siteIdGuid]
			})
		}
	}, [postLocationBySiteId, showApptDetails, selectedAppointment])

	
	return (
		<Stack>
			<IconGrid
				mb={2}
				muiIcon={
					<Box sx={{ paddingTop: '.2rem' }}>
						<ConfirmCalendarIcon />
					</Box>
				}>
				<Text.Bold
					pl={1}
					sx={{
						fontSize: {
							sm: '1.1rem',
							md: '1.3rem'
						}
					}}
					i18nKey="LBL_YOUR_UPCOMING_APPOINTMENTS"
				/>
			</IconGrid>
			{appointments.length > 0 ? (
				<Stack>
					{appointments.map((appointment, index) => {
						return (
							<UpcomingAppointmentCard key={index} id="section-to-print">
								<UpcomingAppointmentCard.Header
									index={index}
									appointment={appointment}
									handleClickApptDetails={() =>
										handleClickApptDetails(appointment)
									}
								/>
								<UpcomingAppointmentCard.Content appointment={appointment} />
								<UpcomingAppointmentCard.Actions
									appointment={appointment}
									onCancelAppointment={() => {
										handleCancelAppointment(appointment)
									}}
								/>
							</UpcomingAppointmentCard>
						)
					})}
					<Box mt={2} mb={3}>
						<InfoAccordion
							infoAccordionId="standard-prep"
							infoAccordionHeader={Strings.STANDARD_PREP_HEADER}
							infoAccordionHeaderIcon={<User fillColor="#13499F" />}
							expandIcon={
								!isExpanded ? (
									<Plus fillColor="#13499F" />
								) : (
									<Minus fillColor="#13499F" />
								)
							}
							onChangeHandler={onClickHandlerExpand}
							isExpanded={isExpanded}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: Strings.STANDARD_PREP_BODY,
								}}
							></div>
						</InfoAccordion>
					</Box>
				</Stack>
			) : (
				<NoRecords noRecordsMessage={Strings.NO_RECORDS_UPCOMING_APPOINTMENT} />
			)}
			<ConfirmDialog
				confirmDialogId="cancel-appt-modal"
				openDialog={openDialog}
				i18nKeyForHeader="LBL_CANCEL_APPOINTMENT"
				confirmDiaglogHeaderIcon={<TrashIcon fillColor="#000" />}
				onClickHandlerClose={onClickHandlerClose}
				onclickHandlerSubmit={onClickHandlerSubmit}
			>
				<Text variant="paragraph2" i18nKey="QUERY_APPOINTMENT_BODY" />
			</ConfirmDialog>
			<AppointmentDetails
				id="appointment-dialog"
				fullWidth
				maxSize="lg"
				appointment={selectedAppointment}
				showApptDetails={showApptDetails}
			>
				<AppointmentDetails.Title
					appointment={selectedAppointment}
					onClose={() => setShowApptDetails(false)}
				/>
				<AppointmentDetails.Content appointment={selectedAppointment} />
				<AppointmentDetails.Action
					appointment={selectedAppointment}
					onCancelAppointment={onClickHandlerSubmit}
				/>
				<AppointmentDetails.Location
					appointment={selectedAppointment}
					location={data}
				/>
			</AppointmentDetails>
			<Toast
				open={openToast}
				alertCode={800}
				handleClose={handleCloseToast}
			/>
		</Stack>

	)
}

export default AppointmentList
