import { createApi } from '@reduxjs/toolkit/query/react'
import { newBaseQuery, transformErrorResponse } from './service-utils'

export const transformResponse = (data) => {
	return data || {}
}

export const authorizeStaff = createApi({
    baseQuery: newBaseQuery,
    reducerPath: 'authorizeStaff',
    endpoints: (build) => ({
        authorizeStaff: build.mutation({
            query: (staffEmail) => ({
                url: '/staff/GetAuthorizedSystemUser',
                method: 'POST',
                body: {
                    "PrimaryEmail": staffEmail
                }
            }),
			transformResponse,
			transformErrorResponse
        })
    }),
})

export const { useAuthorizeStaffMutation } = authorizeStaff