import { createSlice } from '@reduxjs/toolkit';

export const appStateReducer = createSlice({
  name: 'appState',
  initialState: {},
  reducers: {
    saveState: (state, action) => {
      localStorage.setItem('appState', JSON.stringify(action.payload));
      return state;
    },
    loadState: (state) => {
      const savedState = localStorage.getItem('appState');
      if (savedState) {
        return JSON.parse(savedState);
      }
      return state;
    },
    deleteState: () => {
      localStorage.removeItem('appState');
    }
  },
});

export const { saveState, loadState } = appStateReducer.actions;
export default appStateReducer.reducer;
