import { Box, Grid } from "@mui/material";
import { ReactComponent as CloseTooltip } from 'assets/FilterIcons/CloseTooltip.svg'
import { ReactComponent as SearchBlue } from 'assets/FilterIcons/SearchBlue.svg'
import Text from "components/Text";
import IconGrid from "components/data-display/IconGrid/IconGrid";
import useMobile from "hooks/useMobile";

const CustomTooltipTitle = ({ setIsTooltipOpen }) => {
    const isMobile = useMobile();

    return (
        <>
            <Grid
                container={!isMobile}
                justifyContent='space-between'
                sx={{
                    display: "flex",
                    flexDirection: 'row',
                    alignItems: 'start'
                }}
            >
                <IconGrid muiIcon={<SearchBlue />}>
                    <Text.Bold
                        variant="subtitle2"
                        sx={{
                            color: '#13499F',
                        }}
                        i18nKey='LBL_SPECIALTY_TOOLTIP_TITLE'
                    />
                </IconGrid>
                <CloseTooltip tabIndex={0} onKeyUpCapture={(event) => {
                    if (event.key === 'Enter') {
                        setIsTooltipOpen(false);
                    }
                }} onClick={() => setIsTooltipOpen(false)} />
            </Grid>

            <Box mt={2}>
                <Text
                    variant="paragraph3"
                    tabIndex={0}
                    sx={{ color: '#13499F' }}
                    i18nKey='LBL_SPECIALTY_TOOLTIP_TEXT'
                />
                <Text.Bold
                    variant="paragraph3"
                    tabIndex={0}
                    sx={{ color: '#13499F', textDecoration: 'underline' }}
                    i18nKey='LBL_SPECIALTY_TOOLTIP_TEXT_STYLED'
                />
            </Box>
        </>
    )
}

export default CustomTooltipTitle;