import { PRINT_APPOINTMENT_PDF } from "utils/constants/string.constants";
import useAppointmentActions from "./useAppointmentActions";

/* istanbul ignore next */
export const usePrintAppointment = (user, appointmentDetails) => {

    const { appointmentConfirmationPDF } = useAppointmentActions(user, appointmentDetails);

    const printAppointment = () => {
        var t = window.open("", "_blank", PRINT_APPOINTMENT_PDF.PRINT_SETTINGS);
        t.document.open();
        t.document.write(appointmentConfirmationPDF());
        t.document.close();
        t.onbeforeunload = function () {
            t.blur()
        }
        t.focus();
        setTimeout(() => {
            t.closed || (t.close(), t.window.close())
        }, 1000)
    }

    return printAppointment
}