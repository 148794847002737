import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { testAttr } from 'utils/test/test.utils'
import theme from 'utils/theme'

const InfoAccordion = ({
	infoAccordionId,
	infoAccordionHeader,
	infoAccordionHeaderIcon,
	children,
	expandIcon,
	onChangeHandler,
	isExpanded,
	...props
}) => {
	const StyledAccordion = styled(Accordion)({
		border: '1px solid',
		borderColor: theme.colors.blue[400],
		borderRadius: '4px',
		backgroundColor: 'unset',
		boxShadow: 'unset',
	})

	return (
		<StyledAccordion
			{...testAttr(infoAccordionId)}
			expanded={isExpanded}
			onChange={onChangeHandler}
		>
			<AccordionSummary
				expandIcon={expandIcon}
				aria-controls={`${infoAccordionId}-content`}
				id={infoAccordionId}
				tabIndex={0}
				sx={{
					padding: '8px 12px',
					backgroundColor: 'text.light',
					borderRadius: '4px',
					...(isExpanded && {
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					}),
					'& .MuiAccordionSummary-content': {
						margin: 0,
					},
					'& .MuiAccordionSummary-content.Mui-expanded': {
						margin: 0,
					},
					'&:focus-visible': {
						boxShadow: '1px 1px 6px 6px rgba(19, 73, 159, 0.25)',
					},
					'&:focus': {
						backgroundColor: 'text.light'
					}
				}}
				{...props}
			>
				<Stack direction="row"
					alignItems="center"
					sx={{ gap: '12px' }}>
					{infoAccordionHeaderIcon}
					<Typography variant="paragraph2"
						sx={{ color: theme.colors.blue[400] }}
					>
						{infoAccordionHeader}
					</Typography>
				</Stack>
			</AccordionSummary>

			<AccordionDetails
				{...testAttr(`${infoAccordionId}-body`)}
				sx={{
					backgroundColor: 'transparent',
				}}
			>
				{children}
			</AccordionDetails>
		</StyledAccordion>
	)
}

export default InfoAccordion
