import { createTheme } from '@mui/material/styles'

import { setupTheme } from './setupTheme'
import { typography } from './typography'

const theme = createTheme(setupTheme, {
	typography,
})

export default theme
