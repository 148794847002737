import { createApi } from '@reduxjs/toolkit/query/react'
import {
	CUSTOMER_PATH,
	SYNC_CUSTOMER_DATA,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'

export const transformResponse = (data) => {
	return data || {}
}

export const syncCustomerApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'mccpwtsyncup',
	endpoints: (build) => ({
		syncMccCustomerData: build.mutation({
			query: (data) => ({
				url: `${CUSTOMER_PATH}/${SYNC_CUSTOMER_DATA}`,
				method: 'POST',
				body: {
					...data,
				},
				transformErrorResponse
			}),
		}),
	}),
})

export const {
	useSyncMccCustomerDataMutation,
} = syncCustomerApi
