import { createTheme } from '@mui/material/styles'

import { colors } from './colors'
import { palette } from './palette'

import '@fontsource/poppins'

const BREAKPOINTS = {
	xs: 0,
	sm: 650,
	md: 1024,
	lg: 1376,
}

const setupTheme = createTheme({
	breakpoints: {
		values: BREAKPOINTS,
	},
	typography: {
		fontSize: 16,
		fontFamily: ['Poppins', 'sans-serif']
			.join(','),
	},
	palette,
	colors,
	overrides: {
		MuiButton: {
			root: {
				borderRadius: 50,
			},
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					scrollbarColor: "#6b6b6b #2b2b2b",
					"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
						backgroundColor: "#2b2b2b",
					},
					"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
						borderRadius: 8,
						backgroundColor: "#6b6b6b",
						minHeight: 24,
						border: "3px solid #2b2b2b",
					},
					"&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
						backgroundColor: "#959595",
					},
					"&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
						backgroundColor: "#959595",
					},
					"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
						backgroundColor: "#959595",
					},
					"&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
						backgroundColor: "#2b2b2b",
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true
			}
		}
	},


})

export { BREAKPOINTS, setupTheme }
