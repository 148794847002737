import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { visuallyHidden } from '@mui/utils'
import Text from 'components/Text'
import LifeLabs from 'assets/mcc-assets/lifelabs-logo.svg'
import strings from 'locales/en-US.json'
import { useSelector } from 'react-redux'
import './mcc.footer.scss';
import { MenuItem, OutlinedInput, Select, Typography } from '@mui/material'

/* istanbul ignore next */
const MCCFooter = ({ province = 'BC', supportedLngs }) => {
	const [selectedLangForSR, setSelectedLangForSR] = useState()
	const selectedProvince = useSelector((state) => state.selectedProvince)

	const { t, i18n } = useTranslation()

	const currYear = new Date().getFullYear()

	return (
		<div id="footer">
			<footer className="page-footer">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div>
								<Text.Bold
									variant="paragraph3Bold"
									i18nKey="FOOTER_ACKNOWLEDGEMENT_HEADING"
									sx={{
										color: '#5f5f5f'
									}}
								/>
							</div>

							<Text
								variant="paragraph3"
								i18nKey="FOOTER_ACKNOWLEDGEMENT_CONTENT"
								sx={{
									color: '#5f5f5f',
								}}
							/>
						</div>

						<div className="col-md-10 main">
							<ul className="list-inline">
								<li className="dropdown dropup">
									<i className="icon icon-exc-globe" aria-hidden="true"></i>

									<Text
										style={visuallyHidden}
										variant="paragraph2"
										i18nKey="FIELD_PREFERREDLANGUAGE"
									>
										{selectedLangForSR}
									</Text>

									<select
										aria-label={t('FIELD_PREFERREDLANGUAGE')}
										value={i18n.resolvedLanguage}
										onChange={(e) => {
											setSelectedLangForSR(e.target.value)
											i18n.changeLanguage(e.target.value)
										}}
										style={{
											color: '#103378',
											fontSize: '14px',
											fontWeight: 'bold',
											cursor: 'pointer'
										}}
										className='custom-select'
									>	
										{Object.entries(supportedLngs).map(([code, name]) => (
											<option value={code} key={code} 
												className='dropdownList'
											>
												{name}
											</option>
										))}
									</select>
								</li>

								<li>
									<a
										href="https://www.lifelabs.com/about-us/"
										target="_blank"
										title={t('LINK_ABOUTUS')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold variant="paragraph2" i18nKey="LINK_ABOUTUS" sx={{ fontSize: '14px' }} />
									</a>
								</li>

								<li>
									<a
										target="_blank"
										href="https://www.lifelabs.com/accessibility/"
										title={t('LINK_ACCESSIBILITY')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold variant="paragraph2" i18nKey="LINK_ACCESSIBILITY" sx={{ fontSize: '14px' }} />
									</a>
								</li>

								<li>
									<a
										target="_blank"
										href="https://www.lifelabs.com/privacy-policy/"
										title={t('LINK_PRIVACYPOLICY')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold variant="paragraph2" i18nKey="LINK_PRIVACYPOLICY" sx={{ fontSize: '14px' }} />
									</a>
								</li>

								<li>
									<a
										href="https://www.lifelabs.com/direct-services-tos/"
										target="_blank"
										title={t('LINK_TERMSOFUSE')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold variant="paragraph2" i18nKey="LINK_TERMSOFUSE" sx={{ fontSize: '14px' }} />
									</a>
								</li>

								<li>
									<a
										target="_blank"
										href="https://www.lifelabs.com/lifelabs-vulnerability-disclosure-program-policy/"
										title={t('LINK_REPORT_VULNERABILITY')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold
											variant="paragraph2"
											i18nKey="LINK_REPORT_VULNERABILITY"
											sx={{ fontSize: '14px' }}
										/>
									</a>
								</li>

								<li>
									<a
										href={selectedProvince.toUpperCase() === 'BC' ? (`${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}` + "/support") : (`${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}` + "/support")}
										title={t('LINK_SUPPORT')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold
											variant="paragraph2"
											i18nKey="LINK_SUPPORT"
											sx={{ fontSize: '14px' }}
										/>
									</a>
								</li>

								<li>
									<a
										href={selectedProvince.toUpperCase() === 'BC' ? (`${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}` + "/faq") : (`${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}` + "/faq")}
										title={t('LINK_FAQ')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold
											variant="paragraph2"
											i18nKey="LINK_FAQ"
											sx={{ fontSize: '14px' }}
										/>
									</a>

								</li>

								<li>
									<a
										href={selectedProvince.toUpperCase() === 'BC' ? (`${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}` + "/sitemap") : (`${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}` + "/sitemap")}
										title={t('LINK_SITEMAP')}
										rel="noreferrer"
										className='footer-links'
									>
										<Text.Bold
											variant="paragraph2"
											i18nKey="LINK_SITEMAP"
											sx={{ fontSize: '14px' }}
										/>
									</a>

								</li>
							</ul>
							{province !== 'BC' ? (
								<p className="xs-center">
									&copy; {currYear}
									<Text
										variant="paragraph2"
										i18nKey="FOOTER_COPYRIGHTEXCELLERIS"
										sx={{ fontSize: '14px', color: '#5f5f5f', }}
									/>
								</p>
							) : (
								<p className="xs-center">
									<Text variant="paragraph2" i18nKey="FOOTER_POWEREDBY" sx={{ fontSize: '14px', color: '#5f5f5f', }} />
									{' '}&copy; {currYear}{' '}
									<Text
										variant="paragraph2"
										i18nKey="FOOTER_COPYRIGHTECELLERISTECHNOLOGY"
										sx={{ fontSize: '14px', color: '#5f5f5f', }}
									/>
								</p>
							)}
						</div>

						<div className="col-md-2">
							<p>
								<a
									target="_blank"
									href="http://www.lifelabs.com/"
									className="no-icon"
									rel="noreferrer"
								>
									<img
										title="lifelabs.com website"
										src={LifeLabs}
										alt={strings.FOOTER_POWEREDBYLIFELABS}
										className="powered-by"
									/>
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default MCCFooter
