import React from "react";
import Text from 'components/Text'
 
const UnauthorizedPage = () => {
  return (
    <div>
      <Text variant="h1" i18nKey="UNAUTHORIZED"/>
      <Text variant="paragraph1" i18nKey="UNAUTHORIZED_TEXT"/>
    </div>
  );
};

export default UnauthorizedPage;