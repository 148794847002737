import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './service-utils'
import { SITES_PATH } from './url'

/* istanbul ignore next */
export const transformResponse = (data) => {
	return data || {}
}

/* istanbul ignore next */
export const pscWaitTimeApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'daysheet',
	endpoints: (build) => ({
		getWaitTime: build.query({
			query: (data) => ({
				url: SITES_PATH + '?siteId=' + data.siteIdGUID,
				method: 'POST',
				body: { 
					siteId: data.siteId,
					scheduleDate: data.scheduleDate
				 },
			}),
			transformResponse		
		}),
	}),
})

export const { useLazyGetWaitTimeQuery } = pscWaitTimeApi
