import { render } from '@testing-library/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { ThemeProvider } from '@mui/material/styles'

import { store } from 'store'

import theme from 'utils/theme'

/**
 * Adds a 'data-test' attribute only when in test or dev enviorment
 * @example
 * <h1 {...testAttr(name)} />
 *
 * @param {string} val
 *
 * @returns <h1 data-test='name' />
 */
export const testAttr = (val) => {
	return process.env.NODE_ENV === 'production' ? {} : { 'data-testid': val }
}

/**
 * Wraps the custom component in a MUI themeprovider
 * @example
 * customRender(<h1 />)
 *
 * @param {Component} children
 *
 * @returns <customRender><h1 /></customRender>
 */
const AllTheProviders = ({ children }) => {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</Provider>
	)
}

export const customRender = (ui, options) =>
	render(ui, {
		wrapper: AllTheProviders,
		...options,
	})

export const renderWithRouter = (component) => {
	return customRender(<BrowserRouter>{component}</BrowserRouter>)
}

export function setupApiStore(api) {
	const getStore = () =>
		configureStore({
			reducer: combineReducers({
				[api.reducerPath]: api.reducer,
			}),
			middleware: (gdm) => {
				const tempMiddleware = gdm({
					serializableCheck: false,
					immutableCheck: false,
				}).concat(api.middleware)

				return tempMiddleware
				// return tempMiddleware
				// 	.concat(middleware?.concat ?? [])
				// 	.prepend(middleware?.prepend ?? [])
			},
			enhancers: (gde) =>
				gde({
					autoBatch: false,
				}),
		})

	const initialStore = getStore()
	const refObj = {
		api,
		store: initialStore,
	}
	const store = getStore()
	refObj.store = store

	return refObj
}

export function Wrapper(props) {
	return <Provider store={store}>{props.children}</Provider>
}

export const mockSuccesfulResponse = (returnBody, status = 200) => {
	global.fetch = jest.fn().mockImplementationOnce(() => {
		return new Promise((resolve, reject) => {
			resolve({
				ok: true,
				status,
				json: () => {
					return returnBody ? returnBody : {}
				},
			})
		})
	})
}
