import React from "react";
import Text from 'components/Text'
 
const FileNotFoundPage = () => {
  return (
    <div>
      <Text variant="h1" i18nKey="FILE_NOT_FOUND"/>
      <Text variant="paragraph1" i18nKey="FILE_NOT_FOUND_TEXT"/>
    </div>
  );
};

export default FileNotFoundPage;