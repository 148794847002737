import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import { useAuth } from 'hooks/useAuth'
import { useLazyGetMCCCustomerDataQuery } from 'store/services/mccCustomerApi'
import { setJWTToken, setIsAuthorized, setIDToken } from 'store/reducers/authReducer'
import { removeAuthToken, removeItemFromBrowserStorage, saveTokenType, setAuthToken } from 'utils/common.utils'
import { updateSelectedProvince } from 'store/reducers/selectedProvinceReducer'
import { TOKEN_TYPE } from 'utils/constants/common.constants'
import { APPOINTMENTS, MCC_CONFIRM_APPT } from 'utils/constants/routes-constants'
import { saveState } from 'store/reducers/appStateReducer'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'
import { APPOINTMENT_STATES, resetAppointReserveId, resetAppointment, updateAppointmentDiscardedStatus, updateAppointmentState } from 'features/appointment/appointmentSlice'
import { resetFilters } from 'features/filters/filterSlice'

const LoginReturn = ({ redirectTo }) => {
	
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const auth = useAuth()
	const [getMCCCustomerData, data, error] = useLazyGetMCCCustomerDataQuery('')
	const applicationSettings = useSelector(
		(state) => state.config?.applicationSettings
	)

	const state = useSelector(state => state);
	const hasLogoutInitiated = useRef(false);
	const idToken = useSelector(
		(state) => state.auth?.idToken
	)

	const [id4RedirectionComponent, setId4RedirectionComponent] = useState(localStorage.getItem('redirectionPathName') || null);

	useEffect(()=>{
		if(id4RedirectionComponent && state?.appointment?.appointmentReserveId === '' && !hasLogoutInitiated?.current && id4RedirectionComponent === MCC_CONFIRM_APPT){
			dispatch(saveState(state));
			hasLogoutInitiated.current = true;
			window.location.href = MCCRedirectionUrls.Logout.replace('{ID_TOKEN}',idToken).replace('{LOGOUT_REDIRECTION_URL}',process.env.REACT_APP_BASE_URL_PROD);
		}
	},[state])

	const apptSessionExpired = async () => {
		await Promise.all([
			dispatch(updateAppointmentState(APPOINTMENT_STATES[4])),
			dispatch(updateAppointmentDiscardedStatus(true)),
			dispatch(resetAppointReserveId()),
			dispatch(resetAppointment()),
			dispatch(resetFilters())
		]);
		removeAuthToken();
		removeItemFromBrowserStorage('user');
	}

	useEffect(() => {
		sessionStorage.removeItem('prov');
		if (location.hash) {
			const params = queryString.parse(location.hash)
			saveTokenType(TOKEN_TYPE.ID4)
			dispatch(setJWTToken(params.access_token))
			setAuthToken(params?.access_token, params?.expires_in)
			dispatch(setIDToken(params.id_token))
			// GET User from MCC
			getMCCCustomerData()
		}

		if(location.search){
			const query = location?.search?.split('?')[1];
        	const params = queryString.parse(query);
    		const provValue = params.prov;
			dispatch(updateSelectedProvince(provValue))
		}

	}, [
		auth,
		dispatch,
		getMCCCustomerData,
		location.hash,
	])

	useEffect(()=>{
		if (data?.data?.entity) {
			auth.login(data?.data?.entity || error)
			dispatch(setIsAuthorized(true));
			const redirectionPathName = localStorage.getItem('redirectionPathName');
			localStorage.removeItem('redirectionPathName');
			if(localStorage.getItem('reservationCreationTime')){
				const currentDate = new Date();
				const differenceInMilliseconds = new Date(localStorage.getItem('reservationCreationTime')).getTime() - currentDate.getTime();
				const differenceInMinutes = (differenceInMilliseconds / (1000 * 60));
				if((differenceInMinutes + Number(applicationSettings?.reservationTimerDuration)) > 0 ){
					redirectTo && navigate(redirectionPathName, { replace: true })
				} else {
					apptSessionExpired();
				}
				localStorage.removeItem('reservationCreationTime')
			} else {
				redirectTo && navigate(redirectionPathName, { replace: true })
			}
		}
	},[
		data?.data?.entity,
		data.isSuccess,
		navigate,
		error,
		redirectTo
	])
}

export default LoginReturn
