import { Button, Drawer, Grid, Radio, Stack, Tooltip, Typography } from '@mui/material'
import SortDropdown from 'components/SortDropdown'
import React, { useEffect, useState } from 'react'
import { STRINGS } from 'utils/constants/string.constants'
import SortIcon from 'assets/Sort'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import MobileViewFilters from './MobileViewFilters'
import { useDispatch, useSelector } from 'react-redux'
import CustomTooltipTitle from './CustomTooltip'
import theme from 'utils/theme'
import { updateSortCriteria } from './SortCriteriaSlice'
import Toast from 'components/feedback/toast/Toast'

const SortAndFilterMobileView = ({
    locationList,
}) => {

    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const appliedFilters = useSelector((state) => state.filter);
    const [isTooltipOpen, setIsTooltipOpen] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        let dateFilter = appliedFilters?.date ? 1 : 0;
        let servicesOfferedFilter = appliedFilters?.services?.length;
        let specialityTestFilter = appliedFilters?.specialityTests?.id ? 1 : 0;
        let appliedFilterCount = dateFilter + servicesOfferedFilter + specialityTestFilter;
        setAppliedFilterCount(appliedFilterCount);
    }, [appliedFilters])

    const sortCriteria = useSelector(state => state.sortCriteria);
    const [radioSelected, setRadioSelected] = useState(false);
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                backgroundColor: "#DDE5F1",
                padding: "16px"
            }}
        >
            <Grid item>
                {/* {
                    locationList?.locations?.length > 0 ? ( */}
                        <SortDropdown
                            dropdownId="sortDropdown"
                            dropdownLabel={STRINGS.SORT}
                            dropdownItems={STRINGS.SORT_OPTIONS}
                            dropdownIcon={<SortIcon fillColor="#13499F" />}
                            selectedOption={sortCriteria?.sortCriteria}
                            dropdownControl={
                                <Radio
                                    onChange={(e) => {
                                        dispatch(updateSortCriteria(e.target.value))
                                        setRadioSelected(true)
                                    }}
                                    sx={{
                                        color: 'primary.dark'
                                    }}
                                />
                            }
                        />
                    {/* ) : null
                } */}

            </Grid>
            <Grid item>
                <Tooltip
                    role="tooltip"
                    arrow
                    placement='auto'
                    open={isTooltipOpen}
                    title={<CustomTooltipTitle setIsTooltipOpen={setIsTooltipOpen} />}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: '#D4E4FE',
                                color: '#13499F',
                                boxShadow: theme.shadows[5],
                                fontSize: 11,
                                minWidth: {
                                    xs: "328px",
                                    md: "400px"
                                },
                                margin: {
                                    xs: "20px",
                                    md: "0px"
                                },
                                padding: '24px',
                                '& .MuiTooltip-arrow': {
                                    color: '#D4E4FE',
                                },
                            },
                        },
                        popper: {
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    minWidth: '100px',
                                    marginTop: '10px'
                                },
                            }
                        }
                    }}
                    PopperProps={{
                        sx: {
                            paddingTop: '16px',
                        }
                    }}
                >
                    <Button
                        role="button"
                        id={'filter-btn'}
                        aria-haspopup="true"
                        onClick={() => {
                            setShowFilters(!showFilters)
                            setIsTooltipOpen(false);
                        }}
                        endIcon={<FilterIcon />}
                        sx={{
                            minWidth: '5rem',
                            borderColor: 'info.border',
                            backgroundColor: "primary.light",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "4px",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                        }}
                        data-testid="filter-button"
                    >
                        <Typography
                            variant="paragraph2"
                            sx={{
                                color: "#13499F"
                            }}
                        >
                            Filters ({appliedFilterCount})
                        </Typography>
                    </Button>
                    <Drawer
                        open={showFilters}
                        onClose={() => setShowFilters(false)}
                        PaperProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        ModalProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    >
                        <MobileViewFilters
                            setShowFilters={setShowFilters}
                        />
                    </Drawer>
                </Tooltip>
            </Grid>
            {radioSelected && (
                <Toast
                    open={true}
                    alertCode={500}
                    handleClose={() => setRadioSelected(false)}
                    autoHideDuration={6000}
                />
            )}
        </Stack>
    )
}

export default SortAndFilterMobileView