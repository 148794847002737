import { Typography } from "@mui/material";
import useMobile from "hooks/useMobile";
import { Strings } from "utils/Strings";

const Distance = ({ distance, ...props }) => {
    const isMobile = useMobile();
    return (
        <>
            {distance ?
                <Typography {...props} sx={{
                    typography: { xs: 'paragraph1', md: 'caption' },
                    fontSize:{
                        xs: "12px"
                    },
                    lineHeight: {
                        xs: "20px",
                    },
                    fontWeight:{
                        xs: "bold"
                    }
                }}>
                    {distance}{isMobile ? "km" : Strings.KM_AWAY}
                </Typography>
                : <></>}
        </>
    );
}

export default Distance;
