import { useEffect, useState } from 'react'

/* istanbul ignore next */
const useGeoLocation = () => {
	const [geoLocationState, setGeoLocationState] = useState({
		loading: true,
		coordinates: null,
		errorMessage: null,
	})

	const onSuccess = (position) => {
		const {
			coords: { latitude, longitude },
		} = position

		fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GEO_CODING_API_KEY}`
		)
			.then((res) => res.json())
			.then((address) => {
				if (address.status === 'OK') {
					setGeoLocationState({
						loading: false,
						geoAddress: address.results[0].formatted_address,
						coordinates: { latitude, longitude },
						errorMessage: null,
					})
				}
			})
	}

	const onError = () => {
		setGeoLocationState({
			loading: false,
			coordinates: null,
			errorMessage: 'Unable to retrieve your location',
		})
	}

	const findMyLocation = () => {
		if (!navigator.geolocation) {
			setGeoLocationState({
				loading: false,
				coordinates: null,
				errorMessage: 'Geolocation not supported',
			})
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError)
		}
	}

	const findLocationForAddress = (address) => {
		if (address) {
			fetch(
				`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEO_CODING_API_KEY}`
			)
				.then((res) => res.json())
				.then((address) => {
					if (address.status === 'OK') {
						const results = address.results[0]
						const location = results.geometry?.location

						setGeoLocationState({
							loading: false,
							geoAddress: results.formatted_address,
							coordinates: {
								latitude: location.lat,
								longitude: location.lng
							},
							errorMessage: null,
						})
					} else if(address.status === 'ZERO_RESULTS'){
						setGeoLocationState({
							loading: false,
							coordinates: null,
							errorMessage: 'Unable to retrieve your location',
						})
					}
				})
		}

	}

	return { findMyLocation, findLocationForAddress, geoLocationState }
}

export default useGeoLocation
