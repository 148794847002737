import { Box } from '@mui/material';
import NoPhotoImg from 'assets/PSCNoImage.png'

const NoPhoto = () => {
    return (
        <Box>
            <img src={NoPhotoImg} alt="LifeLabs" style={{ width: '100%' }} />
        </Box>
    )
}

export default NoPhoto;
