import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    address: '',
    limit: 10,
    offset: 0,
    source: 'USER'
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateStaffCustomerId: (state, action) => {
            state.customerId = action.payload
        },
        updateStaffSiteId: (state, action) => {
            state.siteId = action.payload
        },
        updateStaffAddress: (state, action) => {
            state.staffAddress = action.payload
        },
        searchSource: (state, action) => {
            state.source = action.payload
        },
        updateSearch: (state, action) => {
            state.address = action.payload
        },
    }
})

export const {
    // nextPage,
    updateSearch,
    searchSource,
    updateStaffCustomerId,
    updateStaffSiteId,
    updateStaffAddress } = searchSlice.actions

export default searchSlice.reducer