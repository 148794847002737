import { Grid, Typography } from "@mui/material";

export const MyVisitTM = ({ prependString = '', appendString = '', ...props }) => {
  return (
    <Typography
      variant={'paragraph3'}
      sx={{ 
        paddingRight: '0.5rem',  
        fontWeight: '600', 
        fontSize: '1rem', 
        lineHeight: '1.5rem',
        ...props.sx 
      }}
      aria-label='my-visit-label'
      {...props}
    >
      {prependString}
      MyVisit
      <sup
        style={{ 
          textDecoration: 'none !important',
          fontSize: '12px'
        }}
        aria-label='my-visit-label-tm'
        role='superscript'
      >
        TM
      </sup>
      {appendString}
    </Typography>
  );
}

export const LeftBorderGrid = ({ children, ...props }) => {
  return <Grid {...props}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      borderLeft: "1px solid #000",
      paddingLeft: "12px", ...props.sx
    }}>
    {children}
  </Grid>
}