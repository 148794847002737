import { Typography } from '@mui/material';
import { Trans } from 'react-i18next'

const TextBold = ({ children, sx, ...props }) => {
    return <Text
        type='bold'
        {...props}
        sx={{
            fontWeight: 600,
            ...sx
        }}
    >
        {children}
    </Text>
}

const Text = ({
    children,
    i18nKey,
    ...props }) => {

    return (
        <Typography {...props}>
            <Trans i18nKey={i18nKey}></Trans>
            {children}
        </Typography>
    );
}

Text.Bold = TextBold
export default Text;
