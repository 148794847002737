import { Typography } from "@mui/material";
import moment from "moment";
import { VERTICAL_BAR } from "utils/constants/location-constants";
import { DATE_FORMAT, TIME_FORMAT } from "utils/constants/time-constants";
import { formatDateTimein12Hour } from "utils/dateTime.utils";


const DateTime = ({ date, time }) => {
    return (
        <Typography
            variant="subtitle2"
            sx={{ fontWeight: '600' }}>
            {moment(date).format(DATE_FORMAT)}
            {VERTICAL_BAR}
            {formatDateTimein12Hour(time)}
        </Typography>
    );
}

export default DateTime;
