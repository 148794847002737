import React from "react";
import Text from 'components/Text'
 
const ServiceUnavailablePage = () => {
  return (
    <div>
      <Text variant="h1" i18nKey="SERVICE_UNAVAILABLE"/>
      <Text variant="paragraph1" i18nKey="SERVICE_UNAVAILABLE_TEXT"/>
    </div>
  );
};

export default ServiceUnavailablePage;