import Header from 'features/header/header'

import Footer from 'features/footer'

import { AuthProvider } from 'hooks/useAuth'
import { useOutlet } from 'react-router-dom'

const AuthLayout = () => {
	const outlet = useOutlet()
	
	return (
		<AuthProvider>
			<Header />
				{outlet}
			<Footer />
		</AuthProvider>
	)
}

export default AuthLayout
