import { createApi } from '@reduxjs/toolkit/query/react'
import {
	LOCATION_PATH,
	TIME_PATH,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'
import { formatDateTimein12Hour } from 'utils/dateTime.utils'

/* istanbul ignore next */
export const transformResponse = (data) => {
	if(data?.appointmentSlots && data?.appointmentSlots[0].slots){
		const formattedTimeSlots = data?.appointmentSlots?.map((appointmentSlot)=>{
			const formattedSlot = appointmentSlot.slots.map((slot)=>{
				slot['orgTime'] = slot?.time
				slot['time'] = formatDateTimein12Hour(slot?.time);
				return slot; 
			})
			return formattedSlot;
		})
		return formattedTimeSlots.flat()
	} else {
		return []
	}
}

export const locationTimeSlotsApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'locationTimeSlots',
	endpoints: (build) => ({
		postTimeslots: build.query({
			query: (body) => ({
				url: `${LOCATION_PATH}${TIME_PATH}`,
				method: 'POST',
				body: {
					site_id: [...(body.site_id)],
					date: [...(body.date)],
					tests: body?.tests?.id ? [body?.tests] : undefined
				},
			}),
			transformResponse,
			transformErrorResponse
		}),
	}),
})

export const { useLazyPostTimeslotsQuery } = locationTimeSlotsApi
export const resetTimeSlotsApi = locationTimeSlotsApi.util.resetApiState
