import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import CircleButton from 'components/buttons/CircleButton'
import RoundedButton from 'components/buttons/RoundedButton'

import { testAttr } from 'utils/test/test.utils'

import Text from 'components/Text'

const ConfirmDialog = ({
	confirmDialogId,
	openDialog,
	confirmDiaglogHeaderIcon,
	i18nKeyForHeader,
	confirmDialogHeader,
	children,
	onClickHandlerClose,
	onclickHandlerSubmit,
	i18nKeyForBtns = []
}) => {
	return (
		<Dialog
			id={confirmDialogId}
			open={openDialog}
			PaperProps={{
				sx: {
					borderRadius: '1rem',
					padding: {
						xs: "32px",
						md: "40px"
					},
					maxHheight: '241px',
					width: '700px',
				},
			}}
			sx={{ width: 'auto' }}
		>
			<DialogTitle sx={{ padding: 'unset', marginBottom: '16px' }}>
				<Stack
					justifyContent="space-between"
					alignItems="center"
					direction="row"
					sx={{ gap: '10px' }}
				>
					<Stack
						direction="row"
						alignItems="center"
						sx={{ gap: '10px' }}
					>
						{confirmDiaglogHeaderIcon}
						<Text
							i18nKey={i18nKeyForHeader}
							sx={{
								fontWeight: '600',
								fontSize: "1.5rem",
								lineHeight: "2.125rem"
							}}
						>
							{confirmDialogHeader}
						</Text>
					</Stack>

					<CircleButton
						{...testAttr(`${confirmDialogId}-closeBtn`)}
						aria-label="Close confirm dialog"
						onClick={() => onClickHandlerClose("close")}
					/>
				</Stack>
			</DialogTitle>

			<DialogContent sx={{ padding: 'unset', marginBottom: '40px' }}>
				{children}
			</DialogContent>


			<DialogActions sx={{ padding: 'unset', justifyContent: 'flex-start' }}>
				<Stack
					direction={{
						xs: "column",
						md: "row"
					}}
					gap={{
						xs: '24px',
					}}
					width={{
						xs: '100%',
					}}
				>
					<RoundedButton
						sx={{ 
							fontSize: '1rem', 
							marginRight: 2,
							width: {
								xs: '100%',
								md: '15rem'
							},
						}}
						onClick={onclickHandlerSubmit}
						{...testAttr(`${confirmDialogId}-yes-btn`)}
						i18nKey={i18nKeyForBtns[0] || 'BTN_YES'}
					/>

					<RoundedButton
						variant="outlined"
						sx={{ 
							fontSize: '1rem',
							width: {
								xs: '100%',
								md: '15rem'
							},
						}}
						onClick={onClickHandlerClose}
						{...testAttr(`${confirmDialogId}-no-btn`)}
						i18nKey={i18nKeyForBtns[1] || 'BTN_NO'}
					/>
				</Stack>
			</DialogActions>

		</Dialog>
	)
}

export default ConfirmDialog
