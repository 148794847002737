import IconGrid from 'components/data-display/IconGrid/IconGrid'
import { ReactComponent as ConfirmCalendarIcon } from 'assets/ConfirmCalendar.svg'
import {
	Alert,
	Box,
	Chip,
	Dialog,
	DialogTitle,
	Grid,
	Stack,
	Typography,
} from '@mui/material'
import { testAttr } from 'utils/test/test.utils'
import theme from 'utils/theme'
import CircleButton from 'components/buttons/CircleButton'
import Text from 'components/Text'
import DateTime from 'components/data-display/Date-Time'
import NoPhoto from 'components/data-display/NoPhoto'
import { Strings } from 'utils/Strings'
import AddToCalendarButton from 'components/buttons/AddToCalendarButton/AddToCalendarButton'
import RoundedButton from 'components/buttons/RoundedButton'
import TrashIcon from 'assets/TrashIcon'
import IconLink from 'components/data-display/IconLink'
import { ReactComponent as EmailShareIcon } from 'assets/EmailShareIcon.svg'
import { ReactComponent as PrintDetailsIcon } from 'assets/PrintDetailsIcon.svg'
import { calculateDurationInMins } from 'utils/dateTime.utils'
import { getLocation, getAppointmentType } from 'utils/common.utils'
import Hours from 'features/searchresults/location-details/Hours'
import moment from 'moment'
import Availability from 'features/searchresults/location/Availability'
import { useSaveEmlFile } from 'hooks/useSaveEmlFile'
import { usePrintAppointment } from 'hooks/usePrintAppointment'

const AppointmentDetails = ({ showApptDetails, children, ...props }) => {
	return (
		<Dialog open={showApptDetails} sx={{ width: 'auto' }} {...props}>
			{children}
		</Dialog>
	)
}

const AppointmentDetailsTitle = ({ appointment = {}, onClose, type }) => {
	return (
		<DialogTitle sx={{ backgroundColor: 'primary.light' }}>
			<Stack>
				<Stack
					direction="row"
					sx={{
						alignItems: 'center',
						marginBottom: {
							xs: '0rem',
							md: '1rem',
						},
						justifyContent: 'space-between',
					}}
				>
					<IconGrid item muiIcon={<ConfirmCalendarIcon />}>
						<Text
							i18nKey="LBL_APPOINTMENT_INFORMATION"
							variant="subtitle1"
							sx={{ paddingLeft: '0.36rem', fontWeight: '600' }}
						/>
					</IconGrid>
					<Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
						<Typography variant="caption" sx={{ marginRight: '1rem' }}>
							Confirmation # {appointment.bookingConfirmationNumber}
						</Typography>
					</Box>
					<Box>
						<CircleButton
							onClick={onClose}
							title="Close Appointment Details"
							circleButtonId="close-appt-details"
							aria-label="Close confirm dialog"
						/>
					</Box>
				</Stack>
				<Box sx={{ display: { xs: 'inherit', md: 'none' } }}>
					<Typography variant="caption" sx={{ marginRight: '1rem' }}>
						Confirmation # {appointment.bookingConfirmationNumber}
					</Typography>
				</Box>
				{type === 'CANCELLED' && (
					<Grid container alignItems={'center'}>
						<Chip
							label={'Cancelled'}
							sx={{
								width: 'fit-content',
								height: '30px',
								borderRadius: '4px',
								border: '1px solid',
								borderColor: 'error.main',
								color: 'error.main',
								background: 'white',
								marginRight: '1rem',
							}}
						/>
						<Typography variant="caption">
							on {moment(appointment?.dateCancelled).format('MMM DD, YYYY')}
						</Typography>
					</Grid>
				)}
			</Stack>
		</DialogTitle>
	)
}

const AppointmentDetailsContent = ({ appointment = {} }) => {
	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			justifyContent="space-between"
			className="appointment-dialog-content"
			sx={{ backgroundColor: 'primary.light', padding: '0 1.5rem' }}
		>
			<Stack sx={{ paddingLeft: '1rem', borderLeft: '1px solid gray' }}>
				<DateTime
					date={appointment.appointmentDate}
					time={appointment.startTime}
				/>
				<Text.Bold variant="paragraph3" sx={{ margin: '0.5rem 0' }}>
					{appointment.contact?.firstName || ''}{' '}
					{appointment.contact?.lastName || ''}
				</Text.Bold>
				<Typography variant="paragraph3" sx={{ margin: '0.5rem 0' }}>
					{getAppointmentType(appointment)}
					&nbsp;{'|'}&nbsp;
					{calculateDurationInMins(appointment.startTime, appointment.endTime)}
				</Typography>
				<Typography variant="paragraph3">
					{getLocation(appointment.locations)}
				</Typography>
				{/* <Directions locationCordinate={{}} /> */}
			</Stack>
			<Stack sx={{ display: { xs: 'none', md: 'inherit' } }}>
				<NoPhoto />
			</Stack>
		</Stack>
	)
}

const AppointmentDetailsAction = ({
	appointment = {},
	onCancelAppointment,
}) => {

	const saveEmalFile = useSaveEmlFile(
		{},
		appointment
	)
	const printAppointment = usePrintAppointment({},appointment)

	return (
		<Grid
			container
			className="appointment-dialog-content"
			sx={{ backgroundColor: 'primary.light', padding: '1rem 1.5rem' }}
		>
			<Grid item xs={12} sm={6}>
				<Stack alingItems="start" spacing={1}>
					<AddToCalendarButton appointment={appointment} />
					<RoundedButton
						onClick={onCancelAppointment}
						sx={{
							backgroundColor: '#D90D2B',
							marginRight: '1rem',
							minWidth: '240px',
						}}
						variant="contained"
					>
						<Typography
							variant="paragraph3"
							sx={{ color: 'secondary.dark', marginRight: 1 }}
						>
							{Strings.LINK_CANCEL_APPOINTMENT}
						</Typography>
						<TrashIcon fillColor="#ffffff" />
					</RoundedButton>
				</Stack>
			</Grid>
			<Stack sx={{ display: { xs: 'inherit', md: 'none' }, marginTop: '10px' }}>
				<NoPhoto />
			</Stack>
			<Grid item xs={12} sm={6} sx={{ marginTop: { xs: '10px', md: '0px' } }}>
				<Stack
					direction="row"
					spacing={2}
					justifyContent={{ xs: 'inherit', md: 'end' }}
				>
					<IconLink
						id="email-share-link"
						muiIcon={<EmailShareIcon />}
						onClick={saveEmalFile}
						text={Strings.LINK_SHARE_BY_MAIL}
					/>

					<IconLink
						id="handle-print-link"
						muiIcon={<PrintDetailsIcon />}
						onClick={printAppointment}
						text={Strings.LINK_PRINT_DETAILS}
					/>
				</Stack>
			</Grid>
		</Grid>
	)
}

const AppointmentDetailsLocation = ({ appointment = {}, location = {} }) => {
	return (
		<Box
			className="appointment-dialog-location-box"
			sx={{ padding: '1rem 1.5rem' }}
		>
			<Text.Bold variant="subtitle1" >Location Information</Text.Bold>
			<Grid sx={{ marginBottom: '1rem', marginTop: '0.5rem'}}>
				<Availability location={location} />
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Hours hours={location.storeHours} />
				</Grid>
				<Grid item xs={12} sm={6} sx={{ marginTop: { xs: '1rem', md: '0px'}}}>
					<Text.Bold variant="subtitle2">Contact</Text.Bold>
					<Stack 
						sx={{ 
							paddingLeft: '1rem', 
							borderLeft: '1px solid #000000' ,
							marginTop: '1rem',
							gap: '1rem'
						}}
					>
						<Typography variant="body2" >
							Phone&nbsp;
							<span style={{ marginLeft: '2rem'}}>{location.siteContact?.contactPhone}</span>
						</Typography>
						<Typography variant="body2">
							Fax&nbsp;
							<span style={{ marginLeft: '3.4rem'}}>{location.siteContact?.fax}</span>
						</Typography>
					</Stack>
				</Grid>
				{location.notes && (
					<Grid item xs={12} sx={{ marginTop: '1rem' }}>
						<Typography variant="body2">*&nbsp;{location.notes}</Typography>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

AppointmentDetails.Title = AppointmentDetailsTitle
AppointmentDetails.Content = AppointmentDetailsContent
AppointmentDetails.Action = AppointmentDetailsAction
AppointmentDetails.Location = AppointmentDetailsLocation

export default AppointmentDetails
