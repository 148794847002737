import TextButton from 'components/buttons/TextButton'
import IconGrid from './IconGrid/IconGrid'

const IconLink = ({ id, onClick, text, muiIcon, children, ...props }) => {
    return (
        <IconGrid muiIcon={muiIcon} {...props}>
            <TextButton ml={1}
                id={id}
                onClick={onClick}
            >
                {text}
            </TextButton>
        </IconGrid>
    )
}

export default IconLink
