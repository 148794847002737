import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import App from 'App'

import reportWebVitals from './reportWebVitals'

import { store } from './store'

import './index.scss'

const pca = new PublicClientApplication({
	auth:{
		clientId: "ecd0c963-5f02-47d8-aceb-1c330d33a46e",
		authority: "https://login.microsoftonline.com/f0700e1c-4b93-401b-b83b-30a4b13c3325",
		redirectUri: "/"
	}
})

const AppWithAuthentication = () => (
	<React.StrictMode>
		<Provider store={store}>
		<MsalProvider instance={pca}>
			<App />
		</MsalProvider>
		</Provider>
	</React.StrictMode>
);


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<AppWithAuthentication />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
