import useCountdownTimer from 'hooks/useCountDownTimer'
import React, { useEffect } from 'react'

const SessionExpire = ({
    applicationSettings,
    selectedTime,
    setSelectedTime,
    setOpenToast,
    setAlertCode,
    setOpenDialog,
    timerStarted, 
    setTimerStarted
}) => {

    const [formattedTime, resetTimer] = useCountdownTimer(
        applicationSettings?.reservationTimerDuration || '2'
    )

    useEffect(() => {
        if (selectedTime && !timerStarted) {
            resetTimer(applicationSettings?.reservationTimerDuration)
            setTimerStarted(true)
        } else if (!selectedTime && timerStarted) {
            setTimerStarted(false)
        }
    }, [resetTimer, selectedTime, timerStarted])

    useEffect(() => {
        if (formattedTime === '0:00' && timerStarted) {
            setSelectedTime()
            setOpenToast(true)
            setAlertCode(275)
            setTimerStarted(false)
            setOpenDialog(false);
        }
    }, [formattedTime, timerStarted])

    return (
        <div>
        </div>
    )
}

export default SessionExpire