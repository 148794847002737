import moment from 'moment'
import { DAYS, TIME_FORMAT, TimezoneAbbreviation, Timezones, microsoftSupportedTimezones } from './constants/time-constants'
import { store } from 'store'
import { getProvinceInitials } from './common.utils'

export const getFormattedTime = (time) => {
	return moment(time, 'HH:mm:ss').format('hh:mma')
}

/* istanbul ignore next */
export const getCurrentDay = () => {
	return moment().format('dddd');
}

/* istanbul ignore next */
export const getFormattedDateForFilter = (date) => {
	return date ? `${moment(date).format('MMM DD')}` : ''
}

export const formatDateTimein12Hour = (inputDate) => {
    try {
        if (inputDate === null || inputDate === '') {
            return inputDate;
        }
        const date = new Date(inputDate);
		if(date.getFullYear() < 2024){
			return null;
		}
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    } catch (error) {
        console.log('Error formatting date:', error);
        return inputDate;
    }
}

/* istanbul ignore next */
export const isDateTimeInRange = (openDate, closeDate, timeZone) => {
	const selectedTimeZone = microsoftSupportedTimezones.find((zone) => zone.name === timeZone);
	const checkDate = new Date(new Date().toLocaleString('en-US', { timeZone: (selectedTimeZone?.browserTimezone || 'America/New_York')}));
	const startDate = new Date(openDate);
	const endDate = new Date(closeDate);

	return checkDate >= startDate && checkDate <= endDate;
}

/* istanbul ignore next */
/* This function is no longer used */
// export const getFormatedEaliestAppt = (dateTime) => {
// 	if (dateTime) {
// 		/* istanbul ignore if */
// 		if (moment(new Date(dateTime)).isSame(new Date(), 'day')) {
// 			// return `Today at ${moment(new Date(dateTime)).format(TIME_FORMAT)}`
// 			return  `Today at ${convertUTCToLocalizedTime(dateTime)}` 
// 		} else {
// 			return `${moment(new Date(dateTime)).format('ddd, MMM Do')} at ${moment(
// 				convertUTCToLocalizedTime(dateTime)
// 			).format(TIME_FORMAT)}`
// 		}
// 	} else {
// 		return ''
// 	}
// }

export const calculateDurationInMins = (startdateTime, enddateTime) => {
	var duration = moment.duration(
		moment(enddateTime).diff(moment(startdateTime))
	)
	return duration.asMinutes() + 'min'
}

export const calculateDurationInHours = (startdateTime, enddateTime) => {
	var duration = moment.duration(
		moment(enddateTime).diff(moment(startdateTime))
	)
	return duration.asHours()
}

export const calculateDurationInHoursBasedOnTimeZone = (startdateTime, timeZone) => {
	const selectedTimeZone = microsoftSupportedTimezones.find((zone) => zone.name === timeZone);
	const currentDate = new Date().toLocaleString('en-US', { timeZone: (selectedTimeZone.browserTimezone || 'America/New_York')});
	var duration = moment.duration(
		moment(startdateTime).diff(moment(currentDate))
	)
	return duration.asHours()
}

export const calculateDurationInDays = (startdateTime, enddateTime) => {
	var duration = moment.duration(
		moment(enddateTime).diff(moment(startdateTime))
	)
	return duration.asDays()
}

const HYPHEN = ' - '
export const getFormattedDateRange = (stat) => {
	const startDate = stat.start || stat.closureStartDate
	let endDate = stat.end || stat.closureEndDate

	let returnString = moment(startDate).format('MMM DD')

	if (endDate) {
		const hours = calculateDurationInHours(startDate, endDate)
		if (hours < 24) {
			returnString += ''
		} else if (moment(startDate).month() === moment(endDate).month()) {
			returnString += HYPHEN + moment(endDate).format('DD')
			/* istanbul ignore else */
		} else {
			returnString += HYPHEN + moment(endDate).format('MMM DD')
		}
	}

	return returnString
}

/**
 * Checks to see if it's a week out from planned holiday
 *
 * @param {datetimestamp} startDate
 *
 * @returns boolean
 */
export const checkIfWeekOut = (startDate, timeZone) => {
	const selectedTimeZone = microsoftSupportedTimezones.find((zone) => zone.name === timeZone);
	const currentDate = moment(new Date(new Date().toLocaleString('en-US', { timeZone: (selectedTimeZone?.browserTimezone || 'America/New_York')}))).startOf('day')
	const numOfDays = moment(startDate).diff(currentDate, 'days')
	if (numOfDays >= 0 && numOfDays <= 7) {
		return true
	} else {
		return false
	}
}

export const getClosedHours = (hours) => {
	let currentHours = [...hours]
	if (hours.length < DAYS.length) {
		let remainingDays = [...DAYS]
			.filter((day) => !hours.find((hour) => hour.day === day))
			.map((day) => ({
				day,
				closed: true,
			}))
		currentHours = [...currentHours, ...remainingDays]
	}
	return currentHours
}

/* istanbul ignore next */
export function convertToLocalizedTime(inputDate, province = "ON") {
	try {
		const utcTime = new Date(inputDate);
		const hours = utcTime.getUTCHours();
		const minutes = utcTime.getUTCMinutes();
		let formattedHours = hours % 12;
		formattedHours = formattedHours === 0 ? 12 : formattedHours;
		const meridiem = hours < 12 ? 'AM' : 'PM';
		const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
		const provinceTimeZone = Timezones.find(zone => (zone.abbreviation === province) || (zone.province === province));
		const formattedTime = `${formattedHours}:${formattedMinutes} ${meridiem} ${provinceTimeZone?.timezoneAbbreviation}`;
		return formattedTime;
	} catch (error) {
		return inputDate;
	}
}

/* istanbul ignore next */
export const getExtendTimeDuration = (inputDate, prevTime) => {
	try {
		const cleanedInputDate = inputDate.replace(/'/g, '');
		if (prevTime === 0) {
			const timeDifference = Math.abs(new Date() - new Date(cleanedInputDate)) / 60000;
			return Math.floor(timeDifference);
		} else {
			const timeDifference = Math.abs(new Date(prevTime.replace(/'/g, '')) - new Date(cleanedInputDate)) / 60000;
			return Math.floor(timeDifference);
		}

	} catch (error) {
		return 2; // Default value in case of error
	}
};

/* istanbul ignore next */
export const generateSurroundingTimeslots = (baseTime, availableSlots, count) => {
	const format = "h:mm A";
	const timeToMoment = (time) => moment(time, format);
	const momentToTime = (momentObj) => momentObj.format(format);

	const baseMoment = timeToMoment(baseTime);
	const allTimes = availableSlots.map(slot => timeToMoment(slot.time)).sort((a, b) => a - b);
	const timeslotSet = new Set();
	allTimes.forEach((time, index) => {
		if (time.isSame(baseMoment)) {
			for (let i = -count; i <= count; i++) {
				if (allTimes[index + i]) {
					timeslotSet.add(momentToTime(allTimes[index + i]));
				}
			}
		}
	});

	return Array.from(timeslotSet).map(time => ({ time }));
};

/* istanbul ignore next */
export function convertUTCToLocalizedTime(inputDate) {
	try {
		const selectedProvince = store?.getState()?.appointment?.appoinmentLocation?.address?.province || 'ON'
		const inputDateTime = new Date(inputDate);
		const targetTimezone = Timezones.filter((timezone) => timezone.abbreviation === getProvinceInitials(selectedProvince))
		const targetDateTime = inputDateTime.toLocaleString('en-US', { timeZone: targetTimezone[0].name });
		const utcTime = new Date(targetDateTime);
		const hours = utcTime.getHours();
		const minutes = utcTime.getMinutes();
		let formattedHours = hours % 12;
		formattedHours = formattedHours === 0 ? 12 : formattedHours;
		const meridiem = hours < 12 ? 'AM' : 'PM';
		const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
		const formattedTime = `${formattedHours}:${formattedMinutes} ${meridiem} `;
		return formattedTime;
	} catch (error) {
		return inputDate;
	}
}

const isSameDate = (date1, date2) => {
	return date1.getDate() === date2.getDate() &&
		   date1.getMonth() === date2.getMonth() &&
		   date1.getFullYear() === date2.getFullYear();
};

/* istanbul ignore next */
export function convertDateTimeForEarliestTime(inputDate) {
	try {
		if(inputDate){
			const dateToCheck = new Date(inputDate);
			const currentDate = new Date();
			if (!isSameDate(currentDate, dateToCheck)) {
				const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
				const formattedDate = dateToCheck.toLocaleString('en-US', options).replace(/,\s/, ' ');
				if(formattedDate != null){
					return formattedDate;	
				} else {
					return ""
				}
			} else {
				const earliestTime = formatDateTimein12Hour(inputDate);
				if(earliestTime != null){
					const formattedTime = `Today at ${earliestTime}`
					return formattedTime;	
				} else {
					return ""
				}
			}
		} else {
			return ""
		}
	} catch (error) {
		return inputDate;
	}
}

// export const convertUTCToPSCLocationTimezone = (inputDate, provinceTimeZone = "ET") => {
// 	try {

// 		if (inputDate === null) {
// 			return inputDate
// 		}

// 		if(provinceTimeZone === null){
// 			provinceTimeZone = "ET"
// 		}

// 		let utc = new Date(inputDate);
// 		console.log('utc',utc);
// 		const targetTimezone = Timezones.filter((timezone) => timezone.timezoneAbbreviation === provinceTimeZone)
// 		console.log('targetTimezone',targetTimezone);
// 		const options = {
// 			timeZone: targetTimezone[0].name,
// 			hour: 'numeric',
// 			minute: 'numeric',
// 			hour12: true
// 		};
// 		console.log('options',options);
// 		const targetDateTime = new Date(utc.toLocaleString('en-US', { timeZone: targetTimezone[0].name }));
// 		// let targetDateTime = new Date(utc.toLocaleString("en-US", options));
// 		console.log('targetDateTime',targetDateTime);
		
// 		console.log('targetDateTime',targetDateTime);
// 		return targetDateTime;
// 	} catch (error) {
// 		console.log(error)
// 		return inputDate;
// 	}
// }

// export function isDstInCanada(date) {
// 	// DST starts on the second Sunday of March and ends on the first Sunday of November
// 	const month = date.getMonth();
// 	if (month > 2 && month < 10) {
// 		return true;
// 	} else if (month === 2) { // March
// 		// Get the day of the week of March 1st
// 		const marchFirstDay = new Date(date.getFullYear(), 2, 1).getDay();
// 		// If March 1st falls on or after Sunday (0), DST has started
// 		return marchFirstDay >= 0 && marchFirstDay <= 6;
// 	} else if (month === 10) { // November
// 		// Get the day of the week of November 1st
// 		const novemberFirstDay = new Date(date.getFullYear(), 10, 1).getDay();
// 		// If November 1st falls on or before Sunday (0), DST has ended
// 		return novemberFirstDay === 0;
// 	}
// 	return false;
// }

export const getInitials = (name) => {
	if(name && name?.length > 0){
		const words = name.split(/\s+/);    
		const initials = words.map(word => word.charAt(0).toUpperCase());
		return initials.join('');
	} else {
		return ''
	}
}

export const formatDateTimein12HourWithTimezone = (inputDate,timezone) => {
	try {
		const formattedTime = formatDateTimein12Hour(inputDate);
		if(formattedTime === null){
			return '';
		}

		const timezoneInitials = getInitials(timezone);
		if(timezoneInitials && timezoneInitials?.length > 0){
			return `${formattedTime} ${timezoneInitials}`
		} else {
			return `${formattedTime}`
		}
	} catch (error) {
		console.log('Error formatting date:', error);
		return inputDate;
	}
}

// export const calculateEndTime = (startTime, duration) => {
// 	try {
// 		if(startTime === null || startTime === ''){
// 			return startTime;
// 		}
// 		const startDate = new Date(`1970-01-01T${convertTo24Hour(startTime)}`);
// 		const durationMinutes = parseInt(duration.replace("min", ""), 10);
// 		startDate.setMinutes(startDate.getMinutes() + durationMinutes);
// 		const endTime = formatAMPM(startDate);
// 		return endTime;
// 	} catch(error){
// 		console.log(error);
// 	}
// }

export const convertTo24Hour = (time) => {
    let [hours, minutes] = time.split(/:| /);
    const period = time.split(' ')[1];
    if (period === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
    } else if (period === 'AM' && hours === '12') {
        hours = '00';
    }
    return `${hours}:${minutes}`;
}

export const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
}

export const calculateEndTime = (startTime, duration) => {
	try {
		let startDate = new Date(startTime);
		let durationMinutes = parseInt(duration?.replace(/\D/g, ''), 10);
		let endDate = new Date(startDate?.getTime() + durationMinutes * 60000);  
	
		let formattedEndTime = endDate.getFullYear() +
		'-' + String(endDate.getMonth() + 1).padStart(2, '0') +
		'-' + String(endDate.getDate()).padStart(2, '0') +
		'T' + String(endDate.getHours()).padStart(2, '0') +
		':' + String(endDate.getMinutes()).padStart(2, '0') +
		':' + String(endDate.getSeconds()).padStart(2, '0');
		return formattedEndTime;

	} catch(error){
		console.log(error);
	}
}
    