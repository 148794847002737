import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    services: [],
    specialityTests: '',
    date: null
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        updateFilter: (state, action) => {
            return {
                services: [
                    ...action.payload.services || []
                ],
                specialityTests: action.payload.specialityTests || '',
                date: action.payload.date || state.date
            }
        },
        resetFilters: () => {
            return {
                ...initialState
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { resetFilters, updateFilter } = filterSlice.actions

export default filterSlice.reducer