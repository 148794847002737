import { Button, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as ArrowDown } from 'assets/FilterIcons/DropDownArrow.svg'
import { ReactComponent as ArrowUp } from 'assets/FilterIcons/UpArrow.svg'
import moment from "moment";
import { useEffect, useState } from "react";
import { Strings } from "utils/Strings";
import { getFormattedDateForFilter } from "utils/dateTime.utils";
import { ReactComponent as ArrowLeft } from 'assets/Arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/Arrow-Right.svg'

function ButtonField(props) {
	const {
		setOpen,
		label,
		id,
		disabled,
		open,
		InputProps: { ref } = {},
		inputProps: { 'aria-label': ariaLabel } = {},
	} = props;
	return (
		<Button
			variant="contained"
			id={id}
			disabled={disabled}
			ref={ref}
			aria-label={ariaLabel}
			onClick={() => setOpen?.((prev) => !prev)}
			endIcon={!open ? <ArrowDown /> : <ArrowUp />}
			sx={{
				backgroundColor: '#F3F6FA',
				boxShadow: 'none',
				color: '#13499F',
				maxHeight: '36px',
				padding: '6px 22px',
				marginLeft: '12px',
				width: 'max-content',
				'&:hover': {
					backgroundColor: '#F3F6FA',
				},
				'&:focus': {
					backgroundColor: '#F3F6FA',
				},
			}}
		>
			<Typography sx={{
				marginRight: '10px',
				fontSize: '1rem',
				fontWeight: '400',
				lineHeight: '1.5rem',
			}}
			>
				{label ? Strings.FILTER_BY_DATE + " " + (label && `(${getFormattedDateForFilter(label)})` || '') : Strings.FILTER_BY_DATE}
			</Typography>
		</Button>
	);
}

function ButtonDatePicker(props) {
	const [open, setOpen] = useState(false);
	
	const [isTodayDate, setIsTodayDate] = useState(false);

	useEffect(() => {
		if(props?.value){
			const checkSelectedDate = moment(props?.value);
			const today = moment();
			if (checkSelectedDate.isSame(today, 'day')) {
				setIsTodayDate(true);
			} else {
				setIsTodayDate(false);
			}
		}
	}, [props?.value])

	return (
		<DatePicker
			disablePast
			slots={{ 
				...props.slots, 
				field: ButtonField,
					leftArrowIcon: () => <ArrowLeft />,
					rightArrowIcon: () => <ArrowRight />,
					switchViewIcon: () => null,
			}}
			slotProps={{
				...props.slotProps,
				field: { setOpen, open },
				calendarHeader: {
					sx: {
						position: "relative",
						'& .MuiPickersArrowSwitcher-root': {
							width: 0,
							alignItems: 'center',
						},
						"& .MuiPickersCalendarHeader-labelContainer": {
							margin: "auto",
							fontWeight: 'bold',
							paddingRight: '9px',
							cursor: 'unset'
						},
						"& .MuiIconButton-edgeEnd": {
							position: "absolute",
							left: 60,
							top: 0,
							bottom: 0
						},
						"& .MuiIconButton-edgeStart": {
							position: "absolute",
							right: 60,
							top: 0,
							bottom: 0
						}
					}
				},
				leftArrowIcon: {
					sx: { color: "#000", fontSize: "2rem" }
				},
				rightArrowIcon: {
					sx: { color: "#000", fontSize: "2rem", }
				},
				layout: {
					sx:{
						backgroundColor: '#F3F6FA',
						padding: '6px',
						'& .MuiDayCalendar-slideTransition': {
							// Add your custom CSS properties for MuiPickersSlideTransition-root here
							display: 'flex',
							position: 'absolute',
							minHeight: '289px',
							minWidth: '280px',
							left: '8%'
						},
						'& .MuiPickersDay-dayWithMargin': {
							background: '#fff',
							border: '1.5px solid',
							borderColor: '#E0E0E0',
							color: '#000000',
							fontWeight: 'bold'
						},
						'& .MuiPickersDay-today': {
							backgroundColor: 'text.light',
							border: '.1rem solid',
							borderColor: 'info.contrastText',
						},
						'& .Mui-disabled.MuiPickersDay-dayWithMargin': {
							background: '#E0E0E0',
							border: 'none',
						},
						'& .Mui-selected.MuiPickersDay-dayWithMargin': {
							color: isTodayDate ? '#2D75E9' : '#D2D2D2',
							background: 'rgb(19, 73, 159)',
							fontWeight: '600',
						},
						'& .MuiPickersArrowSwitcher-button': {
							background: 'transparent',
						},
						'& .MuiDayCalendar-weekDayLabel': {
							color: '#000000',
							fontWeight: 'bold',
						},
						'& .MuiDayCalendar-weekContainer':{
							margin: '8px 0',
							maxHeight: '36px',
						}
					}
				}
			}}
			{...props}
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			dayOfWeekFormatter={(_day, date) => (
				<Typography fontSize="0.81rem" sx={{ fontWeight: '600' }}>
					{moment(date, 'weekdayLong')
						.format('dddd')
						.substring(0, 2)
						.toUpperCase()
					}
				</Typography>
			)}
			views={['day']}
		/>
	);
}

function PickerWithButtonField({
	selectedDate,
	handleFilterChange
}) {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment} dateFormats={{ monthAndYear: 'MMM yyyy' }}>
			<ButtonDatePicker
				label={selectedDate}
				value={selectedDate ? moment(new Date(selectedDate)) : null}
				onChange={(date) => {
					handleFilterChange('date', date)
				}}
			/>
		</LocalizationProvider>
	);
}

export default PickerWithButtonField;