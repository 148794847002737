function Calender({ fillColor }) {
	return (
		<svg
			width="17"
			height="19"
			viewBox="0 0 17 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.119 6.83333H0.880859M9.26181 9.88095H4.69038M6.9761 12.9286H4.69038M5.45229 1.5V3.78571M11.5475 1.5V3.78571M4.08086 17.5H12.919C14.0391 17.5 14.5991 17.5 15.0269 17.282C15.4033 17.0903 15.7092 16.7843 15.901 16.408C16.119 15.9802 16.119 15.4201 16.119 14.3V5.4619C16.119 4.3418 16.119 3.78175 15.901 3.35392C15.7092 2.9776 15.4033 2.67164 15.0269 2.47989C14.5991 2.2619 14.0391 2.2619 12.919 2.2619H4.08086C2.96075 2.2619 2.4007 2.2619 1.97288 2.47989C1.59655 2.67164 1.29059 2.9776 1.09885 3.35392C0.880859 3.78175 0.880859 4.3418 0.880859 5.4619V14.3C0.880859 15.4201 0.880859 15.9802 1.09885 16.408C1.29059 16.7843 1.59655 17.0903 1.97288 17.282C2.4007 17.5 2.96075 17.5 4.08086 17.5Z"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default Calender
