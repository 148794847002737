import { createSlice } from '@reduxjs/toolkit'

const initialState = 'ON'

export const selectedProvinceSlice = createSlice({
	name: 'selectedProvince',
	initialState,
	reducers: {
		updateSelectedProvince: (state, action) => {
			localStorage.setItem('selectedProvince',action.payload);
			return state = action.payload;
		},
		resetSelectedProvince: () => {
			return initialState
		},
	},
})

export const { resetSelectedProvince, updateSelectedProvince } = selectedProvinceSlice.actions

export default selectedProvinceSlice.reducer
