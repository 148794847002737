function LocationPin({ fillColor }) {
	return (
		<svg
			width="17"
			height="19"
			viewBox="0 0 17 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				stroke={fillColor}
				d="M1.2998 8.30006C1.2998 4.30003 4.49983 1.5 8.49987 1.5C12.4999 1.5 15.6999 4.30003 15.6999 8.30006C15.6999 14.5816 10.0999 17.5 8.49987 17.5C6.89986 17.5 1.2998 14.5816 1.2998 8.30006Z"
				strokeWidth="1.5"
			/>
			<path
				stroke={fillColor}
				d="M10.8999 8.70007C10.8999 10.0256 9.82537 11.1001 8.49987 11.1001C7.17438 11.1001 6.09985 10.0256 6.09985 8.70007C6.09985 7.37457 7.17438 6.30004 8.49987 6.30004C9.82537 6.30004 10.8999 7.37457 10.8999 8.70007Z"
				strokeWidth="1.5"
			/>
		</svg>
	)
}

export default LocationPin
