import i18next from 'i18next'

import en from './en-US.json'
// import fr from "./fr.json";
import { languages } from 'utils/constants/common.constants'
import { initReactI18next } from 'react-i18next'

i18next.use(initReactI18next).init({
	interpolation: { escapeValue: false },
	lng: languages.en,
	resources: {
		en: {
			translation: en,
		},
		// fr: {
		//     translation: fr,
		// },
	},
})

export default i18next
