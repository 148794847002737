import { createSlice } from '@reduxjs/toolkit'

const initialState = {
}

export const customerDetailsReducer = createSlice({
	name: 'customerDetails',
	initialState,
	reducers: {
        updateCustomerDetails: (state, action) => {
			return {
				...state,
				...action.payload
			}
		}
	},
})

export const { updateCustomerDetails } = customerDetailsReducer.actions
export default customerDetailsReducer.reducer
