export function sortFilter(locations = [], sortType) {
	switch (sortType) {
		case 'CLOSEST':
			return locations.sort((a, b) => +a.address?.distance - +b.address?.distance)
		case 'EARLIEST':
			return locations.sort((a, b) => {
				const dateA = a.earliestApptOrg ? new Date(a.earliestApptOrg) : null;
				const dateB = b.earliestApptOrg ? new Date(b.earliestApptOrg) : null;

				if (dateA === null) return 1;
				if (dateB === null) return -1;

				return dateA.getTime() - dateB.getTime();
			});
		case 'SHORTEST':
			return locations.sort((a, b) => {
				
				if (a?.waitTime === null) return 1;
				if (b?.waitTime === null) return -1;

				const waitTimeA = a?.waitTime?.toString() || '';
				const waitTimeB = b?.waitTime?.toString() || '';

				return waitTimeA.localeCompare(waitTimeB);
			});
		default:
			return locations
	}
}
