function Sort({ fillColor }) {
	return (
		<svg
			width="18"
			height="17"
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.3955 5.69873L12.6968 1.99999L8.99802 5.69873"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11.9463 14.4917C11.9463 14.9059 12.2821 15.2417 12.6963 15.2417C13.1105 15.2417 13.4463 14.9059 13.4463 14.4917H11.9463ZM11.9463 2.30762V14.4917H13.4463V2.30762H11.9463Z"
				fill={fillColor}
			/>
			<path
				d="M1.03516 11.3013L4.7339 15L8.43265 11.3013"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5.48438 2.50779C5.48438 2.09358 5.14859 1.75779 4.73438 1.75779C4.32016 1.75779 3.98438 2.09358 3.98438 2.50779L5.48438 2.50779ZM5.48438 14.6919L5.48438 2.50779L3.98438 2.50779L3.98438 14.6919L5.48438 14.6919Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export default Sort
