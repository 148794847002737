export const HOME = '/'
export const APPOINTMENTS = '/appointments'
export const CANCEL_APPT = '/cancel-appointment'
export const LOGIN = '/login'
export const LOGIN_RETURN = '/login-return'
export const DASHBOARD = 'dashboard'
export const CONFIRM_APPT = 'confirm-appt'
export const APPT_CONFIRMED = 'appt-confirmed'
export const MCC_DASHBOARD = APPOINTMENTS + '/' + DASHBOARD
export const MCC_CONFIRM_APPT = APPOINTMENTS + '/' + CONFIRM_APPT
export const MCC_APPT_CONFIRMED = APPOINTMENTS + '/' + APPT_CONFIRMED

export const ID4_ON = process.env.REACT_APP_ID4_ON_URL
export const ID4_BC = process.env.REACT_APP_ID4_BC_URL

export const MCC_ON = 'https://www.on.mycarecompass.lifelabs.com/'
export const MCC_BC = 'https://www.bc.mycarecompass.lifelabs.com/'
export const MCC_SHOP = 'https://store.lifelabs.com'
export const MCC_MARKETPLACE = '/marketplace'

export const LOCATION_FINDER = '/locationfinder'

// ID4 Login
export const ID4_AUTH = process.env.REACT_APP_ID4_AUTH_URL

// ID4 Login BC
export const ID4_AUTH_BC = process.env.REACT_APP_ID4_AUTH_URL_BC

export const grant_type = process.env.REACT_APP_GRANT_TYPE
export const scope = process.env.REACT_APP_SCOPE
export const client_id = process.env.REACT_APP_CLIENT_ID
export const client_secret = process.env.REACT_APP_CLIENT_SECRET
export const username = process.env.REACT_APP_USERNAME
export const Password = process.env.REACT_APP_PASSWORD
