import { Tooltip } from "@mui/material"
import CustomAppointmentToolTip from "features/appointment/CustomAppointmentToolTip"

const CustomizeTooltip = ({
    setIsTooltipOpen,
    customToolTipLabel,
    children,
    isOpen,
    ...props
}) => {
    return (
        <Tooltip
            onClose={() => setIsTooltipOpen(false)}
            title={
                <CustomAppointmentToolTip
                    setIsTooltipOpen={setIsTooltipOpen}
                    label={customToolTipLabel}
                />
            }
            onClick={()=>setIsTooltipOpen(!isOpen)}
            onMouseEnter={()=>setIsTooltipOpen(true)}
            open={isOpen}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#D4E4FE',
                        color: '#13499F',
                        fontSize: 11,
                        minWidth: {
                            xs: '80px',
                            md: '400px',
                        },
                        margin: {
                            xs: '20px',
                            md: '0px',
                        },
                        padding: '24px',
                        '& .MuiTooltip-arrow': {
                            color: '#D4E4FE',
                        },
                    },
                },
            }}
            PopperProps={{
                sx: {
                    zIndex: 10,
                },
            }}
        >
            <div>
                {children}
            </div>
        </Tooltip>
    )
}

export default CustomizeTooltip
