import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isGoogleApiLoaded: false,
	map: null,
}

export const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setApiState: (state, action) => {
			state.isGoogleApiLoaded = action.payload
		},
	},
})

export const { setApiState, loadMap } = mapSlice.actions
export default mapSlice.reducer
