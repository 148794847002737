import { APPOINTMENTS, LOCATION_FINDER } from "./routes-constants";

const DASHBBOARD = '/home?returnUrl=dashboard&prov={province}';
const REPORTS = '/home?returnUrl=reports&prov={province}';
const ANALYTICS = '/home?returnUrl=analytics&prov={province}';
const MARKETPLACE = '/home?returnUrl=marketplace&prov={province}';
const FAQ = '/home?returnUrl=faq&prov={province}';
const SUPPORT = '/home?returnUrl=support&prov={province}';
const ACCOUNT = '/home?returnUrl=account&prov={province}'


export const MCCRedirectionUrls = {
    "Shop_Our_Service": "https://store.lifelabs.com/",
    "Logout": `${process.env.REACT_APP_ID4_LOGOUT_URL}`,
    "Authenticated_Location_Finder": `${process.env.REACT_APP_BASE_URL_PROD}${LOCATION_FINDER}?province=selectedProvince&source=mcc`,
    "MCC_BC_DASHBOARD": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${DASHBBOARD}`,
    "MCC_ON_DASHBOARD": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${DASHBBOARD}`,
    "MCC_BC_REPORTS": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${REPORTS}`,
    "MCC_ON_REPORTS": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${REPORTS}`,
    "MCC_BC_ANALYTICS": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${ANALYTICS}`,
    "MCC_ON_ANALYTICS": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${ANALYTICS}`,
    "PWT_APPOINTMENTS": APPOINTMENTS,
    "MCC_BC_MARKETPLACE": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${MARKETPLACE}`,
    "MCC_ON_MARKETPLACE": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${MARKETPLACE}`,
    "MCC_BC_FAQ": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${FAQ}`,
    "MCC_ON_FAQ": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${FAQ}`,
    "MCC_BC_SUPPORT": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${SUPPORT}`,
    "MCC_ON_SUPPORT": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${SUPPORT}`,
    "MCC_BC_ACCOUNT": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}${ACCOUNT}`,
    "MCC_ON_ACCOUNT": `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}${ACCOUNT}`
}