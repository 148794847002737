import { createSlice } from '@reduxjs/toolkit'

export const APPOINTMENT_STATUS = {
	TO_BE_CONFIRMED: 'TO_BE_CONFIRMED',
	CONFIRMED: 'CONFIRMED',
	CANCELLED: 'CANCELLED',
}

export const APPOINTMENT_STATES = [
	'VIEW_LOCATIONS',
	'APPOINTMENT',
	'CONFIRM_APPOINTMENT',
	'APPOINTMENT_CONFIRMED',
	'DISCARD_APPOINTMENT'
]

export const initialState = {
	appointment: {
		status: APPOINTMENT_STATUS.TO_BE_CONFIRMED,
		appointmentDate: '',
		name: '',
		startTime: '',
		endTime: '',
		appointmentType: '',
		duration: '',
		appointmentSlotId: '',
		testId: '',
	},
	appointmentLocation: {
		siteId: '',
		address1: '',
		address2: '',
		city: '',
		province: '',
		postalCode: '',
	},
	appointmentState: APPOINTMENT_STATES[0],
	appointmentDiscardedStatus: false,
	appointmentReserveId: '',
	appointmentBookingTimeLeft: '',
}

export const appointmentSlice = createSlice({
	name: 'appointment',
	initialState,
	reducers: {
		updateAppointmentLocation: (state, action) => {
			state.appointmentLocation = {
				...state.appointmentLocation,
				...action.payload,
			}
		},
		resetAppointmentLocation: (state) => {
			state.appointmentLocation = {
				...initialState.appointmentLocation,
			}
		},
		updateAppointment: (state, action) => {
			state.appointment = {
				...state.appointment,
				...action.payload,
			}
		},
		resetAppointment: (state) => {
			state.appointment = {
				...initialState.appointment,
			}
		},
		updateAppointmentState: (state, action) => {
			state.appointmentState = action.payload
		},
		updateAppointmentDiscardedStatus: (state, action) => {
			state.appointmentDiscardedStatus = action.payload
		},
		updateAppointmentReserveId: (state, action) => {
			state.appointmentReserveId = action.payload
		},
		resetAppointReserveId: (state,action) => {
			state.appointmentReserveId = initialState.appointmentReserveId
		},
		resetAppointmentTime: (state,action) => {
			state.appointment.startTime = initialState.appointment.startTime
		}, 
		resetAppointmentBookingTimeLeft: (state,action) => {
			state.appointment.appointmentBookingTimeLeft = initialState.appointment.appointmentBookingTimeLeft
		}, 
	},
})

export const {
	resetAppointment,
	updateAppointment,
	updateAppointmentState,
	updateAppointmentLocation,
	resetAppointmentLocation,
	updateAppointmentDiscardedStatus,
	updateAppointmentReserveId,
	resetAppointReserveId,
	resetAppointmentTime,
	resetAppointmentBookingTimeLeft
} = appointmentSlice.actions

export default appointmentSlice.reducer
