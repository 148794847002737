import {
	Autocomplete,
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Popper,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { useEffect, useRef } from 'react'
import { Strings } from 'utils/Strings'
import { generateSurroundingTimeslots } from 'utils/dateTime.utils'
import Text from 'components/Text'
import { ReactComponent as ArrowDown } from 'assets/Arrow-Down.svg'
import { ReactComponent as ArrowUp } from 'assets/Arrow-Up.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const CustomPaper = (props) => {
	return (
		<Paper
			elevation={8}
			{...props}
			sx={{
				borderRadius: '16px',
				marginTop: '8px',
				padding: '0.5rem 1rem'
			}}
			style={{ 
				background: "white !important"
			}}
		/>
	)
};

const AppointmentTimeSlot = ({
	source,
	timeOpen,
	timeSlots,
	setSelectedTime,
	setTimeOpen,
	selectedTime,
	selectedDate,
}) => {
	const noOptionsRef = useRef(null)
	const filterTimeslots = (options, state) => {
		if (!state.inputValue) {
			return options
		}

		const searchValue = state.inputValue.toLowerCase()
		const filteredSlots = timeSlots?.filter((slot) =>
			slot.time.toLowerCase().includes(searchValue)
		)

		let recommendations = []
		if (filteredSlots.length === 1) {
			recommendations = generateSurroundingTimeslots(
				filteredSlots[0].time,
				timeSlots,
				2
			)
		} else {
			recommendations = filteredSlots
		}
		return recommendations
	}

	useEffect(() => {
		if (timeSlots && timeSlots?.length === 0) {
			window.scrollBy(0, 1)
		}
	}, [timeSlots])

	useEffect(() => {
		const handleScroll = () => {
			if (timeOpen) {
				setTimeOpen(false)
			}
		}
		if (timeOpen) {
			window.addEventListener('scroll', handleScroll)
		} else {
			window.removeEventListener('scroll', handleScroll)
		}
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [timeOpen])

	return (
		<>
			{source === 'D365' ? (
				<Autocomplete
					sx={{
						'& fieldset': {
							borderRadius: '25px',
							borderColor: 'transparent',
						},
						'& .MuiAutocomplete-inputRoot': {
							padding: '5px',
							maxHeight: '48px'
						},
						'& .MuiOutlinedInput-root': {
							padding: '5px',
						},
						'& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
							right: '20px'
						},
						'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
							paddingLeft: '15px'
						},
						width: '250px',
						maxHeight: '48px'
					}}
					ListboxProps={{
						sx: {
							'&::-webkit-scrollbar': {
								width: '4px',
								marginTop: '10px',
								borderRadius: '70px',
							},
							'&::-webkit-scrollbar-track': {
								margin: '10px 0',
								background: '#F3F6FA',
								borderRadius: '70px',
							},
							'&::-webkit-scrollbar-thumb': {
								margin: '10px 0',
								background: '#13499F',
								borderRadius: '70px',
							},
							maxHeight: '248px',
						}
					}}
					getOptionLabel={(slot) => slot.time || slot}
					open={timeOpen}
					options={timeSlots || []}
					onChange={(event, value) => {
						if (value) {
							setSelectedTime(value)
							setTimeOpen(false)
						} else {
							setSelectedTime()
							setTimeOpen(true)
						}
					}}
					value={selectedTime || null}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder= {selectedTime ? null : Strings.SELECT_A_TIME}
							variant="outlined"
							outlined={false}
							onClick={() => {
								if (selectedTime) {
									setTimeOpen(selectedDate ? !timeOpen : true)
								} else {
									setTimeOpen(!timeOpen)
								}
							}}
							onBlur={() => setTimeOpen(false)}
							sx={{
								borderRadius: '25px',
								backgroundColor: 'secondary.main',
								color: 'white',
								maxHeight: '48px',
							}}
						/>
					)}
					popupIcon={<ArrowDown />}
					renderOption={(props, slot, state) => {
						return (
							<li
								{...props}
								style={{
									backgroundColor: 'white'
								}}
							>
								<span 
									style={{ 
										backgroundColor: state?.selected === true ? '#F3F6FA' : (state?.index === 0 && state?.inputValue === '') ? '#F3F6FA' : 'white',
										color: (state?.selected === true) ? '#13499F' : (state?.index === 0 && state?.inputValue === '') ? '#13499F' : 'black',
										borderRadius: '5px',
										width: '95%',
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										paddingLeft: '0.25rem'
									}}
								>
									{slot.time}
								</span>
							</li>
						)
					}}
					PaperComponent={CustomPaper}
					filterOptions={filterTimeslots}
				/>
			) : (
				<FormControl fullWidth>
					<InputLabel id="select-input" style={{ display: 'none', color: '#595959' }}>
						Select a Time
					</InputLabel>
					<Select
						labelId="select-input-label"
						id="select-input"
						tabIndex={0}
						ref={noOptionsRef}
						value={selectedTime}
						autoFocus={true}
						data-testid="select-input"
						IconComponent={() =>
							<Box sx={{ marginRight: '20px', marginTop: '5px', cursor: 'pointer' }}>
								{!timeOpen ? <ArrowDown /> : <ArrowUp />}
							</Box>
						}
						onKeyUpCapture={(e) => {
							if (timeSlots?.length === 0) {
								setTimeOpen(false)
							} else if (e?.code === 'Escape') {
								setTimeOpen(false)
							} else if (e?.code === 'Enter') {
								setTimeOpen(true)
							}
						}}
						sx={{
							backgroundColor: 'secondary.main',
							borderRadius: 20,
							padding: 0,
							margin: 0,
							height: '48px',
							width: {
								md: '250px',
								xs: '100%'
							}
						}}
						open={timeOpen}
						renderValue={() =>
							selectedTime?.time ? (
								<Typography variant="paragraph2" sx={{ color: 'black' }}>
									{selectedTime?.time || Strings.SELECT_A_TIME}
								</Typography>
							) : (
								<Typography variant="paragraph2" sx={{ color: '#595959' }}>
									{selectedTime || Strings.SELECT_A_TIME}
								</Typography>
							)
						}
						displayEmpty
						inputProps={{
							'aria-label': selectedTime?.time || Strings.SELECT_A_TIME,
						}}
						disabled={!selectedDate}
						onChange={(e) => {
							setSelectedTime(e.target.value)
							setTimeOpen(false)
						}}
						SelectDisplayProps={{
							id: 'time-select',
							'aria-label': Strings.SELECT_A_TIME,
							'aria-controls': `time-select-options`,
						}}
						MenuProps={{
							MenuListProps: {
								id: `time-select-options`,
							},
							disableScrollLock: true,
							PaperProps: {
								elevation:'8',
								sx: {
									maxHeight: '248px',
									minWidth: {
										md:'250px !important',
										xs: '100%'
									},
									maxWidth: {
										md:'250px !important',
										xs: '100%'
									},
									backgroundColor: '#F3F6FA',
									borderRadius: '16px',
									marginTop: '8px',
									overflowY: 'auto',
									
									'&::-webkit-scrollbar': {
										width: '24px',
									},

									'&::-webkit-scrollbar-track': {
										margin: '10px 0px',
										boxShadow: 'inset 0 0 10px 10px #DDE5F1',
										borderLeft: 'solid 10px transparent',
										borderTop: 'none',
										borderBottom: 'none',
										borderRight: 'solid 10px transparent',
									},
									
									'&::-webkit-scrollbar-thumb': {
										boxShadow: 'inset 0 0 10px 10px #13499F',
										borderLeft: 'solid 10px transparent',
										borderTop: 'none',
										borderBottom: 'none',
										borderRight: 'solid 10px transparent',
									}
								}
							}
						}}
						onClick={() => setTimeOpen(selectedDate ? !timeOpen : false)}
					>
						{timeSlots && timeSlots?.length > 0 ? (
							timeSlots.map((timeSlot, index) => (
								<MenuItem
									value={timeSlot}
									key={index}
									onClick={() => setSelectedTime(timeSlot)}
									tabIndex={0}
									sx={{
										backgroundColor: (timeSlot?.slotId === selectedTime?.slotId) ? '#DDE5F1' : 'white',
										color: (timeSlot?.slotId === selectedTime?.slotId) ? '#13499F' : 'black',
										borderRadius: '5px',
										width: {
											md: '80%',
											xs: '90%'
										},
										margin: '0.5rem 1rem',
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										paddingLeft: '0.25rem'
									}}
								>
									{timeSlot.time}
								</MenuItem>
							))
						) : (
							<MenuItem disabled tabIndex={0}>
								<Text
									variant="paragraphBold1"
									sx={{ fontWeight: '500' }}
									i18nKey="LBL_NO_TIME_SLOT"
								/>
							</MenuItem>
						)}
					</Select>
				</FormControl>
			)}
		</>
	)
}

export default AppointmentTimeSlot
