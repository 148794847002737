import Directions from 'features/searchresults/Directions'
import { Strings } from 'utils/Strings'
import { testAttr } from 'utils/test/test.utils'
import IconGrid from 'components/data-display/IconGrid/IconGrid'
import TextButton from 'components/buttons/TextButton'
import RoundedButton from 'components/buttons/RoundedButton'
import Distance from '../Distance'
import LocationPin from 'assets/LocationPin'
import Cross from 'assets/Cross'
import Text from 'components/Text'
import { Box } from '@mui/system'
import EarliestTime from '../location/EarliestTime'

const { Card, Grid, Stack, IconButton } = require('@mui/material')

const MapInfoCard = ({
	location = {},
	handleEarlApptClick,
	handleClickViewAppt,
	handleClickDetails,
	onCloseClick,
	id,
	...props
}) => {
	return (
		<Card {...props} sx={{ position: 'relative', padding: '1rem', boxShadow: 'none' }}>
			<IconButton
				aria-label='close-popup'
				onClick={onCloseClick}
				sx={{
					position: 'absolute',
					right: 0,
					top: 15,
				}} >
				<Cross fillColor="#13499F" />
			</IconButton>
			<Text.Bold variant="subtitle1">
				{location.siteName}
			</Text.Bold>
			<Distance distance={location.address?.distance} />
			<Stack direction='row' sx={{ margin: '0.5rem 0' }}>
				<IconGrid
					muiIcon={<LocationPin fillColor="#13499F" />}
				>
					<TextButton
						{...testAttr(`location-details-${location.id}`)}
						id={location.siteId}
						i18nKey='LINK_LOC_DETAILS'
						onClick={() => handleClickDetails(location)}
						sx={{
							fontSize: '0.9rem',
							paddingLeft: 0,
							color: '#13499F',
							fontWeight: '600',
							textDecoration: 'underline'
						}}
					/>
				</IconGrid>
				<Box sx={{ border: "0.5px solid #13499F", margin: "5px"}}/>
				<Directions
					locationCordinate={{ ...location.address }}
					sx={{
						fontSize: '0.9rem',
						color: 'primary.dark',
						fontWeight: '600',
						textDecoration: 'underline'
					}} />
			</Stack>
			<EarliestTime 
				id={id} 
				location={location} 
				handleEarlApptClick={handleEarlApptClick}
			/>
			<Grid
				container
				justifyContent={'center'}
				sx={{ width: '100%', marginTop: '0.81rem' }}
			>
				<RoundedButton
					variant="outlined"
					{...testAttr(`view-app-${id}`)}
					onClick={() => handleClickViewAppt(location)}
					sx={{ width: '100%' }}
				>
					{Strings.VIEW_APPT}
				</RoundedButton>
			</Grid>
		</Card>
	)
}

export default MapInfoCard
