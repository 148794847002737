import TextButton from 'components/buttons/TextButton'
import { ReactComponent as DirectionsIcon } from 'assets/DirectionsIcon.svg'

const Directions = ({ locationCordinate = {}, ...props }) => {

	const openGoogleMapStreetView = () => {
		window.open('https://maps.google.com?q=' + locationCordinate.latitude + ',' + locationCordinate.longitude)
	}

	return (
		<TextButton
			onClick={openGoogleMapStreetView}
			startIcon={<DirectionsIcon />}
			i18nKey='LINK_DIRECTIONS'
			sx={{
				marginLeft: '5px',
				paddingLeft: '0',
				gap: '2px'
			}}
			{...props}
		/>
	)
}

export default Directions
