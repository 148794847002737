import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { locationApi } from './services/locationApi'
import { locationTimeSlotsApi } from './services/locationTimeSlotsApi'
import { customerApi } from './services/customerApi'
import { appointmentApi } from './services/appointmentApi'
import { locationSiteIdApi } from './services/locationSiteIdApi'
import { configsApi } from './services/configsApi'

//Reducers
import mapReducer from './reducers/mapReducer'
import filterReducer from '../features/filters/filterSlice'
import searchReducer from '../features/searchresults/searchSlice'
import userAddressReducer from './reducers/userAddressReducer'
import appointmentReducer from '../features/appointment/appointmentSlice'
import configReducer from './reducers/configReducer'
import sortCriteriaReducer from '../features/filters/SortCriteriaSlice'
import { reservationApi } from './services/reservationApi'
import { extendSessionApi } from './services/extendReservationApi'
import authReducer from './reducers/authReducer'
import { mccCustomerApi } from './services/mccCustomerApi'
import selectedLocationReducer from './reducers/selectedProvinceReducer'
import deviceHistoryReducer from './reducers/deviceHistoryReducer'
import { deviceApi } from './services/deviceApi'
import appStateReducer from './reducers/appStateReducer'
import { authToken } from './services/authTokenApi'
import { authorizeStaff } from './services/authorizeStaffApi'
import { earliestAppointmentTimeApi } from './services/earliestAppointmentTimeApi'
import { syncCustomerApi } from './services/syncCustomerAPI'
import { pscWaitTimeApi } from './services/pscWaitTimeApi'
import { removeCachingForKeys } from 'utils/common.utils'
import customerDetailsReducer from './reducers/customerDetailsReducer'

/* istanbul ignore next */
const localStorageMiddleware = ({ getState }) => {
	return (next) => (action) => {
		const result = next(action)
		const state = getState()
		const keysToStore = ['filter', 'userAddress', 'config']
		const stateToStore = {}
		keysToStore.forEach((stateKey) => {
			/* istanbul ignore else */
			if (state[stateKey] !== undefined) {
				stateToStore[stateKey] = state[stateKey]
			}
		})

		localStorage.setItem('applicationState', JSON.stringify(stateToStore))
		return result
	}
}

/* istanbul ignore next */
export const reHydrateStore = () => {
	if (localStorage.getItem('applicationState') !== null) {
		const urlParams = new URLSearchParams(window.location.search);
		const staffSource = urlParams.get("source");
		let reduxState = JSON.parse(localStorage.getItem('applicationState'));
		removeCachingForKeys(reduxState, staffSource, ['userAddress','filter'])

		if (localStorage.getItem('appointment')) {
			let appointmentState = JSON.parse(localStorage.getItem('appointment'));
			if(localStorage.getItem('appointmentBookingTimeLeft')){
				appointmentState.appointmentBookingTimeLeft = localStorage.getItem('appointmentBookingTimeLeft');
			}
			reduxState['appointment'] = appointmentState;
			localStorage.removeItem('appointment');
			localStorage.removeItem('appointmentBookingTimeLeft');
		}

		if(localStorage.getItem('appState')){
			reduxState = JSON.parse(localStorage.getItem('appState'));
			if(reduxState?.map){
				reduxState.map.isGoogleApiLoaded = false
			}
			localStorage.removeItem('appState');
		}

		return reduxState;
	}
}

/* istanbul ignore next */
export const store = configureStore({
	reducer: {
		[appointmentApi.reducerPath]: appointmentApi.reducer,
		[customerApi.reducerPath]: customerApi.reducer,
		[configsApi.reducerPath]: configsApi.reducer,
		[extendSessionApi.reducerPath]: extendSessionApi.reducer,
		[locationApi.reducerPath]: locationApi.reducer,
		[locationSiteIdApi.reducerPath]: locationSiteIdApi.reducer,
		[locationTimeSlotsApi.reducerPath]: locationTimeSlotsApi.reducer,
		[mccCustomerApi.reducerPath]: mccCustomerApi.reducer,
		[reservationApi.reducerPath]: reservationApi.reducer,
		[deviceApi.reducerPath]: deviceApi.reducer,
		[authToken.reducerPath]: authToken.reducer,
		[authorizeStaff.reducerPath]: authorizeStaff.reducer,
		[earliestAppointmentTimeApi.reducerPath]: earliestAppointmentTimeApi.reducer,
		[syncCustomerApi.reducerPath]: syncCustomerApi.reducer,
		[pscWaitTimeApi.reducerPath]: pscWaitTimeApi.reducer,

		appointment: appointmentReducer,
		auth: authReducer,
		config: configReducer,
		filter: filterReducer,
		map: mapReducer,
		search: searchReducer,
		sortCriteria: sortCriteriaReducer,
		userAddress: userAddressReducer,
		selectedProvince: selectedLocationReducer,
		deviceHistory: deviceHistoryReducer,
		appState: appStateReducer,
		customerDetails: customerDetailsReducer
	},
	preloadedState: reHydrateStore(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(customerApi.middleware)
			.concat(configsApi.middleware)
			.concat(locationApi.middleware)
			.concat(locationSiteIdApi.middleware)
			.concat(appointmentApi.middleware)
			.concat(locationTimeSlotsApi.middleware)
			.concat(reservationApi.middleware)
			.concat(extendSessionApi.middleware)
			.concat(mccCustomerApi.middleware)
			.concat(authorizeStaff.middleware)
			.concat(deviceApi.middleware)
			.concat(authToken.middleware)
			.concat(earliestAppointmentTimeApi.middleware)
			.concat(syncCustomerApi.middleware)
			.concat(pscWaitTimeApi.middleware)
			.concat(localStorageMiddleware),
})

setupListeners(store.dispatch)
