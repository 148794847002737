import React from "react";
import Text from 'components/Text'
 
const GeneralErrorPage = () => {
  return (
    <div>
      <Text variant="h1" i18nKey="BROKEN_LINK"/>
      <Text variant="paragraph1" i18nKey="BROKEN_LINK_TEXT"/>
      <Text variant="paragraph1" i18nKey="PAGE_NOT_AVAILABLE"/>
    </div>
  );
};

export default GeneralErrorPage;