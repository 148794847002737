import { Card, Grid, Stack, Typography } from '@mui/material'
import Text from 'components/Text'
import EyeButton from 'components/data-display/IconImg/EyeButton'
import moment from 'moment'
import { Strings } from 'utils/Strings'
import { getLocation, getAppointmentType } from 'utils/common.utils'
import { TIME_FORMAT } from 'utils/constants/time-constants'
import { VERTICAL_BAR } from 'utils/constants/location-constants'
import {
	calculateDurationInMins,
	convertToLocalizedTime,
	formatDateTimein12HourWithTimezone,
} from 'utils/dateTime.utils'
import { testAttr } from 'utils/test/test.utils'

const PastAppointmentCard = ({
	pastAppointment = {},
	handleClickApptDetails,
	index,
	...props
}) => {
	return (
		<Card
			sx={{
				borderRadius: '1rem',
				width: '100%',
				padding: '1rem',
				marginBottom: '.72rem',
				backgroundColor: index % 2 === 0 ? 'info.bg' : 'white',
				border: '2px solid',
				borderColor: 'info.bg',
				boxShadow: 'none',
			}}
			{...props}
		>
			<Grid container>
				<Grid
					item
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flexGrow: {
							xs: 0,
							md: 1,
						},
					}}
					xs={7}
					md={11}
				>
					<Grid
						sx={{
							display: 'flex',
							flexDirection: {
								xs: 'column',
								md: 'row',
							},
							justifyContent: 'space-between',
						}}
						container
					>
						<Stack sx={{ flexGrow: 1, flexBasis: 0 }}>
							<Typography variant="paragraph2">
								{pastAppointment.contact?.firstName || ''}{' '}
								{pastAppointment.contact?.lastName || ''}
							</Typography>
							<Typography variant="paragraph3">
								{getAppointmentType(pastAppointment)}
								{VERTICAL_BAR}
								{calculateDurationInMins(
									pastAppointment.startTime,
									pastAppointment.endTime
								)}
							</Typography>
						</Stack>
						<Stack sx={{ flexGrow: 1, flexBasis: 0 }}>
							<Typography variant="paragraph3">
								{moment(pastAppointment.appointmentDate).format('MMM DD, YYYY')}
								{' |  '}
								{formatDateTimein12HourWithTimezone(
									pastAppointment.startTime,
									pastAppointment?.timeZone
								)}
							</Typography>
							<Typography variant="paragraph3">
								{getLocation(pastAppointment.locations)}
							</Typography>
						</Stack>
						<Stack
							mr={2}
							sx={{
								display: { xs: 'none', md: 'flex' },
							}}
						>
							<Text
								variant="paragraph3"
								sx={{ textAlign: 'right' }}
								i18nKey="LBL_CONFIRMATION"
							/>
							<Typography variant="paragraph3" sx={{ textAlign: 'right' }}>
								{pastAppointment.bookingConfirmationNumber}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
				<Grid
					item
					xs={5}
					md={1}
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<EyeButton
						aria-label="appointment-details"
						title="appointment-details"
						onClick={handleClickApptDetails}
						{...testAttr('eye-btn-' + index)}
						sx={{ alignSelf: 'flex-end' }}
					/>
					<Stack
						sx={{
							display: { md: 'none' },
						}}
					>
						<Text
							variant="paragraph3"
							sx={{ textAlign: 'right' }}
							i18nKey="LBL_CONFIRMATION"
						/>
						<Typography sx={{ textAlign: 'right' }} variant="paragraph3">
							{pastAppointment.bookingConfirmationNumber}
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Card>
	)
}

export default PastAppointmentCard
