import Button from '@mui/material/Button'
import { Trans } from 'react-i18next'

const RoundedButton = ({ color, variant, children, i18nKey, id, ...props }) => {
	return (
		<Button
			variant={variant || 'contained'}
			color={color || 'primary'}
			{...props}
			sx={{
				borderRadius: '80px',
				textTransform: 'none',
				height: '2.75rem',
				width: {
					xs: '10rem',
					md: '13.125rem'
				},
				fontSize: '1rem',
				...props.sx,
			}}
			id={id}
		>
			{
				i18nKey ? <Trans
					i18nKey={i18nKey} /> : <></>
			}
			{children}
		</Button>
	)
}

export default RoundedButton
