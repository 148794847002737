function Plus({ fillColor }) {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line
				x1="0.75"
				y1="8.40381"
				x2="16.25"
				y2="8.40381"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<line
				x1="8.5957"
				y1="0.75"
				x2="8.5957"
				y2="16.25"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default Plus
