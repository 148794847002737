function User({ fillColor }) {
	return (
		<svg
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.14646 1.7334C6.85098 1.7334 5.80078 2.78359 5.80078 4.07908C5.80078 5.37456 6.85098 6.42476 8.14646 6.42476C9.44194 6.42476 10.4921 5.37456 10.4921 4.07908C10.4921 2.78359 9.44194 1.7334 8.14646 1.7334ZM4.30078 4.07908C4.30078 1.95517 6.02255 0.233398 8.14646 0.233398C10.2704 0.233398 11.9921 1.95517 11.9921 4.07908C11.9921 6.20299 10.2704 7.92476 8.14646 7.92476C6.02255 7.92476 4.30078 6.20299 4.30078 4.07908Z"
				fill={fillColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.2041 13.9858C1.2041 11.52 3.20307 9.521 5.66892 9.521H10.622C13.0879 9.521 15.0868 11.52 15.0868 13.9858C15.0868 15.7678 13.6422 17.2124 11.8603 17.2124H4.43064C2.64867 17.2124 1.2041 15.7678 1.2041 13.9858ZM5.66892 11.021C4.03149 11.021 2.7041 12.3484 2.7041 13.9858C2.7041 14.9394 3.4771 15.7124 4.43064 15.7124H11.8603C12.8138 15.7124 13.5868 14.9394 13.5868 13.9858C13.5868 12.3484 12.2594 11.021 10.622 11.021H5.66892Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export default User
