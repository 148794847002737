import { Grid, Stack, Typography } from '@mui/material'
import Text from 'components/Text'
import { Strings } from 'utils/Strings'
import { DAYS } from 'utils/constants/time-constants'
import { getCurrentDay, getFormattedTime } from 'utils/dateTime.utils'

/* istanbul ignore next */
const Hours = ({ hours = [], description = '' }) => {
	return (
		<Stack>
			<Text.Bold
				variant="subtitle2"
				sx={{ marginBottom: '1rem' }}
				i18nKey='LBL_HOURS_OF_OPERATION'
			/>
			<Stack
				sx={{
					borderLeft: '1px solid',
					borderLeftColor: '#000000',
					paddingLeft: '1rem',
				}}
			>
				{hours?.map((hour, index) => {
					return (
						<Grid
							container
							key={index}
							sx={{ marginBottom: '0.5rem' }}
						>
							<Grid item xs={3}>
								<span style={{ fontWeight: getCurrentDay() == hour.day ? '600' : '400'}}>{hour.day?.slice(0, 3)}</span>
							</Grid>
							<Grid item xs={9}>
								<Typography
									sx={{
										fontSize: '0.951rem',
										fontWeight: getCurrentDay() == hour.day ? '600' : '400',
									}}
									i18nKey={hour.closed ? 'LBL_CLOSED' : ''}
								>
									{!hour.closed ?
										`${getFormattedTime(hour.openTime)} - ${getFormattedTime(
											hour.closeTime
										)}` : Strings.CLOSED}
								</Typography>
							</Grid>
						</Grid>
					)
				})}
			</Stack>
			{<Typography>{description}</Typography>}
		</Stack>
	)
}

export default Hours
