import { Grid, Typography } from "@mui/material";

const NoRecords = ({ noRecordsMessage }) => {
  return (
    <Grid
      sx={{
        padding: '1rem',
        marginBottom: '2rem',
        backgroundColor: 'info.dark',
        borderRadius: '1rem'
      }}>
      <Typography>{noRecordsMessage}</Typography>
    </Grid>
  );
}

export default NoRecords;
