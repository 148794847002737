const colors = {
	white: {
		400: '#FFFFFF',
	},
	black: {
		400: '#000000',
	},
	red: {
		200: '#FFF3F4',
		300: '#F2D7D9',
		400: '#A80014',
		500: '#D90D2B',
	},
	grey: {
		200: '#FAFAFA',
		400: '#E0E0E0',
		500: '#595959',
	},
	green: {
		100: '#6CFE7B',
		200: '#437048',
		300: '#1FD531',
		350: '#EDFEEF',
		400: '#6CFE7B',
	},
	orange: {
		200: '#FFF4DB',
		400: '#FFBB54',
		500: '#FE730E',
	},
	blue: {
		200: '#F3F6FA',
		300: '#2D76E9',
		350: '#DDE5F1',
		400: '#13499F',
		500: '#0E397E33',
	},
	brown: {
		400: '#B8520A',
	},
}

export { colors }
