export const DAYS_OF_WEEK = ["SU", "MO", "TU", "WE", "Thu", "Fri", "Sat"]
export const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

export const DATE_FORMAT = 'ddd, MMM DD, YYYY'

export const TIME_FORMAT = 'hh:mm A'
export const TZ_FORMAT = 'ddd MMM DD YYYY HH:mm:ss [GMT]Z'

export const Timezones = [
    { name: 'America/Edmonton', province: 'Alberta', abbreviation: 'AB', timezoneAbbreviation: 'MST', offset: -7 },
    { name: 'America/Vancouver', province: 'British Columbia', abbreviation: 'BC', timezoneAbbreviation: 'PST', offset: -8 },
    { name: 'America/Winnipeg', province: 'Manitoba', abbreviation: 'MB', timezoneAbbreviation: 'CST', offset: -6 },
    { name: 'America/Halifax', province: 'Nova Scotia', abbreviation: 'NS', timezoneAbbreviation: 'AST', offset: -4 },
    { name: 'America/St_Johns', province: 'Newfoundland and Labrador', abbreviation: 'NL', timezoneAbbreviation: 'NST', offset: -3.5 },
    { name: 'America/Toronto', province: 'Ontario', abbreviation: 'ON', timezoneAbbreviation: 'EST', offset: -5 },
    { name: 'America/Regina', province: 'Saskatchewan', abbreviation: 'SK', timezoneAbbreviation: 'CST', offset: -6 },
    { name: 'America/Yellowknife', province: 'Northwest Territories', abbreviation: 'NT', timezoneAbbreviation: 'MST', offset: -7 },
    { name: 'America/Iqaluit', province: 'Nunavut', abbreviation: 'NU', timezoneAbbreviation: 'AST', offset: -4 },
    { name: 'America/Whitehorse', province: 'Yukon', abbreviation: 'YT', timezoneAbbreviation: 'PST', offset: -8 },
    { name: 'America/Moncton', province: 'New Brunswick', abbreviation: 'NB', timezoneAbbreviation: 'AST', offset: -4 },
    { name: 'America/Charlottetown', province: 'Prince Edward Island', abbreviation: 'PE', timezoneAbbreviation: 'AST', offset: -4 },
    { name: 'America/Montreal', province: 'Quebec', abbreviation: 'QC', timezoneAbbreviation: 'EST', offset: -5 },
    { name: "Asia/Calcutta", abbreviation: "IST", offset: 5.5 }
];

export const TimezoneAbbreviation = [
    { name: 'Mountain Standard Time', abbreviation: 'MST' },
    { name: 'Pacific Standard Time', abbreviation: 'PST' },
    { name: 'Central Standard Time', abbreviation: 'CST' },
    { name: 'Atlantic Standard Time', abbreviation: 'AST' },
    { name: 'Newfoundland Standard Time', abbreviation: 'NST' },
    { name: 'Eastern Standard Time', abbreviation: 'EST' }
];

export const microsoftSupportedTimezones = [
    { name: 'Dateline Standard Time', province: 'International', abbreviation: 'INTL', timezoneAbbreviation: 'DST', offset: -12, browserTimezone: 'Etc/GMT+12' },
    { name: 'UTC-11', province: 'Pacific/International', abbreviation: 'INTL', timezoneAbbreviation: 'UTC-11', offset: -11, browserTimezone: 'Etc/GMT+11' },
    { name: 'Samoa Standard Time', province: 'Samoa', abbreviation: 'WS', timezoneAbbreviation: 'SST', offset: -11, browserTimezone: 'Pacific/Apia' },
    { name: 'Hawaiian Standard Time', province: 'Hawaii', abbreviation: 'HI', timezoneAbbreviation: 'HST', offset: -10, browserTimezone: 'Pacific/Honolulu' },
    { name: 'Alaskan Standard Time', province: 'Alaska', abbreviation: 'AK', timezoneAbbreviation: 'AKST', offset: -9, browserTimezone: 'America/Anchorage' },
    { name: 'Pacific Standard Time (Mexico)', province: 'Baja California', abbreviation: 'BC', timezoneAbbreviation: 'PST', offset: -8, browserTimezone: 'America/Tijuana' },
    { name: 'Pacific Standard Time', province: 'California', abbreviation: 'CA', timezoneAbbreviation: 'PST', offset: -8, browserTimezone: 'America/Los_Angeles' },
    { name: 'US Mountain Standard Time', province: 'Arizona', abbreviation: 'AZ', timezoneAbbreviation: 'MST', offset: -7, browserTimezone: 'America/Phoenix' },
    { name: 'Mountain Standard Time (Mexico)', province: 'Chihuahua', abbreviation: 'CHH', timezoneAbbreviation: 'MST', offset: -7, browserTimezone: 'America/Chihuahua' },
    { name: 'Mountain Standard Time', province: 'Colorado', abbreviation: 'CO', timezoneAbbreviation: 'MST', offset: -7, browserTimezone: 'America/Denver' },
    { name: 'Central America Standard Time', province: 'Central America', abbreviation: 'CAM', timezoneAbbreviation: 'CST', offset: -6, browserTimezone: 'America/Guatemala' },
    { name: 'Central Standard Time', province: 'Texas', abbreviation: 'TX', timezoneAbbreviation: 'CST', offset: -6, browserTimezone: 'America/Chicago' },
    { name: 'Central Standard Time (Mexico)', province: 'Mexico City', abbreviation: 'CDMX', timezoneAbbreviation: 'CST', offset: -6, browserTimezone: 'America/Mexico_City' },
    { name: 'Canada Central Standard Time', province: 'Saskatchewan', abbreviation: 'SK', timezoneAbbreviation: 'CST', offset: -6, browserTimezone: 'America/Regina' },
    { name: 'SA Pacific Standard Time', province: 'Bogota', abbreviation: 'CO', timezoneAbbreviation: 'COT', offset: -5, browserTimezone: 'America/Bogota' },
    { name: 'Eastern Standard Time', province: 'New York', abbreviation: 'NY', timezoneAbbreviation: 'EST', offset: -5, browserTimezone: 'America/New_York' },
    { name: 'US Eastern Standard Time', province: 'New York', abbreviation: 'NY', timezoneAbbreviation: 'EST', offset: -5, browserTimezone: 'America/New_York' },
    { name: 'Venezuela Standard Time', province: 'Venezuela', abbreviation: 'VE', timezoneAbbreviation: 'VET', offset: -4.5, browserTimezone: 'America/Caracas' },
    { name: 'Paraguay Standard Time', province: 'Paraguay', abbreviation: 'PY', timezoneAbbreviation: 'PYT', offset: -4, browserTimezone: 'America/Asuncion' },
    { name: 'Atlantic Standard Time', province: 'Puerto Rico', abbreviation: 'PR', timezoneAbbreviation: 'AST', offset: -4, browserTimezone: 'America/Puerto_Rico' },
    { name: 'Central Brazilian Standard Time', province: 'Brasilia', abbreviation: 'BR', timezoneAbbreviation: 'BRT', offset: -4, browserTimezone: 'America/Cuiaba' },
    { name: 'SA Western Standard Time', province: 'Lima', abbreviation: 'PE', timezoneAbbreviation: 'PET', offset: -5, browserTimezone: 'America/Lima' },
    { name: 'Pacific SA Standard Time', province: 'Santiago', abbreviation: 'CL', timezoneAbbreviation: 'CLT', offset: -4, browserTimezone: 'America/Santiago' },
    { name: 'Newfoundland Standard Time', province: 'Newfoundland and Labrador', abbreviation: 'NL', timezoneAbbreviation: 'NST', offset: -3.5, browserTimezone: 'America/St_Johns' },
    { name: 'E. South America Standard Time', province: 'Brasilia', abbreviation: 'BR', timezoneAbbreviation: 'BRT', offset: -3, browserTimezone: 'America/Sao_Paulo' },
    { name: 'Argentina Standard Time', province: 'Buenos Aires', abbreviation: 'AR', timezoneAbbreviation: 'ART', offset: -3, browserTimezone: 'America/Argentina/Buenos_Aires' },
    { name: 'SA Eastern Standard Time', province: 'Montevideo', abbreviation: 'UY', timezoneAbbreviation: 'UYT', offset: -3, browserTimezone: 'America/Montevideo' },
    { name: 'Greenland Standard Time', province: 'Greenland', abbreviation: 'GL', timezoneAbbreviation: 'GMT-3', offset: -3, browserTimezone: 'America/Godthab' },
    { name: 'Montevideo Standard Time', province: 'Montevideo', abbreviation: 'UY', timezoneAbbreviation: 'UYT', offset: -3, browserTimezone: 'America/Montevideo' },
    { name: 'UTC-02', province: 'Atlantic/International', abbreviation: 'INTL', timezoneAbbreviation: 'UTC-2', offset: -2, browserTimezone: 'Etc/GMT+2' },
    { name: 'Mid-Atlantic Standard Time', province: 'Mid-Atlantic', abbreviation: 'MAT', timezoneAbbreviation: 'MAT', offset: -2, browserTimezone: 'Etc/GMT+2' },
    { name: 'Azores Standard Time', province: 'Azores', abbreviation: 'PT', timezoneAbbreviation: 'AZOT', offset: -1, browserTimezone: 'Atlantic/Azores' },
    { name: 'Cape Verde Standard Time', province: 'Cape Verde', abbreviation: 'CV', timezoneAbbreviation: 'CVT', offset: -1, browserTimezone: 'Atlantic/Cape_Verde' },
    { name: 'Morocco Standard Time', province: 'Morocco', abbreviation: 'MA', timezoneAbbreviation: 'WET', offset: 0, browserTimezone: 'Africa/Casablanca' },
    { name: 'UTC', province: 'Universal', abbreviation: 'UTC', timezoneAbbreviation: 'UTC', offset: 0, browserTimezone: 'Etc/UTC' },
    { name: 'GMT Standard Time', province: 'United Kingdom', abbreviation: 'UK', timezoneAbbreviation: 'GMT', offset: 0, browserTimezone: 'Europe/London' },
    { name: 'Greenwich Standard Time', province: 'Universal', abbreviation: 'GMT', timezoneAbbreviation: 'GMT', offset: 0, browserTimezone: 'Europe/London' },
    { name: 'W. Europe Standard Time', province: 'Western Europe', abbreviation: 'WE', timezoneAbbreviation: 'CET', offset: 1, browserTimezone: 'Europe/Berlin' },
    { name: 'Central Europe Standard Time', province: 'Central Europe', abbreviation: 'CE', timezoneAbbreviation: 'CET', offset: 1, browserTimezone: 'Europe/Budapest' },
    { name: 'Romance Standard Time', province: 'Paris', abbreviation: 'FR', timezoneAbbreviation: 'CET', offset: 1, browserTimezone: 'Europe/Paris' },
    { name: 'Central European Standard Time', province: 'Berlin', abbreviation: 'DE', timezoneAbbreviation: 'CET', offset: 1, browserTimezone: 'Europe/Berlin' },
    { name: 'W. Central Africa Standard Time', province: 'Central Africa', abbreviation: 'CA', timezoneAbbreviation: 'WAT', offset: 1, browserTimezone: 'Africa/Lagos' },
    { name: 'Namibia Standard Time', province: 'Namibia', abbreviation: 'NA', timezoneAbbreviation: 'CAT', offset: 1, browserTimezone: 'Africa/Windhoek' },
    { name: 'Jordan Standard Time', province: 'Jordan', abbreviation: 'JO', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Asia/Amman' },
    { name: 'GTB Standard Time', province: 'Greece', abbreviation: 'GR', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Europe/Athens' },
    { name: 'Middle East Standard Time', province: 'Beirut', abbreviation: 'LB', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Asia/Beirut' },
    { name: 'Egypt Standard Time', province: 'Egypt', abbreviation: 'EG', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Africa/Cairo' },
    { name: 'Syria Standard Time', province: 'Syria', abbreviation: 'SY', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Asia/Damascus' },
    { name: 'South Africa Standard Time', province: 'South Africa', abbreviation: 'ZA', timezoneAbbreviation: 'SAST', offset: 2, browserTimezone: 'Africa/Johannesburg' },
    { name: 'FLE Standard Time', province: 'Finland', abbreviation: 'FI', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Europe/Helsinki' },
    { name: 'Israel Standard Time', province: 'Israel', abbreviation: 'IL', timezoneAbbreviation: 'IST', offset: 2, browserTimezone: 'Asia/Jerusalem' },
    { name: 'E. Europe Standard Time', province: 'Eastern Europe', abbreviation: 'EE', timezoneAbbreviation: 'EET', offset: 2, browserTimezone: 'Europe/Bucharest' },
    { name: 'Arabic Standard Time', province: 'Baghdad', abbreviation: 'IQ', timezoneAbbreviation: 'AST', offset: 3, browserTimezone: 'Asia/Baghdad' },
    { name: 'Arab Standard Time', province: 'Riyadh', abbreviation: 'SA', timezoneAbbreviation: 'AST', offset: 3, browserTimezone: 'Asia/Riyadh' },
    { name: 'Russian Standard Time', province: 'Moscow', abbreviation: 'RU', timezoneAbbreviation: 'MSK', offset: 3, browserTimezone: 'Europe/Moscow' },
    { name: 'E. Africa Standard Time', province: 'East Africa', abbreviation: 'EA', timezoneAbbreviation: 'EAT', offset: 3, browserTimezone: 'Africa/Nairobi' },
    { name: 'Iran Standard Time', province: 'Iran', abbreviation: 'IR', timezoneAbbreviation: 'IRST', offset: 3.5, browserTimezone: 'Asia/Tehran' },
    { name: 'Arabian Standard Time', province: 'Arabian Peninsula', abbreviation: 'AP', timezoneAbbreviation: 'GST', offset: 4, browserTimezone: 'Asia/Dubai' },
    { name: 'Azerbaijan Standard Time', province: 'Azerbaijan', abbreviation: 'AZ', timezoneAbbreviation: 'AZT', offset: 4, browserTimezone: 'Asia/Baku' },
    { name: 'Mauritius Standard Time', province: 'Mauritius', abbreviation: 'MU', timezoneAbbreviation: 'MUT', offset: 4, browserTimezone: 'Indian/Mauritius' },
    { name: 'Georgian Standard Time', province: 'Georgia', abbreviation: 'GE', timezoneAbbreviation: 'GET', offset: 4, browserTimezone: 'Asia/Tbilisi' },
    { name: 'Caucasus Standard Time', province: 'Caucasus', abbreviation: 'CU', timezoneAbbreviation: 'GET', offset: 4, browserTimezone: 'Asia/Yerevan' },
    { name: 'Afghanistan Standard Time', province: 'Afghanistan', abbreviation: 'AF', timezoneAbbreviation: 'AFT', offset: 4.5, browserTimezone: 'Asia/Kabul' },
    { name: 'Ekaterinburg Standard Time', province: 'Ekaterinburg', abbreviation: 'RU', timezoneAbbreviation: 'YEKT', offset: 5, browserTimezone: 'Asia/Yekaterinburg' },
    { name: 'Pakistan Standard Time', province: 'Pakistan', abbreviation: 'PK', timezoneAbbreviation: 'PKT', offset: 5, browserTimezone: 'Asia/Karachi' },
    { name: 'West Asia Standard Time', province: 'West Asia', abbreviation: 'WA', timezoneAbbreviation: 'UZT', offset: 5, browserTimezone: 'Asia/Tashkent' },
    { name: 'India Standard Time', province: 'India', abbreviation: 'IN', timezoneAbbreviation: 'IST', offset: 5.5, browserTimezone: 'Asia/Kolkata' },
    { name: 'Sri Lanka Standard Time', province: 'Sri Lanka', abbreviation: 'LK', timezoneAbbreviation: 'IST', offset: 5.5, browserTimezone: 'Asia/Colombo' },
    { name: 'Nepal Standard Time', province: 'Nepal', abbreviation: 'NP', timezoneAbbreviation: 'NPT', offset: 5.75, browserTimezone: 'Asia/Kathmandu' },
    { name: 'Central Asia Standard Time', province: 'Kazakhstan', abbreviation: 'KZ', timezoneAbbreviation: 'ALMT', offset: 6, browserTimezone: 'Asia/Almaty' },
    { name: 'Bangladesh Standard Time', province: 'Bangladesh', abbreviation: 'BD', timezoneAbbreviation: 'BST', offset: 6, browserTimezone: 'Asia/Dhaka' },
    { name: 'N. Central Asia Standard Time', province: 'Novosibirsk', abbreviation: 'RU', timezoneAbbreviation: 'NOVT', offset: 6, browserTimezone: 'Asia/Novosibirsk' },
    { name: 'Myanmar Standard Time', province: 'Myanmar', abbreviation: 'MM', timezoneAbbreviation: 'MMT', offset: 6.5, browserTimezone: 'Asia/Yangon' },
    { name: 'SE Asia Standard Time', province: 'SE Asia', abbreviation: 'SEA', timezoneAbbreviation: 'ICT', offset: 7, browserTimezone: 'Asia/Bangkok' },
    { name: 'North Asia Standard Time', province: 'Krasnoyarsk', abbreviation: 'RU', timezoneAbbreviation: 'KRAT', offset: 7, browserTimezone: 'Asia/Krasnoyarsk' },
    { name: 'China Standard Time', province: 'China', abbreviation: 'CN', timezoneAbbreviation: 'CST', offset: 8, browserTimezone: 'Asia/Shanghai' },
    { name: 'North Asia East Standard Time', province: 'Irkutsk', abbreviation: 'RU', timezoneAbbreviation: 'IRKT', offset: 8, browserTimezone: 'Asia/Irkutsk' },
    { name: 'Singapore Standard Time', province: 'Singapore', abbreviation: 'SG', timezoneAbbreviation: 'SGT', offset: 8, browserTimezone: 'Asia/Singapore' },
    { name: 'W. Australia Standard Time', province: 'Western Australia', abbreviation: 'WA', timezoneAbbreviation: 'AWST', offset: 8, browserTimezone: 'Australia/Perth' },
    { name: 'Taipei Standard Time', province: 'Taiwan', abbreviation: 'TW', timezoneAbbreviation: 'CST', offset: 8, browserTimezone: 'Asia/Taipei' },
    { name: 'Ulaanbaatar Standard Time', province: 'Mongolia', abbreviation: 'MN', timezoneAbbreviation: 'ULAT', offset: 8, browserTimezone: 'Asia/Ulaanbaatar' },
    { name: 'Tokyo Standard Time', province: 'Japan', abbreviation: 'JP', timezoneAbbreviation: 'JST', offset: 9, browserTimezone: 'Asia/Tokyo' },
    { name: 'Korea Standard Time', province: 'South Korea', abbreviation: 'KR', timezoneAbbreviation: 'KST', offset: 9, browserTimezone: 'Asia/Seoul' },
    { name: 'Yakutsk Standard Time', province: 'Yakutsk', abbreviation: 'RU', timezoneAbbreviation: 'YAKT', offset: 9, browserTimezone: 'Asia/Yakutsk' },
    { name: 'Cen. Australia Standard Time', province: 'Northern Territory', abbreviation: 'NT', timezoneAbbreviation: 'ACST', offset: 9.5, browserTimezone: 'Australia/Darwin' },
    { name: 'AUS Central Standard Time', province: 'South Australia', abbreviation: 'SA', timezoneAbbreviation: 'ACST', offset: 9.5, browserTimezone: 'Australia/Adelaide' },
    { name: 'E. Australia Standard Time', province: 'Queensland', abbreviation: 'QLD', timezoneAbbreviation: 'AEST', offset: 10, browserTimezone: 'Australia/Brisbane' },
    { name: 'AUS Eastern Standard Time', province: 'New South Wales', abbreviation: 'NSW', timezoneAbbreviation: 'AEST', offset: 10, browserTimezone: 'Australia/Sydney' },
    { name: 'West Pacific Standard Time', province: 'Guam', abbreviation: 'GU', timezoneAbbreviation: 'ChST', offset: 10, browserTimezone: 'Pacific/Guam' },
    { name: 'Tasmania Standard Time', province: 'Tasmania', abbreviation: 'TAS', timezoneAbbreviation: 'AEST', offset: 10, browserTimezone: 'Australia/Hobart' },
    { name: 'Vladivostok Standard Time', province: 'Vladivostok', abbreviation: 'RU', timezoneAbbreviation: 'VLAT', offset: 10, browserTimezone: 'Asia/Vladivostok' },
    { name: 'Central Pacific Standard Time', province: 'Solomon Islands', abbreviation: 'SB', timezoneAbbreviation: 'SBT', offset: 11, browserTimezone: 'Pacific/Guadalcanal' },
    { name: 'New Zealand Standard Time', province: 'New Zealand', abbreviation: 'NZ', timezoneAbbreviation: 'NZST', offset: 12, browserTimezone: 'Pacific/Auckland' },
    { name: 'UTC+12', province: 'Pacific/International', abbreviation: 'INTL', timezoneAbbreviation: 'UTC+12', offset: 12, browserTimezone: 'Etc/GMT-12' },
    { name: 'Fiji Standard Time', province: 'Fiji', abbreviation: 'FJ', timezoneAbbreviation: 'FJT', offset: 12, browserTimezone: 'Pacific/Fiji' },
    { name: 'Kamchatka Standard Time', province: 'Kamchatka', abbreviation: 'RU', timezoneAbbreviation: 'PETT', offset: 12, browserTimezone: 'Asia/Kamchatka' },
    { name: 'Tonga Standard Time', province: 'Tonga', abbreviation: 'TO', timezoneAbbreviation: 'TOT', offset: 13, browserTimezone: 'Pacific/Tongatapu' }
];
