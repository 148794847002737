import { useState, useEffect } from 'react'
import { STRINGS } from 'utils/constants/string.constants'

const { Stack, Typography, Grid, Chip } = require('@mui/material')

const TestsList = ({ locationDetails = {} }) => {
	const [testCategories, setTestCategories] = useState([])

	//TODO: Sync with backend
	useEffect(() => {
		const specialityTests = {
			title: STRINGS.SPECIALITY_TESTS,
			tests: [...locationDetails.specialityTest || []],
		}

		const toleranceTests = {
			title: STRINGS.TOLERANCE_TEST,
			tests: [...locationDetails.toleranceTest || []],
		}

		setTestCategories([specialityTests, toleranceTests])
	}, [locationDetails])

	return (
		<Stack sx={{ padding: '1rem 0' }}>
			<Tests testCategories={testCategories} />
		</Stack>
	)
}

const Tests = ({ testCategories = [] }) => {
	return (
		<Stack>
			{testCategories.map((testCategory, index) => {
				return (
					<Stack key={index}>
						<Typography 
							variant="subtitle1"
							sx={{
								fontWeight: {
									xs: "bold"
								}
							}}
						>
							{testCategory.title}
						</Typography>
						<Grid 
							container
							sx={{
								flexWrap: 'wrap',
							}}
						>
							{testCategory.tests?.map((test = {}, testIndex) => {
								return (
									<Chip
										key={testIndex}
										label={test.name}
										sx={{
											borderRadius: '0.2rem',
											margin: '0.5rem',
											backgroundColor: 'text.light',
											color: 'text.dark',
											height: {
												xs: "auto"
											},
											'& .MuiChip-label': {
											  display: {
												xs: 'block'
											  },
											  whiteSpace: {
												xs: 'normal'
											  },
											},
										}}
									/>
								)
							})}
						</Grid>
					</Stack>
				)
			})}
		</Stack>
	)
}

export default TestsList
