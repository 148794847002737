import { Stack, Grid, Typography, Box, Dialog } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

import CircleButton from 'components/buttons/CircleButton'
import { ReactComponent as Loading } from 'assets/Loading.svg'

import Hours from './Hours'
import Services from './Services'
import TestsList from './TestsList'
import Directions from '../Directions'
import Location from '../location/Location'
import Availability from '../location/Availability'
import { WaitTime } from '../location/Content'

import { Strings } from 'utils/Strings'
import { testAttr } from 'utils/test/test.utils'

import Calendar from 'assets/Calendar'

import Distance from '../Distance'
import Text from 'components/Text'
import NoPhoto from 'components/data-display/NoPhoto'
import IconGrid from 'components/data-display/IconGrid/IconGrid'
import TextButton from 'components/buttons/TextButton'
import { VERTICAL_BAR } from 'utils/constants/location-constants'
import theme from 'utils/theme';
import EarliestTime from '../location/EarliestTime'
import useMobile from 'hooks/useMobile'

const LocationDetails = ({
	showLocationDetails,
	locationDetails = {},
	isLoading,
	distance,
	handleClickDetails,
	handleEarlApptClick,
	handleClickViewAppt,
}) => {

	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isMobile = useMobile();

	return (
		<Dialog 
			open={showLocationDetails} 
			sx={{ width: 'auto' }}
			PaperProps={{
				sx: {
					maxWidth: {
						md: '750px',
						xs: '360px'
					},
					width: '100%'
				},
			}}
		>
			{isLoading ? <Box>
				<Loading 
					style={{
						maxWidth: "390px",
						height: "auto",
						padding: '1rem 1.2rem'
					}}
				/>
			</Box> :
				<Stack>
					<Stack sx={{ padding: '1rem 1.2rem' }}>
						<Grid 
							container 
							justifyContent="space-between"
						>
							<Typography
								sx={{ fontWeight: '600', fontSize: '1.45rem' }}
								variant="subtitle1"
								color="text.dark"
							>
								{locationDetails.siteName}
							</Typography>

							<CircleButton
								circleButtonId='ld-close-btn'
								aria-label="Close location details"
								onClick={handleClickDetails}
							/>
						</Grid>
						<Grid 
							container 
							direction={{
								xs: "column",
								md: 'row'
							}}
							sx={{
								marginTop:{
									xs: "19px",
									md: '0'
								},
								alignItems:{
									xs: "start"
								}
							}}
						>
							<Grid item xs={6} >
								<NoPhoto />
							</Grid>
							<Grid item xs={6} sx={{ paddingLeft: {xs: '0rem', md: "1rem"} }}>
								<Stack>
									<Text.Bold
										variant=""
										className="address"
										sx={{ 
											lineHeight: '1rem',
											marginTop:{
												xs: "10px"
											},
										}}
									>
										{locationDetails.address?.address1}
										{locationDetails.address?.address2}
									</Text.Bold>
									<Typography variant="subtitle2" className="address">
										{locationDetails.address?.city}
										{', '}
										{locationDetails.address?.province}
									</Typography>
									<Grid
										container
										alignItems="center"
										sx={{
											display: 'flex',
											alignItems: 'center',
											borderColor: 'divider',
											borderRadius: 2,
											bgcolor: 'background.paper',
											color: 'text.secondary',
											'& svg': {
												m: 1,
											},
										}}
									>
										<Directions
											locationCordinate={{ ...locationDetails.address }}
											sx ={{
												paddingLeft:{
													xs: "0px"
												}
											}}
										/>
										<Typography 
											sx={{
												margin: {
													xs: "0 0.4rem",
													md: '0 1rem'
												}
											}}
										>
											{VERTICAL_BAR}
										</Typography>
										<Distance
											distance={locationDetails.address?.distance || distance}
										/>
									</Grid>
									<EarliestTime 
										id='btn' 
										location={locationDetails} 
										handleEarlApptClick={handleEarlApptClick}
									/>
									<Location.Actions
										handleClickViewAppt={() =>
											handleClickViewAppt(locationDetails)
										}
										showHomeVisit={false}
									/>
								</Stack>
							</Grid>
						</Grid>
						<Stack>
							<Typography
								variant="paragraph2"
								sx={{
									margin: '0.72rem 0',
									fontWeight: 'bold'
								}}
							>
								{Strings.AT_THIS_LOCATION}
							</Typography>
							<Availability location={locationDetails} />
						</Stack>
						<Grid 
            				container={!isMobile}
							sx={{ 
								margin: '1rem 0',
								flexDirection:{
									xs: "column",
									md: "row"
								}
							}}
						>
							<Grid item xs={6} maxWidth={{ xs: '100%', md: '50%'}}>
								<Hours
									hours={locationDetails.storeHours}
									description={locationDetails.description}
								/>
							</Grid>
							<Grid item xs={6} maxWidth={{ xs: '100%', md: '50%'}} marginTop={{ xs: '24px', md: '0'}}>
								<Services locationDetails={locationDetails} />
							</Grid>
						</Grid>
					</Stack>
					<Grid container sx={{ backgroundColor: 'info.dark', padding: '1rem' }}>
						<TestsList locationDetails={locationDetails} />
					</Grid>
				</Stack>}
		</Dialog>
	)
}

export default LocationDetails
