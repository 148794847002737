import { Typography, Box, Stack, Link, Grid, List, ListItem } from '@mui/material'
import Text from 'components/Text'
import { Trans } from 'react-i18next'
import { LeftBorderGrid } from 'components/CommonComponents'
import { REDURECTION_URLS } from 'utils/constants/redirection-urls'

const AppointmentConfirmedBody = () => {

	const KEYS = [
		"LBL_APPT_CONFIRMED_DOC1",
		"LBL_APPT_CONFIRMED_DOC2",
	]

	const KEYS_1_2 = [
		"LBL_APPT_CONFIRMED_DOC3",
		"LBL_APPT_CONFIRMED_DOC4",
	]

	const KEYS_2 = [
		"LBL_APPT_CONFIRMED_DOC5",
		"LBL_APPT_CONFIRMED_DOC6"
	]

	return (
		<>
			<Grid sx={{
				display: {
					xs: "inherit",
					md: "flex"
				},
				marginTop: {
					xs: 2,
					md: 4
				},
				justifyContent: 'space-between',
			}}>
				<LeftBorderGrid item sx={{ marginBottom: { xs: "24px", md: "0px"}}}>
					<Box mb={2}>
						<Text.Bold variant="paragraph2"
							i18nKey='LBL_APPT_CONFIRMED_WHAT' />
					</Box>
					<Text variant="paragraph3"
						i18nKey='LBL_APPT_CONFIRMED_WHAT_TEXT'>
						<Link href={REDURECTION_URLS.WHAT_TO_EXPECT}>
							<Text.Bold
								sx={{ color: 'primary.main' }}
								variant="paragraph3"
								i18nKey='LBL_APPT_CONFIRMED_WHAT_LINK' />
						</Link>
					</Text>
				</LeftBorderGrid>

				<LeftBorderGrid item>
					<Box mb={2}>
						<Text.Bold variant="paragraph2"
							i18nKey='LBL_APPT_CONFIRMED_TEST_PREP' />
					</Box>
					<Text variant="paragraph3"
						i18nKey='LBL_APPT_CONFIRMED_TEST_PREP_TEXT'>
						<Link href={REDURECTION_URLS.PATIENT_TEST_INSTRUCTIONS}>
							<Text.Bold variant="paragraph3"
								sx={{ color: 'primary.main' }}
								i18nKey='LBL_APPT_CONFIRMED_TEST_PREP_LINK' />
						</Link>
					</Text>
				</LeftBorderGrid>
			</Grid>

			<Grid>
				<Text.Bold mt={6} mb={1}
					i18nKey='LBL_APPT_CONFIRMED_ITEMS'
				/>

				<List mt={2}
					sx={{ listStyleType: 'disc', marginLeft: '1rem' }}>
					{
						[...KEYS, ...KEYS_2].map((item, index) => {
							return (
								<ListItem
									key={index}
									variant="paragraph3"
									sx={{
										display: 'list-item',
										fontSize: 14,
										paddingTop: 0,
										paddingLeft: 1,
									}}
								>
									<Trans i18nKey={item} />
									{
										index === 1 &&
										<List sx={{ listStyleType: 'disc', paddingLeft: 1, }}>
											{
												KEYS_1_2.map((item1, index1) => {
													return <ListItem
														key={index1}
														variant="paragraph3"
														sx={{
															display: 'list-item',
															fontSize: 14,
															paddingTop: 0,
															paddingLeft: 1,
															textWrap: 'wrap',
														}}
													>
														<Trans i18nKey={item1} />
													</ListItem>
												})}

										</List>
									}
								</ListItem>
							)
						})
					}
				</List>
			</Grid>

			<Text.Bold
				i18nKey='LBL_APPT_CONFIRMED_VIEW_RESULTS'
				mt={3} mb={2} />
			<Text variant='paragraph3'
				i18nKey='LBL_APPT_CONFIRMED_RESULTS'
			/>

		</>
	)
}

export default AppointmentConfirmedBody
