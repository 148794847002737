import { createApi } from '@reduxjs/toolkit/query/react'
import {
	LOCATION_PATH,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'
import { getClosedHours } from 'utils/dateTime.utils'

export const transformResponse = (data) => {
    const locationDetails = data?.locationDetails?.[0];
    if (locationDetails && locationDetails.storeHours) {
        locationDetails.storeHours = getClosedHours(locationDetails.storeHours);
    }
    return locationDetails || {};
}

export const locationSiteIdApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'locationSiteId',
	endpoints: (build) => ({
		postLocationBySiteId: build.query({
			query: (data) => {
				return {
					url: `${LOCATION_PATH}`,
					method: 'POST',
					body: { ...data },
				}
			},
			transformResponse,
            transformErrorResponse
		}),

	}),
})

export const { useLazyPostLocationBySiteIdQuery } =
	locationSiteIdApi
