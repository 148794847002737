import { createContext, useContext, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { useLocalStorage } from './useLocalStorage'

import { HOME } from 'utils/constants/routes-constants'

import { setUser } from 'store/reducers/authReducer'

const AuthContext = createContext()

const renameKeys = (obj, newKeys) => {
	const transformedObj = {};
	for (const key in obj) {
	  if (key in newKeys) {
		transformedObj[newKeys[key]] = obj[key];
	  } else {
		transformedObj[key] = obj[key];
	  }
	}
	return transformedObj;
  }
  
  const newKeys = {
	phoneNumber: 'phone',
	identityId: 'id'
  };

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage('user', null)
	const navigate = useNavigate()

	const login = async (data) => {
		const updatedData = renameKeys(data, newKeys);
		setUser(updatedData)
	} 
	
	const logout = () => {
		setUser(null)
	}

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
		}),
		[login, logout, user]
	)
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
