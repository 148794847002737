import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { API_CODE, NEW_HOST_PREPEND } from './url'
import { getAuthToken, getTokenType, removeAuthToken, setAuthToken } from 'utils/common.utils'
import { authToken } from './authTokenApi'
import { store } from 'store';
import { TOKEN_TYPE } from 'utils/constants/common.constants';
import { getAccessToken } from 'App';
import ErrorBoundary from 'features/Error/ErrorBoundary'

const baseQuery = (options, apiCode = API_CODE) => {
    return fetchBaseQuery({
        ...options,
        prepareHeaders: (headers) => {
            headers.set('x-functions-key', apiCode)
            return headers
        },

    })
}

export const transformErrorResponse = (error) => {
    console.error(error);
    return <ErrorBoundary errorType={error.status} />;
}

/* istanbul ignore next */
export const newBaseQuery = fetchBaseQuery({
    baseUrl: NEW_HOST_PREPEND, 
    credentials: 'include',
    prepareHeaders: async (headers) => {
      let token;
      const tokenType = getTokenType();
      if(tokenType === TOKEN_TYPE.AZURE_AD){
        token = await getAccessToken();
      } else {
        token = getAuthToken();
      }

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  })

/* istanbul ignore next */
export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await newBaseQuery(args, api, extraOptions);
    const tokenType = getTokenType();
    // if (result?.error && result?.error?.status === 401 && !window.location.href.includes('/appointments')) {
    //     try {
            
    //         let token;
    //         let expiresIn;

    //         if(tokenType === TOKEN_TYPE.AZURE_AD){
    //             token = await getAccessToken();
    //         } else {
    //             const authResult = await store.dispatch(authToken.endpoints.getAuthToken.initiate(undefined, {forceRefetch: true}));
    //             token = authResult.data?.access_token;
    //             expiresIn = authResult.data?.expires_in;
    //         }

    //         if (token) {
    //             setAuthToken(token, expiresIn);
    //             result = await newBaseQuery(args, api, extraOptions);
    //         } else {
    //             removeAuthToken();
    //             return { error: { status: 401, data: 'Token refresh failed' } };
    //         }
    //     } catch {
    //         removeAuthToken();
    //         return { error: { status: 401, data: 'Token refresh failed' } };
    //     }
    // } 

    if(result?.error && result?.error?.status === 401 && window.location.href.includes('/appointments')){
        removeAuthToken();
    }

    return result;
};

export default baseQuery;