import { Card, Grid, Typography, Stack, Alert } from '@mui/material'

import TextButton from 'components/buttons/TextButton'
import RoundedButton from 'components/buttons/RoundedButton'
import Content from './Content'
import Directions from '../Directions'
import Availability from './Availability'

import { Strings } from 'utils/Strings'
import { testAttr } from 'utils/test/test.utils'

import './location.scss'
import Distance from '../Distance'
import Home from 'assets/House'
import Text from 'components/Text'
import useMobile from 'hooks/useMobile'

const Location = ({ location = {}, id, children, ...props }) => {
	return (
		<Card className="location" {...props} sx={{ paddingTop:{ xs: 0}, ...props.sx }}>
			<Grid container spacing={2} direction={{ xs: "column", md: "row"}}>
				<Grid
					item
					md={1}
					xs={1}
					container
					direction="column"
					alignItems={{
						xs: "inherit",
						md: "center"
					}}
					{...props}
				>
					<Typography 
						variant="h5" 
						sx={{ 
							color: 'blue.400', 
							fontWeight:"bold",
							marginTop:{
								xs: 0,
								md:"3px"
							},
						}}
					>
						{id}
					</Typography>
				</Grid>

				<Grid item xs={11} container>
					{children}
				</Grid>
			</Grid>
		</Card>
	)
}

const Address = ({
	location = {},
	showDistance = true,
	showDirections = true,
	...props
}) => {
	const address = location.address || {}

	return (
		<Stack sx={{ width: '100%', gap:{ xs: "10px"} }} {...props}>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className="name-grid"
			>
				<Text.Bold variant="subtitle1">
					{location.siteName}
				</Text.Bold>
				{showDistance && <Distance distance={location.address?.distance} />}
			</Grid>
			<Grid 
				container 
				justifyContent="space-between" 
				alignItems="center"
			>
				
				<Text.Bold variant="subtitle2">
					{address.address1 && `${address.address1},`}{address.address2 && `${address.address2},`} {address.city},{' '}
					{address.province}
				</Text.Bold>
				{showDirections && <Directions locationCordinate={location.address} />}
			</Grid>
		</Stack>
	)
}

Location.Address = Address

const WalkInTag = ({ location = {} }) => {
	return (
		<Stack>
			{location.isWalkingOnly && (
				<Alert
					sx={{
						color: 'success.light',
						backgroundColor: 'success.bg',
						width: 'auto',
						margin: '0.5rem 0',
						justifyContent: 'center',
						padding: '0 0.5rem',
					}}
					icon={false}
					severity="success"
				>
					{Strings.LOC_STATUS.WIO}
				</Alert>
			)}
		</Stack>
	)
}

Location.WalkInTag = WalkInTag

Location.Content = Content

Location.Availability = Availability

const Actions = ({
	location = {},
	id,
	showHomeVisit = true,
	handleClickViewAppt,
	handleClickBookHomeVisit,
	...props
}) => {

	const isMobile = useMobile()

	return (
		<Grid container justifyContent="space-between" alignItems="center" {...props}>
			{!location.isWalkingOnly && (
				<Grid item xs={isMobile ? 'auto' : (showHomeVisit ? 6 : 12)} sx={{ margin: '0.1rem 0' }}>
					<RoundedButton
						{...testAttr(`view-app-${id}`)}
						onClick={() => handleClickViewAppt(location)}
						sx={{
							width: {
								xs: "161px",
								md: "13.125rem"
							},
							height: {
								xs: "34px",
								md: "2.75rem"
							},
							padding: {
								xs: '1px',
							},
							fontSize: {
								xs: '14px',
								md: '1rem'
							},
							lineHeight: {
								xs: '22px',
								md: '1.125rem'
							}
						}}
					>
						{Strings.VIEW_APPT}
					</RoundedButton>
				</Grid>
			)}

			{showHomeVisit && (
				<Grid item xs={isMobile ? 'auto': 6}>
					<TextButton
						onClick={handleClickBookHomeVisit}
						startIcon={<Home fillColor="#13499F" />}
						sx={{
							fontSize: {
								xs: '12px',
								md: '0.875rem'
							},
							lineHeight: {
								xs: '18px',
								md: '1.125rem'
							},
							fontWeight: {
								xs: 'bold',
								md: '600'
							},
							textDecoration: 'underline'
						}}
					>
						{Strings.BOOK_HOME_VISIT}
					</TextButton>
				</Grid>
			)}
		</Grid>
	)
}

Location.Actions = Actions

export default Location
