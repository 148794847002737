import { useTranslation } from "react-i18next"
import useAppointmentActions from "./useAppointmentActions";

/* istanbul ignore next */
export const useSaveEmlFile = (user, appointmentDetails) => {

    const { appointmentConfirmationEmail } = useAppointmentActions(user, appointmentDetails);
    const t = useTranslation();

    const saveEml = async () => {

        const emlFile = await appointmentConfirmationEmail();

        const blob = new Blob([emlFile], { type: "message/rfc822" });
        const fileUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = "LifeLabs Appointment Confirmation.eml";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    return saveEml;

}