import { ReactComponent as UpdateIcon } from 'assets/Update.svg'
import { ReactComponent as SettingsIcon } from 'assets/Setting.svg'

export const getIcons = (iconCode) => {
    switch (iconCode) {
        case 0:
            return <UpdateIcon />
        case 1:
            return <SettingsIcon />
    }
}

export const LOCATION_STATUS = {
    "CIVIC_HOLIDAY": {
        "BG_COLOR": 'warning.dark',
        "COLOR": "paper.main",
        "ICON_CODE": 0,
        "ICON_COLOR": '#FE730E'
    },
    "TC": {
        "BG_COLOR": 'warning.dark',
        "COLOR": "paper.main",
        "ICON_CODE": 0,
        "ICON_COLOR": '#FE730E'
    },
    "REDUCED_HOURS": {
        "BG_COLOR": 'warning.dark',
        "COLOR": "paper.main",
        "ICON_CODE": 0,
        "ICON_COLOR": '#FFBB54'
    },
    "SD": {
        "BG_COLOR": 'error.dark',
        "COLOR": "error.contrastText",
        "ICON_CODE": 1,
        "ICON_COLOR": '#A80014'
    }
}

export const VERTICAL_BAR = ' | '
