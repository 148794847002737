import {
	MenuItem,
	Typography,
	RadioGroup,
	Radio,
	Grid,
	Link,
} from '@mui/material'
import { Strings } from 'utils/Strings'
import FilterSelect from 'components/FilterSelect'
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import { REDURECTION_URLS } from 'utils/constants/redirection-urls'
import { useSelector } from 'react-redux'
import Text from 'components/Text'
import { useEffect } from 'react'
import { useState } from 'react'

const SpecialtyTestFilter = ({
	selectedFilters = {},
	handleFilterChange = {},
	...props
}) => {
	const specialtyTests = useSelector(state => state.config.specialityTest) || []
	const toleranceTests = useSelector(state => state.config.toleranceTest) || []

	return (
		<FilterSelect
			formSelectId="specilty-tests"
			formSelectlabel={Strings.SPECIALITY_TEST}
			count={selectedFilters?.name?.length > 0 ? 1 : 0}
			value={[specialtyTests]}
			multiple
			{...props}
		>
			<SpecialtyTestFilterList
				selectedFilters={selectedFilters}
				handleFilterChange={handleFilterChange}
				specialtyTests={[...specialtyTests, ...toleranceTests]}
			/>
		</FilterSelect>
	)
}

const SpecialtyTestFilterList = ({
	selectedFilters = {},
	handleFilterChange = {},
	specialtyTests,
	isMobile,
	textProps
}) => {

	const [menuItemSpacing, setMenuItemSpacing] = useState([]);

	useEffect(() => {
		if (specialtyTests) {
			let spacingList = [];
			specialtyTests.forEach((specialtyTest, index) => {
				const textLength = specialtyTest.name.length;
				const baseHeight = 39;
				const minHeight = 39;
				const calculatedHeight = Math.max(minHeight, baseHeight + (textLength - 20));
				spacingList[index] = calculatedHeight;
			})

			setMenuItemSpacing(spacingList);
		}
	}, [specialtyTests])

	return (
		<RadioGroup>
			<Link
				sx={{ width: '100%' }}
				href={REDURECTION_URLS.PATIENT_TEST_INSTRUCTIONS}
				target="_blank"
				rel="noopener"
				tabIndex={0}
			>
				<Grid container sx={{ paddingLeft: '1rem', margin: '0.5rem' }} alignItems="center">
					<Text.Bold
						variant='paragraph3'
						marginRight={1}
						sx={{
							textTransform: 'underline',
							color: '#13499F'
						}}
						i18nKey={'LBL_PATIENT_INSTRUCTION'}
					/>
					<LaunchOutlinedIcon fontSize="small" sx={{ fontSize: '1rem' }} />
				</Grid>
			</Link>
			{specialtyTests?.map((specialtyTest, index) => (
				<MenuItem
					key={index}
					elevation={0}
					sx={{
						backgroundColor: isMobile ? 'white' : '#F3F6FA',
						whiteSpace: 'normal',
						display: 'flex',
						paddingLeft: 0,
						alignItems: 'center',
						width: '100%',
						height: menuItemSpacing[index] || 'auto',
						'&:focus': {
							backgroundColor: isMobile ? 'white' : 'rgba(25, 118, 210, 0.2)'
						}
					}}
					onClick={(event) => {
						event.stopPropagation();
						let isChecked = selectedFilters?.id === specialtyTest.id;
						let newCheckedState = !isChecked;
						handleFilterChange('specialityTests', newCheckedState ? specialtyTest : null);
					}}
					tabIndex={0}
				>
					<Radio
						name={specialtyTest.name}
						value={specialtyTest.id}
						checked={selectedFilters?.id === specialtyTest.id}
						sx={{
							'& .MuiSvgIcon-root': {
								color: 'primary.main'
							}
						}}
					/>
					<Typography
						variant="paragraph3"
						sx={{
							color: '#13499F',
							lineHeight: 1.375,
							...textProps
						}}
					>
						{specialtyTest.name}
					</Typography>
				</MenuItem>
			))}
		</RadioGroup>
	)
}

SpecialtyTestFilter.SpecialtyTestFilterList = SpecialtyTestFilterList;

export default SpecialtyTestFilter
