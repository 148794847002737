import { setupTheme } from './setupTheme'
import { colors } from './colors'

const {
	breakpoints,
	typography: { fontSize },
} = setupTheme
const { white, black } = colors

const typography = {
	h1: {
		fontSize: `${56 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${66 / fontSize}rem`,
	},
	h2: {
		fontSize: `${45 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${56 / fontSize}rem`,
	},
	h3: {
		fontSize: `${36 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${46 / fontSize}rem`,
	},
	h4: {
		fontSize: `${32 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${42 / fontSize}rem`,
	},
	h5: {
		fontSize: `${24 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${34 / fontSize}rem`,
	},
	paragraph1: {
		fontSize: `${20 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${30 / fontSize}rem`,
	},
	paragraph1Mobile: {
		fontSize: `${18 / fontSize}rem`,
		[breakpoints.down('xs')]: {
			fontSize: `${20 / fontSize}rem`,
		},
		color: black[400],
		fontWeight: 400,
		lineHeight: `${30 / fontSize}rem`,
	},
	paragraphBold1: {
		fontSize: `${20 / fontSize}rem`,
		color: black[400],
		fontWeight: 500,
		lineHeight: `${30 / fontSize}rem`,
	},
	paragraph2: {
		fontSize: `${16 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${24 / fontSize}rem`,
	},
	paragraph2SemiBold: {
		fontSize: `${16 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${24 / fontSize}rem`,
	},
	paragraph3: {
		fontSize: `${14 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${22 / fontSize}rem`,
	},
	paragraph3Bold: {
		fontSize: `${14 / fontSize}rem`,
		color: black[400],
		fontWeight: 700,
		lineHeight: `${22 / fontSize}rem`,
	},
	legal: {
		fontSize: `${24 / fontSize}rem`,
		color: black[400],
		fontWeight: 400,
		lineHeight: `${34 / fontSize}rem`,
	},
	button: {
		fontSize: `${14 / fontSize}rem`,
		color: white[400],
		fontWeight: 400,
		lineHeight: `${18 / fontSize}rem`,
		textTransform: 'none',
	},
}

export { typography }
