import Grid from '@mui/material/Grid'

const IconGrid = ({ muiIcon, children, centerIcon = true, ...props }) => {
	return (
		<Grid 
			alignItems="center"
			gap='0.07rem'
			{...props}
			sx={{
				...props.sx,
				display: 'flex'
			}}
			flexDirection='row'
		>
			<Grid
				item
				alignSelf={centerIcon ? 'center' : 'flex-start'}
				justifyContent="center"
				sx={{
					width: 'fit-content',
					marginRight: '0.5rem',
					marginTop: '0.3rem'
				}}
			>
				{muiIcon}
			</Grid>
			<Grid
				item
				sx={{ flexGrow: 1 }}>
				{children}
			</Grid>
		</Grid>
	)
}

export default IconGrid
