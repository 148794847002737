import { Outlet } from "react-router";

const HomeLayout = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default HomeLayout;
