import { createSlice } from '@reduxjs/toolkit'

const initialState = {
}

export const deviceHistoryReducer = createSlice({
	name: 'deviceHistory',
	initialState,
	reducers: {
		setDeviceHistory: (state, action) => {
			return {
				...state,
				...action.payload
			}
		},
        updateDeviceHistory: (state, action) => {
			return {
				...state,
				...action.payload
			}
		}
	},
})

export const { setDeviceHistory, updateDeviceHistory } = deviceHistoryReducer.actions
export default deviceHistoryReducer.reducer
