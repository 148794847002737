import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'

export function useAddressPredictions(input) {
	const { isGoogleApiLoaded } = useSelector((state) => state.map)
	const [predictions, setPredictions] = useState([])

	const autocomplete = useRef()

	if (isGoogleApiLoaded && !autocomplete.current) {
		autocomplete.current = new window.google.maps.places.AutocompleteService()
	}

	function getPlacePredictions(input) {
		autocomplete.current.getPlacePredictions(
			{
				input,
				componentRestrictions: { country: 'ca' },
				type: 'street_address',
			},
			(predictions) => {
				setPredictions(predictions && predictions.map((prediction) => prediction.description))
			}
		)
	}

	// const getPlacePredictions = async (input) => {
	// 	try {
	// 		fetch(
	// 			`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&components=country:ca&key=${process.env.REACT_APP_GEO_CODING_API_KEY}`
	// 		)
	// 		.then((res) => res.json())
	// 			.then((response) => {
	// 				const predictions = response?.predictions;
	// 				setPredictions(predictions ? predictions.map((prediction) => prediction.description) : []);
	// 			})
	// 	} catch (error) {
	// 		console.error('Error fetching place predictions:', error);
	// 	}
	// };

	const debouncedGetPlacePredictions = useMemo(
		() => debounce(getPlacePredictions, 500),
		[]
	)

	useEffect(() => {
		if (input?.replace(/ /g, '')?.length > 2) {
			debouncedGetPlacePredictions(input)
		}
	}, [debouncedGetPlacePredictions, input])

	return predictions
}
