import React from "react";
import Text from 'components/Text'
 
const GatewayTimeoutPage = () => {
  return (
    <div>
      <Text variant="h1" i18nKey="GATEWAY_TIMEOUT"/>
      <Text variant="paragraph1" i18nKey="GATEWAY_TIMEOUT_TEXT"/>
      <Text variant="paragraph1" i18nKey="REFRESH_PAGE"/>
    </div>
  );
};

export default GatewayTimeoutPage;