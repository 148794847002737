import { useState, useEffect } from 'react';
/* istanbul ignore next */
const useCountdownTimer = (initialDuration='2') => {
    let initialTimer;
    if (initialDuration?.includes(':')) {
        const [initialMinutes, initialSeconds] = initialDuration.split(':').map(Number);
        initialTimer = initialMinutes * 60 + initialSeconds;
    } else {
        initialTimer = Number(initialDuration) * 60;
    }

    let interval;

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const [timer, setTimer] = useState(initialTimer);
    const [formattedTime, setFormattedTime] = useState(formatTime(initialTimer));

    const resetTimer = (extendTimeDuration) => {
        clearInterval(interval);
        const newDuration = extendTimeDuration || initialTimer;
        setTimer(newDuration * 60);
        setFormattedTime(formatTime(newDuration * 60));
    };

    useEffect(() => {
        interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer > 0) {
                    setFormattedTime(formatTime(prevTimer - 1));
                    return prevTimer - 1;
                } else {
                    clearInterval(interval);
                    setFormattedTime('0:00');
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return [formattedTime, resetTimer];
};

export default useCountdownTimer;
