
import { testAttr } from 'utils/test/test.utils'
import {
	OutlinedInput,
	Select,
	Typography,
	Box,
	FormControl,
	InputLabel
} from '@mui/material'
import { ReactComponent as ArrowDown } from 'assets/FilterIcons/DropDownArrow.svg'
import { ReactComponent as ArrowUp } from 'assets/FilterIcons/UpArrow.svg'
import { useState } from 'react'

const FilterSelect = ({
	formSelectId,
	formSelectlabel,
	count,
	children,
	showScrollBar = true,
	...props
}) => {
	let countText = '' + count
	countText = countText.length > 0 ? `(${countText})` : ''

	const [arrowIcon, setArrowIcon] = useState("down");

	return (
		<Box>
			<FormControl fullWidth>
				<InputLabel id="filter-select" style={{ display: 'none'}}>Filter Select</InputLabel>
				<Select
					labelId="filter-select-label"
					id="filter-select"
					{...testAttr(formSelectId)}
					IconComponent={(props) =>
						<Box sx={{ marginLeft: 2 }}>
							{arrowIcon === "down" ? <ArrowDown /> : <ArrowUp />}
						</Box>}
					sx={{
						boxShadow: 'none',
						backgroundColor: '#F3F6FA',
						color: '#13499F',
						maxHeight: '36px',
						padding: '6px 12px',
						marginLeft: '12px',
						'&.MuiOutlinedInput-root': {
							'&.Mui-focused': {
							  outline: '2px solid black'
							},
						  },
						'.MuiOutlinedInput-notchedOutline': {
							border: 0,
							display: 'none',
						},
						'&.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline': {
							border: 0,
						},
						'&.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline':
						{
							border: 0,
							paddingRight: '0px',
							'.MuiOutlinedInput-notchedOutline': { border: 0 },
							'&& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':
							{
								paddingRight: 0,
							},
						},
						...props.sx,
					}}
					input={
						<OutlinedInput label={formSelectlabel} />
					}
					SelectDisplayProps={{
						id: formSelectId,
						'aria-label': `${formSelectlabel}${countText}`,
						'aria-controls': `${formSelectId}-options`,
						style: { paddingRight: 'unset' },
					}}
					MenuProps={{
						background: '#F3F6FA',
						PaperProps: showScrollBar ? {
							sx: {
								maxHeight: 300,
								maxWidth: 250,
								backgroundColor: '#F3F6FA',
								'&::-webkit-scrollbar': {
									width: '20px',
								},
								'&::-webkit-scrollbar-thumb': {
									boxShadow: "inset 0 0 11px 11px #13499F",
									border: '8px solid transparent',
									borderRadius: '16px'
								}
							}
						} : {},
						MenuListProps: {
							id: `${formSelectId}-options`,
							sx: {
								paddingTop: 'unset',
								paddingBottom: 'unset',
							},
						},
					}}
					renderValue={() => {
						return (
							<Typography variant="paragraph2" sx={{ color: '#13499F' }}>
								{`${formSelectlabel} ${countText}`}
							</Typography>
						)
					}}
					{...props}
					onOpen={() => setArrowIcon("up")}
					onClose={() => setArrowIcon("down")}	
				>
					{children}
				</Select>
			</FormControl>
		</Box>
	)
}

export default FilterSelect
