import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, IconButton, Link, Menu, MenuItem, Stack } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { visuallyHidden } from '@mui/utils'

import Text from 'components/Text'
import ConfirmDialog from 'components/ConfirmDialog'

import {
	MCC_SHOP,
	APPOINTMENTS,
	HOME,
	LOCATION_FINDER,
} from 'utils/constants/routes-constants'

import MCCLogo from 'assets/mcc-assets/mcc-logo.png'
import MCCLogoON from 'assets/mcc-assets/ON/My-Care-Compass-Logo-Ontario.png'
import MCCLogoBC from 'assets/mcc-assets/BC/My-Care-Compass-Logo-BC.png'
import MCCLogoSK from 'assets/mcc-assets/SK/MyCareCompass.svg'
import MarketIcon from 'assets/mcc-assets/img/marketplace.svg'
import ShopIcon from 'assets/mcc-assets/img/lifelabs-marketplace-blue_icon.png'
import HealthIcon from 'assets/mcc-assets/img/health_profile.svg'
import mobileMCCIcon from 'assets/mcc-assets/favicon.png'

import LocationPin from 'assets/LocationPin'

import strings from 'locales/en-US.json'

import './mcc.header.scss'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updateSelectedProvince } from 'store/reducers/selectedProvinceReducer'
import {
	removeAuthToken,
	removeItemFromBrowserStorage,
} from 'utils/common.utils'
import { useAuth } from 'hooks/useAuth'
import { ReactComponent as InformationIcon } from 'assets/information.svg'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'

/* istanbul ignore next */
const MCCHeader = ({
	isLoggedIn,
	isAppointmentBooking,
	isCreateAccount,
	showSharedReportsLink,
	checkShopNowConfig,
	checkMarketPlaceConfig,
	primeClient,
	isHRAEnabled,
	province = 'ON',
	showProgressPopup,
}) => {
	const navigate = useNavigate()
	const auth = useAuth()
	const user = auth && auth?.user
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenDialog, setIsOpenDialog] = useState(false)
	const [openLogoutModal, setOpenLogoutModal] = useState(false)
	const selectedProvince = useSelector((state) => state.selectedProvince)
	const idToken = useSelector((state) => state.auth?.idToken)

	const [primaryAnchorEl, setPrimaryAnchorEl] = useState(null)
	const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null)

	const openMenu = Boolean(primaryAnchorEl)
	const openAccountMenu = Boolean(secondaryAnchorEl)

	const handleClick = (event, type) => {
		if (type === 'primary') {
			setPrimaryAnchorEl(event.currentTarget)
		} else {
			setSecondaryAnchorEl(event.currentTarget)
		}
	}

	const handleClose = (type) => {
		if (type === 'primary') {
			setPrimaryAnchorEl(null)
		} else {
			setSecondaryAnchorEl(null)
		}
	}

	useEffect(() => {
		const prov = sessionStorage.getItem('prov')
		const url = window.location.href
		const urlObject = new URL(url)
		const ProvinceParams = urlObject.searchParams.get('province')
		if (ProvinceParams) {
			dispatch(updateSelectedProvince(ProvinceParams))
		} else if (prov) {
			dispatch(updateSelectedProvince(prov?.toUpperCase()))
		} else {
			dispatch(updateSelectedProvince(user?.province || 'ON'))
		}
	}, [])

	const getLogoURL = (province) => {
		if (province) {
			if (province.toUpperCase() == 'BC') {
				return MCCLogoBC
			} else if (province.toUpperCase() == 'SK') {
				return MCCLogoSK
			} else {
				return MCCLogoON
			}
		} else {
			return MCCLogo
		}
	}

	const onClickHandlerCloseDialog = () => {
		setIsOpenDialog(false)
	}

	const onClickHandleSubmit = () => {
		setIsOpenDialog(false)
		window.open(MCCRedirectionUrls.Shop_Our_Service, '_blank')
	}

	const logout = () => {
		removeAuthToken()
		removeItemFromBrowserStorage('user')
		window.location.href = MCCRedirectionUrls.Logout.replace(
			'{ID_TOKEN}',
			idToken
		).replace('{LOGOUT_REDIRECTION_URL}',process.env.REACT_APP_BASE_URL_PROD)
	}
	return (
		<>
			<div id="header" className="top-header">
				<div className={`header-container `}>
					<h1 id="logo">
						<a
							id="homeLink"
							title="MyCareCompass"
							onClick={() =>
								showProgressPopup(
									selectedProvince.toUpperCase() === 'BC'
										? MCCRedirectionUrls.MCC_BC_DASHBOARD
										: MCCRedirectionUrls.MCC_ON_DASHBOARD
								)
							}
						>
							<Box sx={{ display: { xs: 'none', md: 'block' } }}>
							<img
								src={getLogoURL(selectedProvince || 'ON')}
								style={{ width: '160px', height: '100%', cursor: 'pointer' }}
								className="focus-padding"
								id="logoImage"
								title="MyCareCompass"
								alt="MyCareCompass"
							/>
							</Box>
							<Box sx={{ display: { xs: 'block', md: 'none' } }}>
							<img
								src={mobileMCCIcon}
								style={{ width: '40px', height: '50%', cursor: 'pointer' }}
								className="focus-padding"
								id="logoImage"
								title="MyCareCompass"
								alt="MyCareCompass"
							/>
							</Box>
						</a>
					</h1>

					<div className="main-header">
						{!isLoggedIn && isAppointmentBooking && (
							<div>
								<span>
									<Text
										variant="paragraph3"
										color="#0f4291"
										i18nKey="APPOINTMENTBOOKINGS_PAGETITLE"
									/>
								</span>

								<span>
									<Text
										variant="paragraph3"
										color="#0f4291"
										i18nKey={'TOOLTIP_APPOINTMENTS_NAV'}
									/>
								</span>
							</div>
						)}

						{isLoggedIn && (
							<div className="header-tab">
								<ul className="nav nav-tabs" role="tablist">
									<li
										role="presentation"
										data-placement="bottom"
										title={t('TOOLTIP_DASHBOARD_NAV')}
									>
										<a
											id="dashboardTab"
											role="tab"
											aria-selected="false"
											onClick={() =>
												showProgressPopup(
													selectedProvince.toUpperCase() === 'BC'
														? MCCRedirectionUrls.MCC_BC_DASHBOARD
														: MCCRedirectionUrls.MCC_ON_DASHBOARD
												)
											}
										>
											<i
												className="icon icon-exc-dashboard-tab"
												aria-hidden="true"
											></i>

											<Text.Bold variant="paragraph3" i18nKey={'LINK_DASHBOARD'} />
										</a>
									</li>

									<li
										role="presentation"
										data-placement="bottom"
										title={t('TOOLTIP_REPORTS_NAV')}
									>
										<a
											id="reportsTab"
											onClick={() =>
												showProgressPopup(
													selectedProvince.toUpperCase() === 'BC'
														? MCCRedirectionUrls.MCC_BC_REPORTS
														: MCCRedirectionUrls.MCC_ON_REPORTS
												)
											}
											role="tab"
											aria-selected="false"
										>
											<i
												className="icon icon-exc-reports-tab"
												aria-hidden="true"
											></i>

											<Text.Bold variant="paragraph3" i18nKey={'LINK_REPORTS'} />
										</a>
									</li>

									<li
										role="presentation"
										data-placement="bottom"
										title={t('TOOLTIP_ANALYTICS_NAV')}
									>
										<a
											id="analyticsTab"
											onClick={() =>
												showProgressPopup(
													selectedProvince.toUpperCase() === 'BC'
														? MCCRedirectionUrls.MCC_BC_ANALYTICS
														: MCCRedirectionUrls.MCC_ON_ANALYTICS
												)
											}
											role="tab"
											aria-selected="false"
										>
											<i className="icon icon-exc-chart" aria-hidden="true" />

											<Text.Bold variant="paragraph3" i18nKey={'LINK_ANALYTICS'} />
										</a>
									</li>

									<li
										role="presentation"
										data-placement="bottom"
										title={'LINK_APPOINTMENTS_TOOLTIP'}
										className={
											window.location.pathname === APPOINTMENTS ? 'active': ''
										}
									>
										<a
											id="appointmentsTab"
											role="tab"
											aria-selected="false"
											onClick={() =>
												showProgressPopup(
													MCCRedirectionUrls.PWT_APPOINTMENTS +
														`?province=${selectedProvince?.toLowerCase()}&source=mcc`
												)
											}
										>
											<i
												className="icon icon-exc-appointments"
												aria-hidden="true"
											></i>

											<Text.Bold
												variant="paragraph3"
												i18nKey={'LINK_APPOINTMENTS'}
											/>
										</a>
									</li>

									{isLoggedIn && checkMarketPlaceConfig && selectedProvince.toUpperCase() !== 'BC' && (
										<li
											role="presentation"
											data-placement="bottom"
											title={t('LINK_MARKETPLACE_TOOLTIP')}
										>
											<a
												id="marketplaceTab"
												onClick={() =>
													showProgressPopup(
														selectedProvince.toUpperCase() === 'BC'
															? MCCRedirectionUrls.MCC_BC_MARKETPLACE
															: MCCRedirectionUrls.MCC_ON_MARKETPLACE
													)
												}
												role="tab"
												aria-selected="false"
											>
												<img
													src={MarketIcon}
													alt={t('LINK_MARKETPLACE_TOOLTIP')}
													width="25px"
													height="25px"
												/>

												<Text.Bold
													variant="paragraph3"
													i18nKey={'LINK_MARKETPLACE'}
												/>
											</a>
										</li>
									)}

									{primeClient && province === 'ON' && isHRAEnabled && (
										<li
											role="presentation"
											data-placement="bottom"
											title={t('LINK_HEALTPROFILE_TOOLTIP')}
										>
											<a
												id="healthprofileTab"
												href="/healthprofile"
												role="tab"
												aria-selected="false"
												style={{ display: 'flex', alignItems: 'center' }}
											>
												<img
													src={HealthIcon}
													alt={t('LINK_HEALTPROFILE')}
													width="25px"
													height="25px"
												/>

												<Text.Bold
													variant="paragraph3"
													i18nKey={'LINK_HEALTHPROFILE'}
												/>
											</a>
										</li>
									)}

									{isLoggedIn && checkShopNowConfig && selectedProvince.toUpperCase() !== 'BC' && (
										<li
											role="presentation"
											data-placement="bottom"
											title={t('LINK_SHOPNOW_TOOLTIP')}
										>
											<a
												id="shopnowTab"
												onClick={() => setIsOpenDialog(true)}
												role="tab"
												aria-selected="false"
												style={{ display: 'flex', alignItems: 'center' }}
											>
												<img
													src={ShopIcon}
													alt={t('LINK_SHOPNOW')}
													width="22px"
													height="22px"
												/>

												<Text.Bold variant="paragraph3" i18nKey={'LINK_SHOPNOW'} />
											</a>
										</li>
									)}
								</ul>
							</div>
						)}

						<Menu
							id="basic-menu"
							anchorEl={secondaryAnchorEl}
							open={openAccountMenu}
							onClose={() => handleClose('secondary')}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
								sx: {
									border: '1px solid #999999',
									boxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
									fontSize: '14px',
									width: '190px',
									padding: '0px',
								},
							}}
						>
							<MenuItem sx={{ display: 'none' }} />
							<MenuItem
								onClick={() => {
									showProgressPopup(
										selectedProvince.toUpperCase() === 'BC'
											? MCCRedirectionUrls.MCC_BC_FAQ
											: MCCRedirectionUrls.MCC_ON_FAQ
									)
									setSecondaryAnchorEl(null)
								}}
								sx={{
									borderRadius: '0px',
									padding: '8px 15px 10px 25px',
									borderTop: '1px solid transparent',
									borderBottom: '1px solid #d2d2d2',
									alignItems: 'center',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<Text variant="paragraph3" i18nKey={'LINK_FAQ'} />
							</MenuItem>
							<MenuItem
								onClick={() => {
									showProgressPopup(
										selectedProvince.toUpperCase() === 'BC'
											? MCCRedirectionUrls.MCC_BC_SUPPORT
											: MCCRedirectionUrls.MCC_ON_SUPPORT
									)
									setSecondaryAnchorEl(null)
								}}
								sx={{
									borderRadius: '0px',
									padding: '8px 15px 10px 25px',
									borderTop: '1px solid transparent',
									borderBottom: '1px solid #d2d2d2',
									alignItems: 'center',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<Text variant="paragraph3" i18nKey={'LINK_SUPPORT'} />
							</MenuItem>
							<MenuItem
								onClick={() => {
									showProgressPopup(
										selectedProvince.toUpperCase() === 'BC'
											? MCCRedirectionUrls.MCC_BC_ACCOUNT
											: MCCRedirectionUrls.MCC_ON_ACCOUNT
									)
									setSecondaryAnchorEl(null)
								}}
								sx={{
									borderRadius: '0px',
									padding: '8px 15px 10px 18px',
									borderTop: '1px solid transparent',
									borderBottom: '1px solid #d2d2d2',
									alignItems: 'center',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<i className="icon icon-exc-account-settings"></i>
								<Text
									variant="paragraph3"
									i18nKey={'LINK_MYACCOUNTSETTINGS'}
									sx={{
										paddingLeft: '5px',
									}}
								/>
							</MenuItem>
							<MenuItem
								onClick={() => {
									setOpenLogoutModal(true)
									setSecondaryAnchorEl(null)
								}}
								sx={{
									borderRadius: '0px',
									padding: '8px 15px 10px 18px',
									borderTop: '1px solid transparent',
									borderBottom: '1px solid #d2d2d2',
									alignItems: 'center',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<i className="icon icon-exc-off"></i>
								<Text
									variant="paragraph3"
									i18nKey={'LINK_LOGOUT'}
									sx={{
										paddingLeft: '5px',
									}}
								/>
							</MenuItem>
						</Menu>

						<IconButton
							edge="start"
							color="inherit"
							aria-label={strings.TOOLTIP_COMMAND_MENU}
							onClick={(e) => handleClick(e, 'primary')}
							sx={{
								mr: 2,
								mt: 1,
								display: {
									md: 'block',
									lg: 'none',
								},
							}}
						>
							<MenuIcon />
							<Text
								style={visuallyHidden}
								variant="paragraph3"
								i18nKey={'TOOLTIP_COMMAND_MENU'}
							/>
						</IconButton>
						<Menu
							id="basic-menu"
							anchorEl={primaryAnchorEl}
							open={openMenu}
							onClose={() => handleClose('primary')}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
								sx: {
									border: '1px solid #999999',
									boxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
									fontSize: '14px',
									width: '190px',
									padding: '0px',
								},
							}}
						>
							<MenuItem
								onClick={(e) => {
									handleClick(e, 'secondary')
								}}
								sx={{
									borderRadius: '0px',
									padding: '8px 15px 10px 18px',
									borderTop: '1px solid transparent',
									borderBottom: '1px solid #d2d2d2',
									alignItems: 'center',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<i className="icon icon-exc-my-account"></i>
								<Text.Bold
									variant="paragraph3"
									i18nKey={'LINK_MYACCOUNT'}
									sx={{
										paddingLeft: '10px',
										color: '#0f4291',
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								/>
								<i className="icon icon-exc-drop-down-menu-down hidden-xs"></i>
							</MenuItem>
							<MenuItem
								onClick={() => {
									showProgressPopup(
										MCCRedirectionUrls.Authenticated_Location_Finder.replace(
											'selectedProvince',
											selectedProvince?.toLowerCase()
										)
									)
									setPrimaryAnchorEl(null)
								}}
								sx={{
									borderRadius: '0px',
									borderTop: '1px solid transparent',
									borderBottom: '1px solid #d2d2d2',
									alignItems: 'center',
									padding: '10px 15px 10px 21px',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<LocationPin fillColor="#0f4291" />
								<Text.Bold
									variant="paragraph3"
									i18nKey={'PAGETITLE_LOCATIONFINDER'}
									sx={{
										paddingLeft: '15px',
										color: '#0f4291',
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								/>
							</MenuItem>
							<MenuItem
								onClick={() => {
									setOpenLogoutModal(true)
									setPrimaryAnchorEl(null)
								}}
								sx={{
									borderRadius: '0px',
									padding: '8px 15px 10px 18px',
									borderTop: '1px solid transparent',
									alignItems: 'center',
									'&:hover': {
										backgroundColor: '#eef2f9',
										border: '1px solid #696969',
									},
								}}
							>
								<i className="icon icon-exc-off"></i>
								<Text.Bold
									variant="paragraph3"
									i18nKey={'LINK_LOGOUT'}
									sx={{
										paddingLeft: '10px',
										color: '#0f4291',
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								/>
							</MenuItem>
						</Menu>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-around"
							justifyItems="end"
							gap={{
								md: '0px',
								lg: '20px',
								xs: '0px',
							}}
							display={{
								md: 'none',
								lg: 'flex',
								xs: 'none',
							}}
						>
							<Box
								sx={{ alignItems: 'center', cursor: 'pointer' }}
								onClick={(e) => {
									handleClick(e, 'secondary')
								}}
							>
								<i className="icon icon-exc-my-account"></i>
								<Text.Bold
									variant="paragraph3"
									i18nKey={'LINK_MYACCOUNT'}
									sx={{
										paddingLeft: '5px',
										color: '#0f4291',
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								/>
								<i className="icon icon-exc-drop-down-menu-down hidden-xs"></i>
							</Box>

							<Box
								sx={{
									alignItems: 'center',
									cursor: 'pointer',
								}}
								onClick={() =>
									showProgressPopup(
										MCCRedirectionUrls.Authenticated_Location_Finder.replace(
											'selectedProvince',
											selectedProvince?.toLowerCase()
										)
									)
								}
							>
								<Stack direction="row" alignItems="center">
									<LocationPin fillColor="#0f4291" />
								</Stack>
							</Box>

							<Box
								sx={{
									alignItems: 'center',
									cursor: 'pointer',
									marginRight: '10px',
								}}
								onClick={() => {
									setOpenLogoutModal(true)
								}}
							>
								<i
									className="icon icon-exc-off"
									style={{ display: 'inherit', fontSize: '18px' }}
								></i>
							</Box>
						</Stack>

						{isCreateAccount && (
							<div className="contact-info">
								<p className="text-right">
									<i
										className="icon icon-exc-call-support"
										aria-hidden="true"
									></i>

									<Text className="visible-xs" i18nKey={'NAV_SUPPORTCALL'} />
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<ConfirmDialog
				confirmDialogId="mcc-shopservices-modal"
				openDialog={isOpenDialog}
				onClickHandlerClose={() => onClickHandlerCloseDialog()}
				onclickHandlerSubmit={() => onClickHandleSubmit()}
			>
				<Stack>
					<Box>
						<Text i18nKey={'CONFIRM_REDIRECT_LINE1'} />
					</Box>
					<Box>
						<Text i18nKey={'CONFIRM_REDIRECT_LINE2'} />
						<a
							id="marketplaceLink"
							href={
								selectedProvince.toUpperCase() === 'BC'
									? MCCRedirectionUrls.MCC_BC_MARKETPLACE
									: MCCRedirectionUrls.MCC_ON_MARKETPLACE
							}
						>
							MarketPlace{' '}
						</a>

						<Text i18nKey={'CONFIRM_REDIRECT_LINE3'} />
					</Box>
				</Stack>
			</ConfirmDialog>
			<ConfirmDialog
				i18nKeyForHeader="BTN_SESSION_LOGOUT"
				confirmDiaglogHeaderIcon={<InformationIcon fillColor="#000" />}
				i18nKeyForBtns={['BTN_YES', 'BTN_CANCEL']}
				confirmDialogId="logout-modal-id"
				openDialog={openLogoutModal}
				onClickHandlerClose={() => setOpenLogoutModal(false)}
				onclickHandlerSubmit={() => logout()}
			>
				<Text
					variant="paragraph2"
					i18nKey="QUERY_SESSION_LOGOUT"
					sx={{
						fontWeight: {
							xs: '600',
							md: '400',
						},
					}}
				/>
			</ConfirmDialog>
		</>
	)
}

export default MCCHeader
