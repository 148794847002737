import { Button } from '@mui/material'
import Cross from 'assets/Cross.jsx'
import { testAttr } from 'utils/test/test.utils'
import theme from 'utils/theme'

const StyledButton = ({ children, ...props }) => {
	return <Button
		sx={{
			border: '1px solid',
			borderColor: theme.colors.blue[400],
			borderRadius: '50%',
			color: theme.colors.blue[400],
			background: theme.colors.white[400],
			minWidth: '37px',
			minHeight: '37px',
			padding: '10px'
		}}
		{...props}
	>
		{children}
	</Button>
}

const CircleButton = ({ circleButtonId, children, showCross = true, ...props }) => {

	return (
		<StyledButton
			{...testAttr(circleButtonId)}
			id={circleButtonId}
			{...props}
		>
			{
				showCross ?
					<Cross fillColor="#13499F" /> :
					children
			}
		</StyledButton>
	)
}

export default CircleButton

