import { createApi } from '@reduxjs/toolkit/query/react'
import {
    DEVICE_HISTORY_PATH,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'

export const deviceApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'device',
	endpoints: (build) => ({
		createDevice: build.query({
			query: (data) => ({
				url: `${DEVICE_HISTORY_PATH}`,
				method: 'POST',
				body: {
					...data,
				},
				transformErrorResponse
			}),
		}),
		updateDeviceHistory: build.mutation({
			query: (data) => ({
				url: `${DEVICE_HISTORY_PATH}`,
				method: 'PUT',
				body: {
					...data,
				},
			}),
		}),
	}),
})

export const {
    useLazyCreateDeviceQuery,
    useUpdateDeviceHistoryMutation
} = deviceApi
