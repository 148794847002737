import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	AppBar,
	Link,
	IconButton,
	MenuItem,
	Select,
	Grid,
	Button,
	TextField,
	Typography,
	Drawer,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	List,
	ListItem,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import MenuIcon from '@mui/icons-material/Menu'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'

import Text from 'components/Text'
import { footerLinkList } from 'features/footer/lifelabs/FooterLinksList'

import { updateSelectedProvince } from 'store/reducers/selectedProvinceReducer'

import SearchIcon from 'assets/Search-icon'
import MapleIcon from 'assets/header-assets/icon-maple.png'
import CalenderIcon from 'assets/header-assets/icon-calendar.png'
import CheckinIcon from 'assets/header-assets/icon-checkin.png'
import ResultsIcon from 'assets/header-assets/icon-results.png'
import ShopIcon from 'assets/header-assets/icon-shop1.png'
import { ReactComponent as ArrowDown } from 'assets/FilterIcons/DropDownArrow.svg'

import './lifelabs.header.scss'
import { LOCATION_FINDER } from 'utils/constants/routes-constants'

const provinceList = [
	{
		key: 'ON',
		title: 'Ontario',
	},
	{
		key: 'BC',
		title: 'British Columbia',
	},
	{
		key: 'SK',
		title: 'Saskatchewan',
	},
]
function LifelabsHeader({ isMobile }) {
	const dispatch = useDispatch()
	const [province, setProvince] = useState('ON')
	const selectedProvince = useSelector((state) => state.selectedProvince)

	const handleProvinceClick = (event) => {
		let selectedProvince = provinceList.filter(
			(state) => state.key === event.target.value
		)[0]?.key
		setProvince(selectedProvince)
		dispatch(updateSelectedProvince(selectedProvince))
	}

	useEffect(()=>{
      if(selectedProvince){
		const prov = provinceList.find((prov) => (prov.title.toLowerCase() === selectedProvince?.toLowerCase()) || (prov.key.toLowerCase() === selectedProvince?.toLowerCase()))
		if(prov){
			setProvince(prov.key)
		}
	  }
	},[selectedProvince])

	useEffect(()=>{
		const url = window.location.href;
		const urlObject = new URL(url);
		const provinceParams = urlObject.searchParams.get("province");
		if(provinceParams){
			dispatch(updateSelectedProvince(provinceParams))
		}
	},[])

	return (
		<>
			{isMobile ? (
				<>
					<HeaderTopMobile
						province={province}
						handleProvinceClick={handleProvinceClick}
					/>
					<HeaderBody province={province} />
				</>
			) : (
				<>
					<Headertop
						handleProvinceClick={handleProvinceClick}
						province={province}
					/>
					<HeaderBody province={province} />
					<HeaderFooter />
				</>
			)}
		</>
	)
}

const Headertop = ({ province, handleProvinceClick }) => {
	return (
		<AppBar position="static" className="header__top" data-testid="header-top">
			<Grid container alignItems="center" className="header__container">
				<Grid item xs={6}>
					<Grid container alignItems="center">
						<Stack direction="row" spacing={15} alignItems="center">
							<Select
								value={province}
								onChange={(event) => handleProvinceClick(event)}
								displayEmpty
								inputProps={{ 'aria-label': 'Select Province' }}
								className="selectProvince"
								classes={{
									icon: 'selectProvinceIcon',
									select: 'selectProvinceSelect',
								}}
								aria-label="Select Province"
								role="combobox"
								data-testid="select-province-web"
								aria-expanded={false}
								aria-controls="province-menu"
							>
								<MenuItem value="ON" className="province-name">
									Ontario
								</MenuItem>
								<MenuItem value="BC" className="province-name">
									British Columbia
								</MenuItem>
								<MenuItem value="SK" className="province-name">
									Saskatchewan
								</MenuItem>
							</Select>
							<Link
								variant="button"
								color="inherit"
								href="https://www.lifelabs.com/about-us/"
								className="linkContainer"
								underline="none"
							>
								<img src={MapleIcon} alt="Maple Icon" />
								<Text
									variant="paragrah3"
									className="linkText"
									i18nKey={'LBL_CANADIAN_COMPANY'}
								/>
							</Link>
						</Stack>
					</Grid>
				</Grid>
				<Grid item xs={5}>
					<Grid container justifyContent="flex-end" alignItems="center">
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							gap="40px"
						>
							<Link
								variant="button"
								color="inherit"
								href="https://www.lifelabs.com/careers/"
								className="navLink"
							>
								<Text variant="paragrah3" i18nKey={'LBL_CAREERS'} />
							</Link>
							<Link
								variant="button"
								color="inherit"
								href="https://www.lifelabs.com/contact-us/"
								className="navLink"
							>
								<Text variant="paragrah3" i18nKey={'LBL_CONTACT_US'} />
							</Link>
							<Link
								variant="button"
								color="inherit"
								href="https://www.lifelabs.com/patients/billing/"
								className="navLink"
							>
								<Text variant="paragrah3" i18nKey={'LBL_PAY_AN_INVOICE'} />
							</Link>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
		</AppBar>
	)
}

const HeaderBody = ({ province }) => {
	return (
		<Grid container sx={{ height: '70px' }} className="header-body">
			<Grid item xs={12} sm={12} md={2} lg={2} className="header-body-logo">
				<Link href="https://www.lifelabs.com/" title="LifeLabs">
					<img
						className="logoImage"
						src="https://lifelabs.azureedge.net/lifelabs-wp-cdn/wp-content/themes/lifelabs/assets/images/logo-new.png"
						alt="LifeLabs"
					/>
				</Link>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={10}
				lg={10}
				className="buttonContainer"
				gap={0.5}
				alignItems="center"
			>
				<Button
					variant="contained"
					color="primary"
					className="button"
					startIcon={<img alt="Book a Lab Visit" src={CalenderIcon} />}
					href={LOCATION_FINDER}
					sx={{
						width: {
							xs: '42vw',
							md: 'auto'
						}
					}}
				>
					<Text
						variant="paragraph3"
						color="#fff"
						i18nKey={'LINK_LAB_APPOINTMENT_BOOKINGS'}
						sx={{ fontSize: { xs: '0.8rem',  md: '0.875rem'}}}
					/>
				</Button>

				{province === 'ON' && (
					<Button
						variant="contained"
						color="primary"
						className="button"
						startIcon={<img alt="Book a House Call" src={CalenderIcon} />}
						href="https://myvisit.lifelabs.com/"
						sx={{
							width: {
								xs: '46vw',
								md: 'auto'
							}
						}}
					>
						<Text variant="paragraph3" color="#fff" i18nKey={'MYVISIT_GO_TO'} sx={{ fontSize: { xs: '0.8rem',  md: '0.875rem'}}}/>
					</Button>
				)}

				<Button
					variant="contained"
					color="primary"
					className="button"
					startIcon={<img alt="See my Results" src={ResultsIcon} />}
					href="https://www.lifelabs.com/see-my-results/"
					sx={{
						width: {
							xs: '42vw',
							md: 'auto'
						}
					}}
				>
					<Text variant="paragraph3" color="#fff" i18nKey={'SEE_MY_RESULTS'} sx={{ fontSize: { xs: '0.8rem',  md: '0.875rem'}}}/>
				</Button>
				<Button
					variant="contained"
					color="primary"
					className="button"
					startIcon={<img alt="Shop our Services" src={ShopIcon} width={16}/>}
					href="https://store.lifelabs.com/"
					sx={{
						width: {
							xs: '46vw',
							md: 'auto'
						}
					}}
				>
					<Text variant="paragraph3" color="#fff" i18nKey={'LINK_SHOPNOW'} sx={{ fontSize: { xs: '0.8rem',  md: '0.875rem'}}}/>
				</Button>
			</Grid>
		</Grid>
	)
}

const HeaderFooter = () => {
	return (
		<AppBar position="static" className="header__footer" color="default">
			<Grid container direction="row" alignItems="center">
				<Grid item xs={12} md={9} lg={8}>
					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="start"
						className="linkContainer"
					>
						<Typography variant="body1" className="footer__link">
							<Link
								href="https://www.lifelabs.com/patients/"
								color="#13499F"
								underline="none"
							>
								<Text
									variant="paragraph2"
									color="#13499F"
									i18nKey={'LBL_PATIENTS'}
								/>
							</Link>
						</Typography>
						<Typography variant="body1" className="footer__link">
							<Link
								href="https://www.lifelabs.com/healthcare-providers/"
								color="#13499F"
								underline="none"
							>
								<Text
									variant="paragraph2"
									color="#13499F"
									i18nKey={'LBL_HEALTHCARE_PROVIDERS'}
								/>
							</Link>
						</Typography>
						<Typography variant="body1" className="footer__link">
							<Link
								href="https://www.lifelabs.com/corporate-customers/"
								color="#13499F"
								underline="none"
							>
								<Text
									variant="paragraph2"
									color="#13499F"
									i18nKey={'LBL_CORPORATE_CUSTOMERS'}
								/>
							</Link>
						</Typography>
						<Typography variant="body1" className="footer__link">
							<Link
								href="https://www.lifelabs.com/tests-services/"
								color="#13499F"
								underline="none"
							>
								<Text
									variant="paragraph2"
									color="#13499F"
									i18nKey={'LBL_TESTS_AND_SERVICES'}
								/>
							</Link>
						</Typography>
					</Stack>
				</Grid>
				<Grid
					item
					alignItems="center"
					justifyContent="flex-end"
					className="searchForm"
				>
					<Grid item className="searchForm">
						<Grid item>
							<TextField
								id="search-desktop"
								name="search"
								placeholder="Search"
								value=""
								type="text"
								variant="standard"
								className="searchTextField"
							/>
							<IconButton aria-label="Search">
								<SearchIcon fillColor="red" />
							</IconButton>
						</Grid>
						<Grid>
							<IconButton aria-label="menu" className="popupCollapse">
								<MenuIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</AppBar>
	)
}

const HeaderTopMobile = ({ province, handleProvinceClick }) => {
	const [open, setOpen] = useState(false)
	return (
		<AppBar
			position="static"
			className="header__top"
			data-testid="header-top-mobile"
		>
			<Grid container alignItems="center" className="header__container__mobile">
				<Grid item className="header-section-maple">
					<Grid item>
						<IconButton
							aria-label="open drawer"
							data-testid="open drawer"
							onClick={() => setOpen(true)}
							edge="start"
						>
							<MenuIcon style={{ color: 'white' }} />
						</IconButton>
						<Drawer open={open} onClose={() => setOpen(false)}>
							<HeaderDrawer />
						</Drawer>
					</Grid>
					<Grid item className="header-mobile-section">
						<Link
							variant="button"
							color="inherit"
							href="https://www.lifelabs.com/about-us/"
							className="linkContainer"
							underline="none"
						>
							<img src={MapleIcon} alt="Maple Icon" />
						</Link>
						<Link
							variant="button"
							color="inherit"
							href="https://www.lifelabs.com/patients/billing/"
							className="navLink"
						>
							<Text i18nKey={'LBL_PAY_AN_INVOICE'} />
						</Link>
					</Grid>
				</Grid>
				<Grid item alignItems="center" className="header-mobile-state">
					<Select
						value={province}
						onChange={(event) => handleProvinceClick(event)}
						inputProps={{ 'aria-label': 'Select Province Mobile' }}
						className="selectProvince"
						classes={{
							icon: 'selectProvinceIcon',
							select: 'selectProvinceSelect',
						}}
						aria-label="Select Province Mobile"
						role="combobox"
					>
						{provinceList?.map((province, index) => {
							return (
								<MenuItem
									key={province.key}
									value={province.key}
									className="province-name"
								>
									{province.title}
								</MenuItem>
							)
						})}
					</Select>
				</Grid>
			</Grid>
		</AppBar>
	)
}

const HeaderDrawer = () => {
	const navigateLink = (link) => {
		window.location.href = link
	}
	return (
		<Grid container className="header-drawer">
			<Grid item className="header-social-links">
				<Link
					className="linkedin"
					href="https://www.linkedin.com/company/lifelabs-medical-laboratory-services"
					title="Linkedin"
					aria-label="Linkedin"
				>
					<LinkedInIcon />
				</Link>
				<Link
					className="twitter"
					href="https://twitter.com/intent/follow?source=followbutton&amp;variant=1.0&amp;screen_name=lifelabs"
					title="Twitter"
					aria-label="Twitter"
				>
					<XIcon />
				</Link>
				<Link
					className="facebook"
					href="https://www.facebook.com/LifeLabsLaboratory"
					title="Facebook"
					aria-label="Facebook"
				>
					<FacebookIcon />
				</Link>
				<Link
					className="youtube"
					href="https://www.youtube.com/channel/UC6uFwF_U0Yej4mVxHQMAy8g"
					title="Youtube"
					aria-label="Youtube"
				>
					<YouTubeIcon />
				</Link>
			</Grid>
			<Grid item className="searchForm">
				<Grid item className="searchForm">
					<TextField
						id="search-desktop"
						name="search"
						placeholder="Search"
						value=""
						type="text"
						variant="standard"
						color="error"
						focused
						className="searchTextField"
					/>
					<IconButton>
						<SearchIcon fillColor="red" />
					</IconButton>
				</Grid>
			</Grid>
			<Grid item>
				<IconButton aria-label="menu" className="popupCollapse">
					<MenuIcon />
					<Typography variant="body1" className="menu-button">
						MENU
					</Typography>
				</IconButton>
			</Grid>
			<Grid item>
				{footerLinkList.map((section, index) => (
					<Accordion key={index} disableGutters defaultExpanded={true}>
						<AccordionSummary
							expandIcon={<ArrowDown />}
							aria-controls={`panel${index}-content`}
							id={`panel${index}-header`}
							className="header-links-title"
						>
							<Box onClick={() => navigateLink(section.link)} underline="none">
								{section.title}
							</Box>
						</AccordionSummary>
						<AccordionDetails className="header-links-services-accordian">
							<List className="header-links-services-list">
								{section.menuItem.map((item, idx) => (
									<ListItem key={idx} className="header-links-services">
										<Box
											onClick={() => navigateLink(item.link)}
											underline="none"
											className="header-links-service"
										>
											{item.title}
										</Box>
									</ListItem>
								))}
							</List>
						</AccordionDetails>
					</Accordion>
				))}
			</Grid>
		</Grid>
	)
}
export default LifelabsHeader
