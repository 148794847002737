import DatePicker from 'components/input/DatePicker'
import PickerWithButtonField from 'components/input/CustomDatePicker'

const DateFilter = ({
	selectedDate = '',
	handleFilterChange,
	isDropDownOpen,
	...props }) => {
	return (
		<PickerWithButtonField
			selectedDate={selectedDate}
			handleFilterChange={handleFilterChange}
		/>
	)
}

const CustomDatePicker = ({
	selectedDate,
	handleFilterChange
}) => {
	return (
		<DatePicker
			selectedDate={selectedDate}
			onChange={(date) => handleFilterChange('date', date)}
			from={'filters'}
		/>
	)
}

DateFilter.CustomDatePicker = CustomDatePicker;

export default DateFilter
