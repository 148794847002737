import { useEffect, useState } from 'react'

import { Grid, Paper, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'

import IconGrid from 'components/data-display/IconGrid/IconGrid'

import { checkIfWeekOut, getFormattedDateRange } from 'utils/dateTime.utils'
import { LOCATION_STATUS, getIcons } from 'utils/constants/location-constants'
import { testAttr } from 'utils/test/test.utils'

import './location.scss'

export const AvailabilityDetailsAccordion = ({ stat = {}, index = 1 }) => {
	const [expandHolidayDetails, setExpandHolidayDetails] = useState(true)

	const onShowDetails = () => {
		setExpandHolidayDetails(!expandHolidayDetails)
	}

	const type = stat.type || {}
	const color = LOCATION_STATUS[type]?.COLOR || 'paper.main'

	const [statText,setStatText] = useState("");

	useEffect(()=>{
		if(stat && (stat.closureName || stat.name || stat.closureHeader)){
			let statText = stat.closureName || stat.name || stat.closureHeader || '';
			if(statText.includes('div')){
				let tempElement = document.createElement('div');
				tempElement.innerHTML = statText;
				let ckContentDiv = tempElement.querySelector('.ck-content') || tempElement.querySelector('div div div')
				if(ckContentDiv){
					let textContent = ckContentDiv?.textContent?.trim();
					setStatText(textContent)
	
				}
			}
		}
	},[stat])

	return (
		<Stack
			sx={{
				width: '100%',
				border: expandHolidayDetails ? '' : '1px solid #b8520a',
				marginBottom: expandHolidayDetails ? '' : '1rem',
			}}
		>
			<IconGrid
				className="pl05"
				muiIcon={getIcons(
					LOCATION_STATUS[type]?.ICON_CODE,
					LOCATION_STATUS[type]?.ICON_COLOR
				)}
				sx={{
					backgroundColor: LOCATION_STATUS[type]?.BG_COLOR,
					marginBottom: '0.5rem',
					paddingLeft: '0.5rem',
				}}
			>
				<Grid
					container
					alignItems="center"
					color={color}
					justifyContent="space-between"
				>
					<Grid item>
						<Typography
							variant="body2"
							sx={{
								color,
								fontWeight: 'bold',
								margin: '0 0.5rem',
								display: 'inline',
							}}
						>
							{ statText.length > 0 ? statText : (stat.closureName || stat.name || stat.closureHeader || '')}
						</Typography>
						<Typography variant="caption" sx={{ color }}>
							{getFormattedDateRange(stat)}
						</Typography>
					</Grid>
					<Grid data-testid={'add-icon-' + index}>
						{expandHolidayDetails ? (
							<AddIcon
								onClick={onShowDetails}
								sx={{ color }}
								{...testAttr('add-btn-' + index)}
							/>
						) : (
							<HorizontalRuleIcon
								onClick={onShowDetails}
								sx={{ color }}
								{...testAttr('collapse-btn-' + index)}
							/>
						)}
					</Grid>
				</Grid>
			</IconGrid>
			{expandHolidayDetails === false && (
				<Paper elevation={0} sx={{ padding: '0.5rem 1.2rem' }}>
					<div
						dangerouslySetInnerHTML={{
							__html: stat.closureInformation ||
								stat.noticeInformation ||
								stat.description ||
								'',
						}}
					></div>
				</Paper>
			)}
		</Stack>
	)
}

const Availability = ({ location = {}, ...props }) => {
	const filteredStatClousre = location?.statClosure?.filter((stat) =>
		checkIfWeekOut(stat.closureStartDate,location?.address?.timeZone)
	)

	return (
		<Grid
			container
			sx={{ width: '100%' }}
			alignContent="center"
			alignItems="center"
			{...props}
			data-testid="availability"
		>
			{filteredStatClousre?.map((stat, index) => {
				return (
					<AvailabilityDetailsAccordion
						stat={{ ...stat, type: 'CIVIC_HOLIDAY' }}
						key={index}
						index={index}
					/>
				)
			})}
			{location?.tempClosure?.map((stat, index) => {
				return (
					<AvailabilityDetailsAccordion
						stat={{ ...stat, type: 'CIVIC_HOLIDAY' }}
						key={index}
					/>
				)
			})}
			{location?.serviceDisruption?.map((stat, index) => {
				return (
					<AvailabilityDetailsAccordion
						stat={{ ...stat, type: 'SD' }}
						key={index}
					/>
				)
			})}
		</Grid>
	)
}

export default Availability
