import html2pdf from "html2pdf.js"
import moment from "moment/moment"
import { useSelector } from "react-redux"
import { getProvinceInitials } from "utils/common.utils"
import { CONFIRMATION_EMAIL, PRINT_APPOINTMENT_PDF } from "utils/constants/string.constants"
import { calculateEndTime, formatDateTimein12HourWithTimezone } from "utils/dateTime.utils"

/* istanbul ignore next */
export const useAppointmentActions = (user, appointmentDetails) => {

    const duration = useSelector(state => state.appointment.appointment.duration)
    const appointmentDate = useSelector(state => state.appointment.appointment.appointmentDate)
    const appointmentTime = useSelector(state => state.appointment.appointment.startTime)
    const selectedLocation = useSelector(state => state.appointment.appointmentLocation)
    const appointment = useSelector((state) => state.appointment.appointment)
    const generalTests = useSelector(state => state?.config?.generalTest)
    const customerDetails = useSelector(state => state?.customerDetails);

    const getUserName = (
        appointmentDetails,
        user
    ) => {
        if (appointmentDetails?.contact) {
            return `${appointmentDetails.contact.firstName} ${appointmentDetails.contact.lastName}`;
        }
        return `${user?.selectedAdhocUser?.firstName || user?.firstName} ${user?.selectedAdhocUser?.lastName || user?.lastName}`;
    };

    const getLocation = (
        appointmentDetails
    ) => {
        const address = appointmentDetails?.locations || selectedLocation?.address;
        if (!address) return '';

        let location = `${address.address1}, `;
        if (address.address2) {
            location += `${address.address2}, `;
        }
        location += `${address.city}, ${getProvinceInitials(address.province)}`;
        return location;
    };

    const getAppointmentTest = (
        appointmentDetails
    ) => {
        if (appointmentDetails && !appointmentDetails?.startTimeBasedOnTimezone) {
            return appointmentDetails?.specialtyTest?.name || appointmentDetails?.toleranceTest?.name || generalTests?.name || "";
        }
        return appointment?.appointmentType?.includes("General")
            ? appointment?.appointmentType?.split(" ")[0] || ""
            : appointment?.appointmentType || "";
    };

    const getAppointmentTestAndDuration = () => {
        if (appointmentDetails && !appointmentDetails?.startTimeBasedOnTimezone) {
            if (appointmentDetails?.specialtyTest?.name) {
                return { name: appointmentDetails.specialtyTest.name, duration: appointmentDetails.specialtyTest.standardDurationMinuits };
            }
            if (appointmentDetails?.toleranceTest?.name) {
                return { name: appointmentDetails.toleranceTest.name, duration: appointmentDetails.toleranceTest.totalDurationMinutes };
            }
            return { name: generalTests?.name || "", duration: generalTests?.duration };
        }
        return { name: appointment?.appointmentType?.includes("General") ? appointment?.appointmentType?.split(" ")[0] : appointment?.appointmentType || "", duration: appointment?.duration };
    };

    const getRecipientEmail = (userName) => {
        const firstName = userName.split(" ")[0];
        if (customerDetails?.firstName === firstName) {
            return customerDetails?.email;
        }
        return customerDetails?.relatedContacts?.find(contact => contact?.firstName === firstName)?.email || '';
    };

    const getRecipientCustomerId = (userName) => {
        const firstName = userName.split(" ")[0];
        if (customerDetails?.firstName === firstName) {
            return customerDetails?.id;
        }
        return customerDetails?.relatedContacts?.find(contact => contact?.firstName === firstName)?.id || '';
    };

    const appointmentConfirmationPDF = () => {
        const userName = getUserName(appointmentDetails, user);
        const location = getLocation(appointmentDetails);
        const appointmentTest = getAppointmentTest(appointmentDetails);
        const apptDuration = (appointmentDetails?.durationMinutes || duration)?.split("min")[0];
        const apptStartTime = appointmentDetails
            ? formatDateTimein12HourWithTimezone(appointmentDetails?.startTime, appointmentDetails?.timeZone)?.split(" ")
            : appointmentTime?.split(" ");
        const recipientEmail = getRecipientEmail(userName);
        const formattedDate = moment(appointmentDetails?.startTime || appointmentDate).format('dddd, MMMM Do, YYYY') +
            " at " + apptStartTime[0] + apptStartTime[1]?.toLowerCase();

        const bookingConfirmationNumber = appointmentDetails?.bookingConfirmationNumber || appointment?.confirmationId;

        return PRINT_APPOINTMENT_PDF.PRINT_HEADER
            .replace("@{variables('Display Name')}", userName)
            .replace("@{outputs('Compose_Display_Date')}", formattedDate)
            .replace("@{variables('Display Address')}", location)
            .replace("@{variables('Test Name')}", appointmentTest)
            .replace("@{variables('Test Duration')}", apptDuration)
            .replace("@{triggerOutputs()?['body/csap_bookingconfirmationnumber']}", bookingConfirmationNumber)
            .replace("@{variables('Recipient Email')}", recipientEmail)
            .replace("@{variables('Recipient Email')}", recipientEmail)
    }

    const generatePdfBase64 = async (htmlContent) => {
        const pdfBase64 = await html2pdf()
            .from(htmlContent)
            .set({
                margin: 10
            })
            .toPdf()
            .outputPdf('datauristring');

        const base64String = pdfBase64.split(',')[1];
        return base64String;
    };

    const appointmentConfirmationICS = (encoding = true) => {

        const userName = getUserName(appointmentDetails, user);
        const location = getLocation(appointmentDetails);
        const { name: appointmentTest, duration: testDuration } = getAppointmentTestAndDuration(appointmentDetails);

        let apptStartTime;
        let apptEndTime;

        if (appointmentDetails && !appointmentDetails?.startTimeBasedOnTimezone) {
            if (appointmentDetails?.specialtyTest?.name && (appointmentDetails?.specialtyTest?.name !== "" || appointmentDetails?.specialtyTest?.name !== null)) {
                apptEndTime = calculateEndTime(appointmentDetails?.startTime, appointmentDetails?.specialtyTest?.standardDurationMinuits);
            } else if (appointmentDetails?.toleranceTest?.name && (appointmentDetails?.toleranceTest?.name !== "" || appointmentDetails?.toleranceTest?.name !== null)) {
                apptEndTime = calculateEndTime(appointmentDetails?.startTime, appointmentDetails?.toleranceTest?.totalDurationMinutes);
            } else {
                apptEndTime = calculateEndTime(appointmentDetails?.startTime, appointmentDetails?.duration)
            }
            apptStartTime = appointmentDetails?.startTime
        } else {
            apptStartTime = appointment?.startTimeBasedOnTimezone;
            apptEndTime = appointment?.endTimeBasedOnTimezone
        }

        const plainDescription = `This is a calendar placeholder for your ${appointmentTest} appointment at LifeLabs. Some tests require special preparation, please review these instructions to prepare for the appointment: https://www.lifelabs.com/patients/preparing-for-a-test/patient-test-instructions/`;
        const htmlDescription = `
            <html>
            <body>
                <p>This is a calendar placeholder for your ${appointmentTest} appointment at LifeLabs. Some tests require special preparation, please <a href="https://www.lifelabs.com/patients/preparing-for-a-test/patient-test-instructions/">review these instructions to prepare for the appointment.</a></p>
            </body>
            </html>
        `;

        return [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "DTSTART:" + apptStartTime,
            "DTEND:" + apptEndTime,
            "SUMMARY:" + 'LifeLabs Appointment for ' + userName,
            `DESCRIPTION:${plainDescription}`,
            `X-ALT-DESC;FMTTYPE=text/html:${htmlDescription.replace(/\n/g, "\\n").replace(/,/g, "\\,").replace(/;/g, "\\;")}`,
            "LOCATION:" + (encoding ? encodeURIComponent(location.replace(/#/g, '%23')) : location),
            "BEGIN:VALARM",
            "TRIGGER:-PT15M",
            "REPEAT:1",
            "DURATION:PT15M",
            "ACTION:DISPLAY",
            "DESCRIPTION:Reminder",
            "END:VALARM",
            "END:VEVENT",
            "END:VCALENDAR"
        ].join("\n");
    }

    const appointmentConfirmationEmail = async () => {

        const userName = getUserName(appointmentDetails, user);
        const location = getLocation(appointmentDetails);
        const appointmentTest = getAppointmentTest(appointmentDetails);
        const apptDuration = (appointmentDetails?.durationMinutes || duration)?.split("min")[0];

        const apptStartTime = appointmentDetails
            ? formatDateTimein12HourWithTimezone(appointmentDetails?.startTime, appointmentDetails?.timeZone)?.split(" ")
            : appointmentTime?.split(" ");

        const recipientEmail = getRecipientEmail(userName);

        const formattedDate = moment(appointmentDetails?.startTime).format('dddd, MMMM Do, YYYY') +
            " at " + apptStartTime[0] + apptStartTime[1]?.toLowerCase();

        const bookingConfirmationNumber = appointmentDetails?.bookingConfirmationNumber || appointment?.confirmationId;
        const activityId = appointmentDetails?.activityId || appointment?.activityId;
        const customerId = getRecipientCustomerId(userName);
        const mapAddress = appointmentDetails?.mapAddress || appointment?.mapAddress || ''

        const apptEmailBody = CONFIRMATION_EMAIL
            .replace("@{variables('Display Name')}", userName)
            .replace("@{outputs('Compose_Display_Date')}", formattedDate)
            .replace("@{variables('Display Address')}", location)
            .replace("@{variables('Test Name')}", appointmentTest)
            .replace("@{variables('Test Duration')}", apptDuration)
            .replace("@{triggerOutputs()?['body/csap_bookingconfirmationnumber']}", bookingConfirmationNumber)
            .replace("@{variables('Recipient Email')}", recipientEmail)
            .replace("@{variables('Recipient Email')}", recipientEmail)
            .replace("@{outputs('Get_Location_by_ID')?['body/csap_mapaddress']}", mapAddress)
            .replace("@{parameters('ReactPortal URL (csap_ReactPortalURL)')}", process.env.REACT_APP_BASE_URL_PROD)
            .replace("@{triggerOutputs()?['body/activityid']}", activityId)
            .replace("@{triggerBody()?['customerId']}", customerId);

        const htmlContent = appointmentConfirmationPDF();
        const encodedPDF = await generatePdfBase64(htmlContent);

        const icsContent = appointmentConfirmationICS(false);
        const encodedICS = window.btoa(unescape(encodeURIComponent(icsContent)));

        return `To: ${userName} ${recipientEmail}
Subject: LifeLabs - Appointment Confirmation
X-Unsent: 1
Content-Type: multipart/mixed; boundary=--boundary_text_string

----boundary_text_string
Content-Type: text/html; charset=UTF-8

<html>
  <style>
    .appt-title {
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 1.1428571428571428rem;
      line-height: 1.5;
      font-weight: 500;
      margin-top: 32px;
      font-size: 1.3rem;
    }
  </style>
  <body>
    ${apptEmailBody}
  </body>
</html>

----boundary_text_string
Content-Type: application/pdf; name=LifeLabs - Appointment Confirmation.pdf
Content-Transfer-Encoding: base64
Content-Disposition: attachment

${encodedPDF}

----boundary_text_string
Content-Type: text/calendar; name=InsertAppointmentIntoCalendar.ics
Content-Transfer-Encoding: base64
Content-Disposition: attachment

${encodedICS}

----boundary_text_string--
`;

    }

    return {
        appointmentConfirmationPDF,
        appointmentConfirmationICS,
        appointmentConfirmationEmail
    }
}

export default useAppointmentActions