import { createApi } from '@reduxjs/toolkit/query/react'
import {
	EXTEND_RESERVATION_PATH,
	RESERVATIONS_PATH,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'

export const extendSessionApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'extendSession',
	endpoints: (build) => ({
		postExtendSession: build.query({
			query: (reservationId) => ({
				url: `${RESERVATIONS_PATH}${EXTEND_RESERVATION_PATH}/${reservationId}`,
				method: 'PUT',
			}),
			transformErrorResponse
		}),
	}),
})

export const { useLazyPostExtendSessionQuery } = extendSessionApi
export const resetExtendSessionApi = extendSessionApi.util.resetApiState
