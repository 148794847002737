import React from "react";
import Text from 'components/Text'
 
const InternalServerErrorPage = () => {
  return (
    <div>
      <Text variant="h1" i18nKey="INTERNAL_SERVER_ERROR"/>
      <Text variant="paragraph1" i18nKey="INTERNAL_SERVER_ERROR_TEXT"/>
      <Text variant="paragraph1" i18nKey="REFRESH_PAGE"/>
    </div>
  );
};

export default InternalServerErrorPage;