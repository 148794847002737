import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    CONNECT_TOKEN_PATH,
    TOKEN_URL
} from './url'
import { saveTokenType, setAuthToken } from 'utils/common.utils'
import { Password, client_id, client_secret, grant_type, scope, username } from 'utils/constants/routes-constants'
import { TOKEN_TYPE } from 'utils/constants/common.constants'

export const authToken = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: TOKEN_URL,
    }),
    reducerPath: 'authToken',
    endpoints: (build) => ({
        getAuthToken: build.query({
            query: () => ({
                url: CONNECT_TOKEN_PATH,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    grant_type,
                    scope,
                    client_id,
                    client_secret,
                    username,
                    Password
                }).toString(),
            }),
            transformResponse: (data) => {
                saveTokenType(TOKEN_TYPE.ID4)
                setAuthToken(data?.access_token, data?.expires_in)
                return data;
            }
        })
    })
})

export const { useLazyGetAuthTokenQuery } = authToken