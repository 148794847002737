import { ReactComponent as Clock } from "assets/Clock.svg";
import { Tabs, Tab, Box } from "@mui/material";
import TrashIcon from "assets/TrashIcon";
import { useState } from "react";
import { Strings } from "utils/Strings";
import ApptsCardBlock from "./ApptsCardBlock";
import { testAttr } from "utils/test/test.utils";

const ApptsTab = ({ previousAppointments, cancelledAppointments, ...props }) => {
    const [currentTab, setCurrentTab] = useState(0)

    const handleChangeTab = (event, tab) => {
        setCurrentTab(tab)
    }

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                    marginBottom: '1rem',
                    '& .MuiTab-root': {
                        padding: 0,
                        paddingBottom: 1,
                        marginRight: 2,
                        minHeight: '1rem',
                        fontSize: '1.1rem',
                    },
                    '& .MuiTab-root:not(.Mui-selected)': {
                        color: '#000',
                    },
                    minHeight: '1rem',
                    height: 'fit-content',
                   
                }}
                role="tabpanel"
                aria-label='appointments-tab'
                title='appointments-tab'
            >
                <Tab
                    role="tab"
                    aria-label={Strings.PREVIOUS_THREE_MONTHS}
                    icon={<Clock
                        fill={currentTab === 0 ? "#13499F" : 'black'}
                        stroke={currentTab === 0 ? "#13499F" : 'black'} />}
                    iconPosition="start"
                    label={Strings.PREVIOUS_THREE_MONTHS}
                    {...testAttr('previous-three-months-appts-tab')}
                />
                <Tab
                    {...testAttr('cancelled-appts-tab')}
                    role="tab"
                    icon={<TrashIcon fillColor={currentTab === 1 ? "#13499F" : 'black'} />}
                    iconPosition="start"
                    aria-label={Strings.CANCELLED}
                    label={
                        <Box
                            sx={{ marginLeft: '0.5rem' }}>
                            {Strings.CANCELLED}
                        </Box>
                    } />
            </Tabs>
            <Box>
                {currentTab === 0 &&
                    <ApptsCardBlock
                        type='PAST'
                        appointments={previousAppointments}
                    />
                }
                {currentTab === 1 &&
                    <ApptsCardBlock
                        type='CANCELLED'
                        appointments={cancelledAppointments}
                    />
                }
            </Box>
        </>
    )
}

export default ApptsTab;
