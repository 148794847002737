import { useState } from 'react'
import { Box, Grid, Radio, Typography } from '@mui/material'
import SortDropdown from 'components/SortDropdown'
import SortIcon from 'assets/Sort'
import { STRINGS } from 'utils/constants/string.constants'
import Toast from 'components/feedback/toast/Toast'
import useMobile from 'hooks/useMobile'

const SearchResultHeader = ({
	locationsCount = 0,
	selectedSort = 'CLOSEST',
	setSelectedSort,
}) => {
	const [radioSelected, setRadioSelected] = useState(false)
	const isMobile = useMobile()

	return (
		<Box sx={{ padding: '1rem 0px' }}>
			<Grid container justifyContent="space-between">
				<Grid item xs={9}>
					<Box
						sx={{
							paddingLeft: {
								xs: '16px',
								md: 'inherit',
							},
							paddingBottom: {
								xs: '0px',
								md: 'inherit',
							},
						}}
					>
						<Typography
							variant="paragraph1Mobile"
							sx={{
								fontSize: {
									xs: '20px',
									md: '1.14286rem',
								},
								lineHeight: {
									xs: '30px',
									md: '1.75rem',
								},
								fontWeight: {
									xs: 'bold',
									md: '400',
								},
							}}
						>
							{STRINGS.SEARCH_RESULTS} ({locationsCount})
						</Typography>
					</Box>
				</Grid>
				{!isMobile && (
					<Grid item xs={2}>
						<SortDropdown
							dropdownId="sortDropdown"
							dropdownLabel={STRINGS.SORT}
							dropdownItems={STRINGS.SORT_OPTIONS}
							dropdownIcon={<SortIcon fillColor="#13499F" />}
							selectedOption={selectedSort}
							dropdownControl={
								<Radio
									onChange={(e) => {
										setSelectedSort(e.target.value)
										setRadioSelected(true)
									}}
									sx={{
										color: 'primary.dark',
									}}
								/>
							}
							setSelectedSort={setSelectedSort}
							setRadioSelected={setRadioSelected}
						/>
					</Grid>
				)}
			</Grid>
			{radioSelected && (
				<Toast
					open={true}
					alertCode={500}
					handleClose={() => setRadioSelected(false)}
					autoHideDuration={6000}
				/>
			)}
		</Box>
	)
}

export default SearchResultHeader
