function Cross({ fillColor }) {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line
				x1="2.95215"
				y1="13.912"
				x2="13.9123"
				y2="2.95184"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<line
				x1="3.088"
				y1="2.95215"
				x2="14.0482"
				y2="13.9123"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default Cross
