import { createApi } from '@reduxjs/toolkit/query/react'
import { RESERVE_PATH, RESERVATIONS_PATH } from './url'

import { baseQueryWithReauth } from './service-utils'
import { generateGUID } from 'utils/uuidGenerator'

export const reservationApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'reservations',
	endpoints: (build) => ({
		deleteReservation: build.mutation({
			query: (reservationId) => ({
				url: `${RESERVE_PATH}/${reservationId}`,
				method: 'DELETE',
			}),
		}),
		postBookSession: build.query({
			query: (reservationId) => ({
				url: `${RESERVATIONS_PATH}/${reservationId}`,
				method: 'POST',
				body: {
					ReservationCode: generateGUID(),
					SlotId: reservationId,
				},
			}),
		}),
	}),
})

export const { useDeleteReservationMutation, useLazyPostBookSessionQuery } =
	reservationApi

export const resetBookSessionApi = reservationApi.util.resetApiState
