import { Box, Grid } from '@mui/material'
import PastAppointmentCard from './PastAppointmentCard'
import NoRecords from './NoRecords'
import { Strings } from 'utils/Strings'
import AppointmentDetails from './AppointmentDetails'
import { useEffect, useState } from 'react'
import { useLazyPostLocationBySiteIdQuery } from 'store/services/locationSiteIdApi'

const ApptsCardBlock = ({ appointments = [], type = 'PAST', ...props }) => {
	const [showApptDetails, setShowApptDetails] = useState(false)
	const [selectedAppointment, setSelectedAppointment] = useState()
	const [postLocationBySiteId, { data }] = useLazyPostLocationBySiteIdQuery('');

	const handleClickApptDetails = (appointment) => {
		setShowApptDetails(true)
		setSelectedAppointment(appointment)
	}

	useEffect(() => {
		if (showApptDetails && selectedAppointment?.locations?.siteIdGuid) {
			postLocationBySiteId({
				site_id: [selectedAppointment.locations.siteIdGuid]
			})
		}
	}, [postLocationBySiteId, showApptDetails, selectedAppointment])

	return (
		<Box {...props}>
			{appointments.length > 0 ? (
				<Grid container sx={{ width: '100%' }}>
					{appointments?.map((pastAppointment, index) => (
						<PastAppointmentCard
							pastAppointment={pastAppointment}
							key={index}
							index={index}
							handleClickApptDetails={() =>
								handleClickApptDetails(pastAppointment)
							}
						/>
					))}
				</Grid>
			) : (
				<NoRecords
					noRecordsMessage={
						type === 'PAST'
							? Strings.NO_RECORDS_PAST_APPOINTMENT
							: Strings.NO_RECORDS_CANCELLED_APPOINTMENT
					}
				/>
			)}

			<AppointmentDetails
				id="appointment-dialog"
				fullWidth
				showApptDetails={showApptDetails}
			>
				<AppointmentDetails.Title
					appointment={selectedAppointment}
					onClose={() => setShowApptDetails(false)}
					type={type}
				/>
				<AppointmentDetails.Content appointment={selectedAppointment} />
				<AppointmentDetails.Location appointment={selectedAppointment} location={data}/>
			</AppointmentDetails>
		</Box>
	)
}

export default ApptsCardBlock
