import RoundedButton from 'components/buttons/RoundedButton'
import Text from 'components/Text'
import TrashIcon from 'assets/TrashIcon'
import ConfirmDialog from 'components/ConfirmDialog';
import { Box } from '@mui/material';
import { useState } from 'react';
import { testAttr } from 'utils/test/test.utils';

const CancelAppointmentButton = ({
    handleCancelAppointment,
    handleClick,
    addDialog = true
}) => {

    const [openDialog, setOpenDialog] = useState(false)

    const onClickHandlerClose = () => {
        setOpenDialog(false)
    }

    return (
        <>
            <RoundedButton
                aria-label='Cancel Appointemnt'
                title='Cancel Appointment'
                onClick={() => {
                    setOpenDialog(true)
                    handleClick && handleClick();
                }}  
                sx={{
                    marginTop: 2,
                    backgroundColor: '#D90D2B',
                    color: 'text.light',
                    fontSize: '0.8725rem',
                    width: {
                        xs: "15.625rem",
                        md: "15.625rem"
                    }
                }}
                i18nKey='LBL_CANCEL_APPOINTMENT'
                {...testAttr('cancel-appt-btn')}
            >
                <Box ml={1}>
                    <TrashIcon fillColor="#fff" />
                </Box>
            </RoundedButton>
            {addDialog &&
                <ConfirmDialog
                    confirmDialogId="cancel-appt-modal"
                    openDialog={openDialog}
                    i18nKeyForHeader='LBL_CANCEL_APPOINTMENT'
                    confirmDiaglogHeaderIcon={<TrashIcon fillColor="#000" />}
                    onClickHandlerClose={onClickHandlerClose}
                    onclickHandlerSubmit={() => {
                        setOpenDialog(false)
                        handleCancelAppointment()
                    }}
                >
                    <Text
                        variant='paragraph2'
                        i18nKey='QUERY_APPOINTMENT_BODY' />
                </ConfirmDialog>}
        </>
    );
}

export default CancelAppointmentButton;
