function Home({ fillColor }) {
	return (
		<svg
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.74505 1.70423C8.89865 1.43192 7.98816 1.43192 7.14176 1.70423C6.43044 1.93309 5.7556 2.45759 4.1059 3.77735L3.94867 3.90313C3.08896 4.5909 2.74422 4.87135 2.47831 5.19206C2.03444 5.72741 1.72872 6.3635 1.58797 7.04453C1.50365 7.45252 1.50001 7.8969 1.50001 8.99788V12.4256C1.50001 14.1359 2.88645 15.5223 4.59671 15.5223C5.03228 15.5223 5.38539 15.1692 5.38539 14.7337V11.6563C5.38539 9.96736 6.75451 8.59824 8.44341 8.59824C10.1323 8.59824 11.5014 9.96736 11.5014 11.6563V14.7337C11.5014 15.1692 11.8545 15.5223 12.2901 15.5223C14.0004 15.5223 15.3868 14.1359 15.3868 12.4256V8.99788C15.3868 7.8969 15.3832 7.45252 15.2988 7.04453C15.1581 6.3635 14.8524 5.72741 14.4085 5.19206C14.1426 4.87135 13.7979 4.5909 12.9381 3.90313L12.7809 3.77735C11.1312 2.45759 10.4564 1.93309 9.74505 1.70423ZM6.68236 0.276318C7.82749 -0.0921058 9.05933 -0.0921058 10.2045 0.276318C11.2118 0.600422 12.1129 1.32158 13.5824 2.49755C13.627 2.53329 13.6722 2.56946 13.718 2.60605L13.8752 2.73183C13.8981 2.7502 13.9209 2.76837 13.9433 2.78633C14.7137 3.40247 15.1874 3.7814 15.5632 4.23465C16.1637 4.95895 16.5774 5.81954 16.7678 6.74094C16.887 7.31754 16.8869 7.9242 16.8868 8.91065C16.8868 8.9394 16.8868 8.96847 16.8868 8.99788V12.4256C16.8868 14.9643 14.8288 17.0223 12.2901 17.0223C11.0261 17.0223 10.0014 15.9977 10.0014 14.7337V11.6563C10.0014 10.7958 9.30388 10.0982 8.44341 10.0982C7.58294 10.0982 6.88539 10.7958 6.88539 11.6563V14.7337C6.88539 15.9977 5.86071 17.0223 4.59671 17.0223C2.05802 17.0223 1.13994e-05 14.9643 1.13994e-05 12.4256V8.99788C1.13994e-05 8.96847 8.47874e-06 8.9394 5.61772e-06 8.91065C-9.34452e-05 7.9242 -0.000154361 7.31754 0.119016 6.74094C0.309444 5.81954 0.723066 4.95895 1.3236 4.23465C1.69941 3.7814 2.17316 3.40247 2.9435 2.78632C2.96596 2.76836 2.98867 2.7502 3.01163 2.73183L3.16886 2.60604C3.21459 2.56946 3.25978 2.5333 3.30444 2.49755C4.77387 1.32158 5.67498 0.600422 6.68236 0.276318Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export default Home
