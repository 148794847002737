import { colors } from './colors'

const { white, black, blue, red, orange, brown, grey, green } = colors

const palette = {
	primary: {
		main: blue[400],
		dark: blue[400],
		light: blue[200],
		contrastText: white[400],
	},
	secondary: {
		main: white[400],
		dark: white[400],
		light: white[400],
		contrastText: blue[400],
	},
	error: {
		main: red[400],
		dark: red[200],
		light: red[400],
		contrastText: red[500],
	},
	warning: {
		main: orange[400],
		dark: orange[200],
		light: orange[200],
		contrastText: orange[500],
	},
	update: {
		main: orange[200],
		dark: orange[200],
		light: orange[200],
		contrastText: orange[500],
	},
	info: {
		main: blue[300],
		dark: blue[200],
		light: blue[400],
		bg: blue[350],
		contrastText: blue[300],
		border: blue[500]
	},
	success: {
		main: green[300],
		dark: green[100],
		light: green[200],
		bg: green[350],
		contrastText: green[400],
	},
	paper: {
		main: brown[400],
	},
	text: {
		dark: black[400],
		light: white[400],
	},
	background: {
		dark: grey[400],
		light: grey[500]
	},
	action: {
		hover: blue[600],
		focus: blue[400],
		disabled: grey[300],
	},
	...colors,
}

export { palette }
