import { ThemeProvider } from '@mui/material/styles'
import { RouterProvider, useLocation } from 'react-router-dom'
import router from 'Router'
import 'locales'
import theme from 'utils/theme'
import './App.scss'
import 'assets/styles/common.scss'
import { PublicClientApplication } from '@azure/msal-browser'
import { config } from './authConfig.js'
import { decodeJwtToken, getSource, inIframe, removeAuthToken, removeItemFromBrowserStorage, removeTokenType, saveItem, saveTokenType, setAuthToken } from 'utils/common.utils'
import { TOKEN_TYPE } from 'utils/constants/common.constants'
import { useEffect, useState } from 'react'
import { useAuthorizeStaffMutation } from 'store/services/authorizeStaffApi'
import { Backdrop, Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { APPOINTMENTS, HOME, LOCATION_FINDER, MCC_DASHBOARD } from 'utils/constants/routes-constants'

/* istanbul ignore next */
const msalInstance = new PublicClientApplication({
	auth: {
		clientId: config.clientId,
		redirectUri: config.redirectUri,
		authority: config.authority
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: true,
	}
});

/* istanbul ignore next */
msalInstance.initialize()

/* istanbul ignore next */
export const getAccessToken = async () => {
	try {
		const account = await msalInstance.getAllAccounts()[0];
		const accessTokenRequest = {
			scopes: [config.scope],
			account: account,
		};

		const msalResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
		const access_token = msalResponse.accessToken
		setAuthToken(access_token)
		return access_token;
	} catch (error) {
		console.log(error)
	}
}

/* istanbul ignore next */
function App() {

	const [isAuthorized, setIsAuthorized] = useState(false);
	const [getAuthorizeStaff, { data: authorizeStaffDetails }] = useAuthorizeStaffMutation();
	const [ssoToken, setSSOToken] = useState();
	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		saveItem('SKIP_SSO', process.env.REACT_APP_SKIP_SSO)
		async function fetchSSOToken() {
			await checkForSSOToken();
		}
		
		if (getSource() === 'D365' && process.env.REACT_APP_SKIP_SSO === "false") {
			fetchSSOToken();
			saveTokenType(TOKEN_TYPE.AZURE_AD)
		} else {
			removeTokenType();
			setIsAuthorized(true);
			setShowLoader(false)
		}
		
		if(window.location.pathname === HOME || window.location.pathname === LOCATION_FINDER){
			localStorage.removeItem('redirectionPathName');
		}

		if(window.location.pathname === LOCATION_FINDER || window.location.pathname === APPOINTMENTS || window.location.pathname === MCC_DASHBOARD){	
			const params = new URLSearchParams(window.location?.search);
			localStorage.removeItem('id4_url');
			if(params?.get('province') === null && !sessionStorage.getItem('prov')){
				removeItemFromBrowserStorage('user');
            	removeAuthToken();
			} else if(!sessionStorage.getItem('prov') && params?.get('province')){
				sessionStorage.setItem('prov', params?.get('province'));
			}

			if(params?.get('source') === null && !sessionStorage.getItem('source')){
				removeItemFromBrowserStorage('user');
            	removeAuthToken();
			} else if(!sessionStorage.getItem('source') && params?.get('source')){
				sessionStorage.setItem('source', params?.get('source'));
			}
		}
	}, [])

	useEffect(() => {
		if (ssoToken) {
			handleSSOToken(ssoToken);
		}
	}, [ssoToken])

	const checkForSSOToken = async () => {
		let ssoAccessToken = await getAccessToken();
		if (!ssoAccessToken) {
			setShowLoader(false);
		}
		setSSOToken(ssoAccessToken)
	}

	const handleSSOToken = async (ssoAccessToken) => {
		if (inIframe()) {
			setIsAuthorized(true);
			setShowLoader(false);
		} else {
			let staffDetails = decodeJwtToken(ssoAccessToken);
			getAuthorizeStaff(process.env.REACT_APP_ENV === "dev" ? 'PWT.CallCenterAdmin@lifelabs.com' : staffDetails?.upn)
		}
	}

	const handleSSO = async () => {
		try {
			const response = await msalInstance.loginPopup({
				scopes: [config.scope],
				prompt: "select_account"
			});
			setAuthToken(response.accessToken)
			handleSSOToken(response.accessToken)
		} catch (error) {
			console.log(error);
			setIsAuthorized(false);
		}
	};

	useEffect(() => {
		if (authorizeStaffDetails?.email && !inIframe()) {
			setIsAuthorized(true);
		}
		setShowLoader(false);
	}, [authorizeStaffDetails])

	return (
		<ThemeProvider theme={theme}>
			{
				showLoader ?
					(
						<Backdrop
							sx={{ backgroundColor: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
							open={showLoader}
							invisible={true}
						>
							<CircularProgress />
						</Backdrop>
					) :
					isAuthorized ?
						<RouterProvider router={router} /> :
						(
							<Grid
								container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="left"
								sx={{ minHeight: '100vh', marginTop: '100px' }}
							>
								<Card
									sx={{
										padding: '20px'
									}}
								>
									<CardContent>
										<Typography variant='h6'>
											Click on Below Button to Complete SSO
										</Typography>
									</CardContent>

									<CardActions sx={{
										justifyContent: "center"
									}}>
										<Button
											onClick={() => handleSSO()}
											variant='contained'
											size='large'
										>
											Complete SSO
										</Button>
									</CardActions>
								</Card>
							</Grid>
						)
			}
		</ThemeProvider>
	)
}

export default App