import { Grid, Stack, Typography } from '@mui/material'
import TextButton from 'components/buttons/TextButton'
import Brightness1Icon from 'assets/Brightness1.jsx'
import IconGrid from 'components/data-display/IconGrid/IconGrid'
import LocationPin from 'assets/LocationPin'
import { Strings } from 'utils/Strings'
import { getWaitTimeColor, getWalkInTimeString, checkOpenDate, getTimeexact } from 'utils/common.utils'
import { testAttr } from 'utils/test/test.utils'
import EarliestTime from './EarliestTime'
import Text from 'components/Text'
import { convertDateTimeForEarliestTime, formatDateTimein12Hour } from 'utils/dateTime.utils'
import { resetEarliestAppointmentTimeApi, useLazyGetEarliestAppointmentTimeQuery } from 'store/services/earliestAppointmentTimeApi'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useLazyGetWaitTimeQuery } from 'store/services/pscWaitTimeApi'

export const WaitTime = ({ location, ...props }) => {
	return (
		<IconGrid
			sx={{ ...props.sx }}
			muiIcon={
				<Brightness1Icon
					color={getWaitTimeColor(location)}
					sx={{ fontSize: '0.81rem' }}
				/>
			}
		>
			<Grid item xs={11} sx={{ paddingLeft: '0.3rem' }}>
				<Text variant="paragraph3">
					{getWalkInTimeString(location?.waitTime)}
				</Text>
			</Grid>
		</IconGrid>
	)
}

const Content = ({
	location,
	id,
	handleEarlApptClick,
	handleClickDetails,
	setUpdateEarliestApptTime,
	setUpdateWaitTime
}) => {
	const dispatch = useDispatch();
	const contentComponentRef = useRef(null);
	const [getEarliestAppointmentTime, { data: earliestAppointmentDetails }] = useLazyGetEarliestAppointmentTimeQuery("");
	const {
		specialityTests: selectedSpeciltyTest,
		date: selectedDate
	} = useSelector(state => state.filter);
	const applicationSettings = useSelector(
		(state) => state.config?.applicationSettings
	)
	const [getWaitTime, { data: waitTimeDetails }] = useLazyGetWaitTimeQuery("");

	/* istanbul ignore next */
	useEffect(() => {
		/* istanbul ignore next */
		const observer = new IntersectionObserver((entries) => {
			let intervalId;
			if (entries[0].isIntersecting) {
				if (location?.earliestAppt === null && location?.earliestAppointmentSlots === null) {
					getEarliestAppointmentTime({
						site_id: [location?.siteIdGuid],
						date: [selectedDate === null ? moment(new Date()).format('YYYY-MM-DD') : moment(selectedDate).format('YYYY-MM-DD')],
						tests: {
							"id": !selectedSpeciltyTest ? applicationSettings?.generalspecialtytestguid : selectedSpeciltyTest?.id,
							"type": !selectedSpeciltyTest ? '1' : selectedSpeciltyTest?.testType
						}
					});
				} else if (location?.earliestAppointmentSlots?.appointmentSlots) {
					const earliestTime = {
						siteId: location?.siteIdGuid,
						earliestTime: convertDateTimeForEarliestTime(location?.earliestAppointmentSlots?.appointmentSlots[0]?.slots[0]?.time),
						earliestTimeOrg: location?.earliestAppointmentSlots?.appointmentSlots[0]?.slots[0]?.time
					}
					setUpdateEarliestApptTime(prevItems => [...prevItems, earliestTime]);
				}

				getWaitTime({
					siteId: location?.siteId,
					siteIdGUID: location?.siteIdGuid,
					scheduleDate: selectedDate === null ? moment(new Date()).format('YYYY-MM-DD') : moment(selectedDate).format('YYYY-MM-DD'),
				})

				intervalId = setInterval(() => {
					getWaitTime({
						siteId: location?.siteId,
						siteIdGUID: location?.siteIdGuid,
						scheduleDate: selectedDate === null ? moment(new Date()).format('YYYY-MM-DD') : moment(selectedDate).format('YYYY-MM-DD'),
					});
				}, 60000);

				observer.unobserve(contentComponentRef.current);

			} else {
				clearInterval(intervalId);
			}
		});

		/* istanbul ignore next */
		if (contentComponentRef.current) {
			observer.observe(contentComponentRef.current);
		}

		/* istanbul ignore next */
		return () => {
			if (contentComponentRef.current) {
				observer.unobserve(contentComponentRef.current);
			}
		};
	}, [location?.siteIdGuid]);

	useEffect(() => {
		if (earliestAppointmentDetails) {
			const earliestTime = {
				siteId: location?.siteIdGuid,
				earliestTime: earliestAppointmentDetails?.earliestTimeFormatted,
				earliestTimeOrg: earliestAppointmentDetails?.earliestTimeOrg
			}
			setUpdateEarliestApptTime(prevItems => [...prevItems, earliestTime]);
		}
	}, [earliestAppointmentDetails]);

	useEffect(() => {
		if (waitTimeDetails) {
			const waitTimeList = {
				siteId: location?.siteIdGuid,
				waitTime: waitTimeDetails?.waitTime,
			}
			setUpdateWaitTime(prevItems => [...prevItems, waitTimeList]);
		}
	}, [waitTimeDetails])

	return (
		<Stack className="location-stack" ref={contentComponentRef}>
			<EarliestTime
				id={id}
				location={location}
				handleEarlApptClick={handleEarlApptClick}
				earliestTime={location?.earliestAppt}
			/>
			{checkOpenDate(location?.closingTime, location?.address?.timeZone) === true && !location?.isTemporarilyClosed && !location?.isPermanentlyClosed && (
				<Grid container sx={{ margin: '0.3rem 0', paddingLeft: '7%' }}>
					<Typography
						variant="paragrah3"
						sx={{
							borderRight: '1px solid gray',
							marginRight: '1rem',
							paddingRight: '1rem',
						}}
						data-testid="open today"
					>
						{Strings.LOC_STATUS.OT}
					</Typography>
					<Typography variant="paragrah3">
						{formatDateTimein12Hour(location?.openingTime)} - {formatDateTimein12Hour(location?.closingTime)}
					</Typography>
				</Grid>
			)}
			{location?.isTemporarilyClosed === true && (
				<IconGrid
					muiIcon={
						<Brightness1Icon
							sx={{ fontSize: '0.81rem', color: 'error.main' }}
						/>
					}
				>
					{Strings.LOC_STATUS.CT}
				</IconGrid>
			)}
			<IconGrid
				muiIcon={<LocationPin fillColor="#13499F" />}
				sx={{ marginTop: '0.5rem' }}
			>
				<TextButton
					aria-label={Strings.LOC_DETAILS}
					{...testAttr(`location-details-${location?.id}`)}
					onClick={handleClickDetails}
					id={location?.siteId}
				>
					{Strings.LOC_DETAILS}
				</TextButton>
			</IconGrid>
		</Stack>
	)
}

export default Content
