function MyLocation({ fillColor }) {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="8.49967"
				cy="8.50016"
				r="4.91667"
				stroke={fillColor}
				strokeWidth="1.5"
			/>
			<circle
				cx="8.50022"
				cy="8.50022"
				r="1.13889"
				fill={fillColor}
				stroke={fillColor}
				strokeWidth="1.5"
			/>
			<line
				x1="13.9727"
				y1="8.69434"
				x2="16.2504"
				y2="8.69434"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<line
				x1="0.75"
				y1="8.69434"
				x2="3.02778"
				y2="8.69434"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<line
				x1="8.69434"
				y1="3.02783"
				x2="8.69434"
				y2="0.750054"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<line
				x1="8.69434"
				y1="16.25"
				x2="8.69434"
				y2="13.9722"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default MyLocation
