function SearchIcon({ fillColor }) {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3947 6.92391C12.3947 9.91945 9.96632 12.3478 6.97078 12.3478C3.97524 12.3478 1.54688 9.91945 1.54688 6.92391C1.54688 3.92837 3.97524 1.5 6.97078 1.5C9.96632 1.5 12.3947 3.92837 12.3947 6.92391ZM11.256 12.3628C10.0772 13.2928 8.58881 13.8478 6.97078 13.8478C3.14681 13.8478 0.046875 10.7479 0.046875 6.92391C0.046875 3.09994 3.14681 0 6.97078 0C10.7948 0 13.8947 3.09994 13.8947 6.92391C13.8947 8.58947 13.3066 10.1177 12.3267 11.3122L16.7333 15.7188C17.0262 16.0117 17.0262 16.4865 16.7333 16.7794C16.4404 17.0723 15.9655 17.0723 15.6726 16.7794L11.256 12.3628Z"
				fill={fillColor}
			/>
		</svg>
	)
}

export default SearchIcon
