import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Text from 'components/Text'
import { testAttr } from 'utils/test/test.utils'
import { ReactComponent as ErrorIcon } from 'assets/Error.svg'
import { Strings } from 'utils/Strings'
import { formattedPhoneNumber, validateEmailAddress, validatePhoneNumber } from 'utils/common.utils'

const isOptional = (helperText) => {
	return helperText !== "(Optional)";
}

const RoundedTextField = ({
	label,
	onChange,
	id,
	validations = {
		removeNums: true,
	},
	helperText,
	type,
	...props
}) => {

	const [value, setValue] = useState('')
	const [pristine, setPristine] = useState(true)
	const [error, setError] = useState(false)
	const [errorValue, setErrorValue] = useState('');
	const [punctuations, setPunctuations] = useState(0);

	useEffect(() => {
		setValue(props.value);
		const { error, errorValue } = handleValidation(value);
		setError(error);
		setErrorValue(errorValue);
	}, [props.value]);

	useEffect(() => {
		if (pristine === false && value?.length === 0 && isOptional(helperText)) {
			setError(true)
		}
	}, [value, pristine])

	const handleValidation = (value) => {
		let error = false;
		let errorValue = '';
		if (value && value !== '') {
			if (validations.phoneNumber) {
				error = validatePhoneNumber(value);
				errorValue = 'Enter a Valid Phone Number';
			} else if (validations.emailAddress) {
				error = validateEmailAddress(value);
				errorValue = 'Enter a Valid Email Address';
			} else {
				if (validations.removeNums) {
					value = value?.replace(/[0-9]/g, '')
				}
				if (validations.maxLen) {
					value = value.slice(0, validations.maxLen)
				}
				if (validations.removeUnneededChars) {
					value = value?.replace(/[^a-zA-Z0-9.'-]/g, '');
					if (value.length === 1) {
						value = value?.replace(/[^a-zA-Z0-9]/g, '')
					}
				}
				if (/^\d/.test(value) && value.length < 3) {
					error = true;
					errorValue = 'Enter a Vaild Name';
				}
			}

			let punctuationsCount = value.match(/[.'-]/g)?.length || 0;
			if (validations.maxSpecialChars !== undefined) {
				if (punctuationsCount >= validations.maxSpecialChars) {
					error = true;
					errorValue = 'Enter a Vaild Name';
				}
			}
			setPunctuations(punctuationsCount);
		}

		return {
			error,
			errorValue,
			value
		}
	};

	useEffect(() => {
		if (label === Strings.EMAIL_ADDRESS && !isOptional(helperText)) {
			setError(false);
			setErrorValue("");
		} else {
			const { error, errorValue } = handleValidation(value);
			setError(error);
			setErrorValue(errorValue);
		}
	}, [helperText])

	return (
		<Stack {...props} marginBottom={3}>
			<Stack alignItems="center" direction="row" gap="10px">
				<Typography
					variant="caption"
				>
					{label}
				</Typography>
				<Text
					variant="subtitle2"
					sx={{
						color: "#595959",
						lineHeight: "18px",
						fontSize: "12px"
					}}
				>
					{helperText}
				</Text>
			</Stack>


			<TextField
				required
				id={id}
				inputProps={{ ...testAttr(id) }}
				aria-describedby={error ? `${id}-error` : null}
				sx={{
					'.MuiOutlinedInput-root': {
						borderRadius: '5rem',
					},
					'.MuiOutlinedInput-root .MuiOutlinedInput-input': {
						padding: '0.5rem 1rem',
					},
					'.MuiOutlinedInput-notchedOutline': {
						borderColor: (error || props?.adhocUserDuplicateNameError || props?.showError) ? 'error.main' : 'background.light',
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: (error || props?.adhocUserDuplicateNameError || props?.showError) ? 'error.main' : 'background.light',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: (error || props?.adhocUserDuplicateNameError || props?.showError) ? 'error.main' : 'background.light',
					},
				}}
				value={(label === Strings.PHONE_NUMBER) ? formattedPhoneNumber(value) : value}
				onChange={(event) => {
					const { error, errorValue, value } = handleValidation(event.target.value);
					if (isOptional(helperText)) {
						setError(error);
						setErrorValue(errorValue);
					}
					setValue(value)
					onChange(value, error)
				}}
				onBlur={() => {
					if (errorValue?.length === 0 && isOptional(helperText)) {
						setError(value?.length === 0)
					}
				}}
				onKeyUpCapture={(event) => {
					setPristine(false)
					if (event.target.value?.length > 0 && !error) {
						setError(false)
					}
				}}
				placeholder={label}
				variant="outlined"
				InputProps={{
					endAdornment:
						<>
							{(error || props?.adhocUserDuplicateNameError || props?.showError) ?
								<InputAdornment position="end">
									<IconButton
										aria-label="error"
										edge="end"
									>
										<ErrorIcon />
									</IconButton>
								</InputAdornment> 
							: null}
						</>
				}}
				disabled={props?.disabled}
			/>

			{error && (
				<Text
					variant="caption"
					sx={{ color: 'error.main' }}
					i18nKey={(errorValue.length > 0 || !isOptional(helperText)) ? errorValue : "MSG_REQUIRED_FIELD"}
					id={`${id}-error`}
				/>
			)}

			{!error && props.adhocUserDuplicateNameError && (
				<Text
					variant="caption"
					sx={{ color: 'error.main' }}
					i18nKey={"User With Same Name Already Exists"}
					id={`${id}-error`}
				/>
			)}
		</Stack>
	)
}

export default RoundedTextField
