import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from "react-redux";
import { getLocationMarkerIcon, getMarkerIcon } from "utils/common.utils";

/* istanbul ignore next */
const LocationPscMap = ({ marker, ...props }) => {
    const { isGoogleApiLoaded } = useSelector(state => state.map)
    const center = useMemo(() => ({ lat: 54.65107, lng: -100.347015 }), [])
    const [map, setMap] = useState(null)

    useEffect(() => {
        if (isGoogleApiLoaded && marker && map != null) {
            const bounds = new window.google.maps.LatLngBounds()
            bounds.extend({
                lat: Number(marker?.address?.latitude),
                lng: Number(marker?.address?.longitude)
            });
            map.fitBounds(bounds);
        }
    }, [map, marker, isGoogleApiLoaded])

    const onLoad = useCallback(function callback(map) {
        setTimeout(() => setMap(map), 100);
    }, [])

    return (
        <>
            {
                isGoogleApiLoaded ? <GoogleMap
                    mapContainerStyle={{
                        height: '200px'
                    }}
                    center={center}
                    onLoad={onLoad}
                >
                    <MarkerF
                        key={marker?.siteId}
                        title={marker?.siteName || ''}
                        position={{
                            lat: Number(marker?.address?.latitude),
                            lng: Number(marker?.address?.longitude)
                        }}
                        icon={{
                            url: 'data:image/svg+xml;charset=UTF-8,' +
                                encodeURIComponent(getLocationMarkerIcon()),
                            scale: 0.5,
                            optimized: false
                        }}
                    />
                </GoogleMap> : <></>
            }
        </>

    );
}

export default LocationPscMap;
