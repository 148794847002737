import { Stack } from '@mui/material'
import ConfirmAppointment from 'features/appointment/ConfirmAppointment'
import AppointmentConfirmed from 'features/appointment/AppointmentConfirmed'
import { useParams } from 'react-router'
import { CONFIRM_APPT, DASHBOARD } from 'utils/constants/routes-constants'
import MCCDashboard from './MCCDashboard'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthToken, saveTokenType, setAuthToken } from 'utils/common.utils'
import { TOKEN_TYPE } from 'utils/constants/common.constants'
import { setJWTToken } from 'store/reducers/authReducer'
import { useEffect } from 'react'

import CancelAppointment from 'features/ViewAppointment/CancelAppointment'

const MyCareCompass = () => {
	let { apptState } = useParams()

	const dispatch = useDispatch()

	const accessToken = useSelector((state) => state.auth?.token)

	const authToken = getAuthToken()

	useEffect(() => {
		if (accessToken === null && authToken) {
			saveTokenType(TOKEN_TYPE.ID4)
			dispatch(setJWTToken(authToken))
			setAuthToken(authToken)
		}
	}, [authToken, accessToken])

	return (
		<Stack>
			{(!apptState || apptState === DASHBOARD) && <MCCDashboard />}

			{apptState === CONFIRM_APPT && <ConfirmAppointment />}

			{apptState !== CONFIRM_APPT && apptState !== DASHBOARD && apptState && (
				<AppointmentConfirmed />
			)}
		</Stack>
	)
}

export default MyCareCompass
