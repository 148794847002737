import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   sortCriteria: ''
}

export const sortCriteriaSlice = createSlice({
    name: 'sortCriteria',
    initialState,
    reducers: {
        updateSortCriteria: (state, action) => {
            state.sortCriteria = action.payload
        },
        resetSortCriteria: () => {
            return {
                ...initialState
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetSortCriteria, updateSortCriteria } = sortCriteriaSlice.actions

export default sortCriteriaSlice.reducer