import { Stack, Typography, Box, Grid } from '@mui/material'
import { ReactComponent as PersonOutline } from 'assets/PersonOutline.svg'
import LocationPin from 'assets/LocationPin'
import IconGrid from 'components/data-display/IconGrid/IconGrid'
import { STATES } from 'utils/constants/common.constants'
import { Strings } from 'utils/Strings'
import CircleButton from 'components/buttons/CircleButton'
import Text from 'components/Text'

const CallPopover = ({ handleClose }) => {
	return (
		<Stack
			sx={{
				gap: '.81rem',
				padding: {
					xs: '1rem',
					md: '1rem 2rem'
				},
				paddingRight: {
					md: '3rem'
				},
				minWidth: {
					xs: "0rem",
					md: "27rem"
				},
				width: 'fit-content',
				borderRadius: '2rem'
			}}
		>
			<Stack direction="row" justifyContent="space-between">
				<Box>
					<IconGrid
						muiIcon={<PersonOutline />}
						marginBottom={1}
						justifyContent="space-between"
					>
						<Text variant="h5"
							i18nKey='LBL_AUTISM_PATIENT_SUPPORT' fontWeight="bold" />
					</IconGrid>
				</Box>
				<Box>
					<CircleButton
						aria-label="Close confirm dialog"
						onClick={handleClose}
						circleButtonId='close-popover-btn'
					/>
				</Box>
			</Stack>

			{STATES.map((state, index) => {
				return (
					<Box key={index}>
						<IconGrid muiIcon={<LocationPin fillColor="#13499F" />} marginTop={1}>
							<Typography
								variant="paragraph2"
								color="primary.dark"
								sx={{ paddingLeft: '0.5rem', fontWeight: '600' }}
							>
								{Strings[state.name]}
							</Typography>
						</IconGrid>
						<Stack
							sx={{
								borderLeft: '1px solid grey',
								paddingLeft: '0.81rem',
								marginTop: '0.81rem',
							}}
						>
							<Grid container alignItems='center'>
								<Text
									variant="paragraph3"
									i18nKey='LBL_TOLL_FREE' />
								<Typography
									variant='paragraph3'
									sx={{
										fontWeight: '600',
										marginLeft: '0.3rem',
										color: 'primary.dark',
										textDecoration: 'underline'
									}}>
									{state.number}
								</Typography>
							</Grid>
							<Stack direction="row" gap={{ xs: "0px", md: "12px" }} >
								<Typography variant="paragraph3" width={{ xs: "37%", md: "21%" }}>
									Open Hours:
								</Typography>
								<Typography variant="paragraph3">
									{state.openHours}
								</Typography>
							</Stack>
						</Stack>
					</Box>
				)
			})}
		</Stack>
	)
}

export default CallPopover
