import { useState } from 'react'
import {
	Box,
	Button,
	Link,
	Stack,
	Tooltip,
} from '@mui/material'
import DateFilter from 'features/filters/DateFilter'
import ServicesOfferedFilter from 'features/filters/ServicesOfferedFilter'
import SpecialtyTestFilter from 'features/filters/SpecialtyTestFilter'
import { useDispatch, useSelector } from 'react-redux'
import { resetFilters, updateFilter } from './filterSlice'
import './Filters.scss'
import CustomTooltipTitle from './CustomTooltip'
import { Trans } from 'react-i18next'
import theme from 'utils/theme'

const Filters = () => {
	const dispatch = useDispatch()

	const appliedFilters = useSelector((state) => state.filter);

	const [filter, setFilter] = useState(appliedFilters || {
		services: [],
		specialityTests: '',
		date: null
	})

	const [isApplySelected, setIsApplySelected] = useState(false)
	const [isTooltipOpen, setIsTooltipOpen] = useState(true)

	const handleFilterChange = (name, value) => {
		let updatedFilters = {
			...filter,
		}
		if (name === 'services') {
			const index = updatedFilters[name].indexOf(value)
			if (index > -1) {
				updatedFilters[name] = [
					...updatedFilters[name].filter(
						(filterValue) => filterValue !== value
					),
				]
			} else {
				updatedFilters[name] = [value, ...(updatedFilters[name] || [])]
			}
		} else {
			updatedFilters[name] = value
		}
		setFilter(updatedFilters)
		setIsApplySelected(false)
	}

	const handleClearAll = () => {
		setFilter({
			services: [],
			specialityTests: '',
			date: null
		})
		dispatch(resetFilters())
		setIsApplySelected(false)
	}

	const checkIsFilterSelected = (filter = {}) => {
		return (
			filter.services?.length > 0 ||
			filter.specialityTests?.name?.length > 0 ||
			filter.date !== null
		)
	}

	const handleApply = () => {
		dispatch(updateFilter(filter))
		setIsApplySelected(true)
	}

	return (
		<Box
			sx={{
				backgroundColor: 'info.bg',
				display: 'flex',
				padding: '16px 0px',
				position: 'relative',
			}}
		>
			<Stack direction="row" sx={{ width: '100%' }}>
				<Box
					sx={{
						alignSelf: 'left',
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: '12px',
						paddingLeft: '100px',
						paddingRight: '130px'
					}}
				>
					<Box sx={{ alignSelf: 'left', flexBasis: '45%' }}>
						<Tooltip
							role="tooltip"
							tabIndex={0}
							arrow
							placement='bottom-start'
							open={isTooltipOpen}
							title={<CustomTooltipTitle setIsTooltipOpen={setIsTooltipOpen} />}
							componentsProps={{
								tooltip: {
									sx: {
										backgroundColor: '#D4E4FE',
										color: '#13499F',
										boxShadow: theme.shadows[5],
										fontSize: 11,
										minWidth: 400,
										padding: '24px',
										'& .MuiTooltip-arrow': {
											color: '#D4E4FE',
										},
									},
								},
							}}
						>
							<Stack
								direction={{
									xs: "column",
									md: "row"
								}}
								justifyContent="center"
								gap="12px"
							>
								<ServicesOfferedFilter
									selectedFilters={filter.services}
									handleFilterChange={handleFilterChange}
									onOpen={() => setIsTooltipOpen(false)}
								/>

								<SpecialtyTestFilter
									selectedFilters={filter.specialityTests}
									handleFilterChange={handleFilterChange}
									onOpen={() => setIsTooltipOpen(false)}
								/>

								<DateFilter
									selectedDate={filter.date}
									handleFilterChange={handleFilterChange}
									onOpen={() => setIsTooltipOpen(false)}
								/>
							</Stack>
						</Tooltip>
					</Box>

					<FilterApplyButtons
						filter={filter}
						checkIsFilterSelected={checkIsFilterSelected}
						handleClearAll={handleClearAll}
						isApplySelected={isApplySelected}
						handleApply={handleApply}
					/>
				</Box>
			</Stack>
		</Box>
	)
}

const FilterApplyButtons = ({
	filter,
	checkIsFilterSelected,
	handleClearAll,
	isApplySelected,
	handleApply,
	isMobile,
	filterButtonProps
}) => {
	return (
		<Stack
			direction="row"
			justifyContent="space-around"
			alignItems="center"
			sx={{
				...filterButtonProps
			}}
		>
			<Box order={isMobile ? 2: 1}>
				{(checkIsFilterSelected(filter) || isMobile) && (
					<Link
						sx={{ margin: '0px 16px' }}
						component="button"
						variant="body2"
						onClick={handleClearAll}
						disabled={isMobile ? !checkIsFilterSelected(filter): false}
					>
						<Trans i18nKey='BTN_CLEAR_ALL' />
					</Link>
				)}
			</Box>
			<Box order={isMobile ? 1: 2}>
				<Button
					title="Apply"
					sx={{
						backgroundColor: checkIsFilterSelected(filter) && !isApplySelected ? '#13499F' : '#FFFFFF',
						borderRadius: '80px',
						textTransform: 'none',
						padding: '10px 16px',
						width: '14.375em',
						maxHeight: '36px',
					}}
					variant={
						(checkIsFilterSelected(filter) && !isApplySelected) ? 'contained' : 'outlined'
					}
					color="primary"
					onClick={handleApply}
				>
					<Trans i18nKey='BTN_APPLY' />
				</Button>
			</Box>
		</Stack>
	)
}

Filters.FilterApplyButtons = FilterApplyButtons;

export default Filters
