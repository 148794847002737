import Button from "@mui/material/Button";
import Text from "components/Text";

const TextButton = ({ children, i18nKey, variant = 'paragraph3', ...props }) => {
    return (
        <Button
            variant="text"
            sx={{
                textTransform: 'none',
                fontWeight: 600,
                textDecoration: "underline",
                padding: {
                    md: '0'
                },
                ...props.sx
            }}
            role='button'
            {...props}
        >
            {
                i18nKey ? <Text
                    variant={variant}
                    i18nKey={i18nKey}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        textDecoration: "underline",
                        padding: 0,
                        color: 'primary.main',
                        ...props.sx
                    }}
                /> : <></>
            }
            {children}
        </Button>
    );
}

export default TextButton;
