import MCCFooter from './mcc/mcc.footer'

import './Footer.scss'
import { useLocation } from 'react-router'
import LifeLabsfooter from './lifelabs/lifelabes.footer'
import { APPOINTMENTS } from 'utils/constants/routes-constants'
import { useAuth } from 'hooks/useAuth'
import { useSelector } from 'react-redux'

// commenting chinese and punjabi as of now will update in the future
export const supportedLngs = {
	en: 'English',
	fr: 'Français/French',
	// zh: '中文/Chinese',
	// ph: 'ਪੰਜਾਬੀ/Punjabi',
}

const Footer = () => {
	const location = useLocation()
	const auth = useAuth()
	const source = useSelector((state) => state.search.source)

	/* istanbul ignore next */
	return (
		<>
			{auth?.user ? (
				source !== "D365" ? <MCCFooter supportedLngs={supportedLngs} /> : null
			) : (
				<LifeLabsfooter />
			)}
		</>
	)
}

export default Footer
