import { createApi } from '@reduxjs/toolkit/query/react'
import { CONFIG_PATH } from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'

export const transformResponse = (data, meta, arg) => {
    data?.specialityTest?.map((item)=>{
        item['testType'] = 1 // Specilaity
    })
    if(data?.specialityTest?.length > 0){
        data.specialityTest = data?.specialityTest.filter((test)=> test?.name?.toUpperCase() !== "GENERAL")
    }
    data?.toleranceTest?.map((item)=>{
        item['testType'] = 2 // Tolerance
    })
    return data || {}
};

export const configsApi = createApi({
    baseQuery: baseQueryWithReauth,
    reducerPath: 'configs',
    endpoints: (build) => ({
        postConfigs: build.query({
            query: (siteIds) => ({
                url: `${CONFIG_PATH}`,
                method: 'POST',
                body: {
                    siteIds
                }
            }),
            transformResponse,
			transformErrorResponse
        }),
    }),
})

export const { useLazyPostConfigsQuery } = configsApi;
