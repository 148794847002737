import { Card, Typography, Stack, Grid, Box } from '@mui/material'
import { Strings } from 'utils/Strings'
import { ReactComponent as ConfirmCalendarIconCTA } from 'assets/ConfirmCalendarCTA.svg'
import { ReactComponent as EmailShareIcon } from 'assets/EmailShareIcon.svg'
import { ReactComponent as PrintDetailsIcon } from 'assets/PrintDetailsIcon.svg'

import IconLink from 'components/data-display/IconLink'
import EyeButton from 'components/data-display/IconImg/EyeButton'
import moment from 'moment'
import { getLocation, getAppointmentType } from 'utils/common.utils'
import {
	calculateDurationInMins,
	convertToLocalizedTime,
	formatDateTimein12HourWithTimezone,
} from 'utils/dateTime.utils'
import { useSaveCallInvite } from 'hooks/useSaveCallInvite'
import { testAttr } from 'utils/test/test.utils'
import { useSaveEmlFile } from 'hooks/useSaveEmlFile'
import { usePrintAppointment } from 'hooks/usePrintAppointment'
import { VERTICAL_BAR } from 'utils/constants/location-constants'
import CancelAppointmentButton from 'components/buttons/CancelAppointmentButton/CancelAppointmentButton'
import { DATE_FORMAT, TIME_FORMAT } from 'utils/constants/time-constants'
import Text from 'components/Text'

const AppointmentHeader = ({
	appointment = {},
	handleClickApptDetails,
	index,
}) => {
	return (
		<Grid container justifyContent="space-between" alignItems="center" mb={1}>
			<Typography
				variant="subtitle2"
				sx={{
					flexGrow: 1,
					fontWeight: '600',
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
				}}
			>
				{moment(appointment.appointmentDate).format('ddd, MMM DD, YYYY')}
				<Box sx={{ display: { xs: 'none', md: 'inline' }, margin: '0 0.5rem' }}>
					{' | '}
				</Box>
				<Box sx={{ display: { xs: 'block', md: 'inline' } }}>
					{formatDateTimein12HourWithTimezone(
						appointment.startTime,
						appointment?.timeZone
					)}
				</Box>
			</Typography>
			<Box
				sx={{
					order: { xs: 2, md: 1 },
					margin: { xs: '0rem', md: '1rem' },
				}}
			>
				<Text variant="caption" i18nKey="LBL_CONFIRMATION">
					{appointment.bookingConfirmationNumber}
				</Text>
			</Box>
			<Box
				sx={{
					order: { xs: 1, md: 2 },
				}}
			>
				<EyeButton
					title="appointment-details"
					aria-label="appointment-details"
					onClick={handleClickApptDetails}
					{...testAttr('eye-btn-' + index)}
				/>
			</Box>
		</Grid>
	)
}

const AppointmentContent = ({ appointment = {} }) => {
	return (
		<Stack sx={{ paddingLeft: '1rem', borderLeft: '1px solid gray' }}>
			<Text.Bold variant="paragraph3">
				{appointment.contact?.firstName || ''}{' '}
				{appointment.contact?.lastName || ''}
			</Text.Bold>
			<Typography variant="paragraph3">
				{getAppointmentType(appointment)}
				{VERTICAL_BAR}
				{calculateDurationInMins(appointment.startTime, appointment.endTime)}
			</Typography>
			<Typography variant="paragraph3">
				{getLocation(appointment.locations)}
			</Typography>
		</Stack>
	)
}

const AppointmentActions = ({ appointment = {}, onCancelAppointment }) => {
	const saveCallInvite = useSaveCallInvite({}, appointment)
	const saveEmalFile = useSaveEmlFile(
		{},
		appointment
	)
	const printAppointment = usePrintAppointment({},appointment)

	return (
		<Grid
			sx={{
				display: 'flex',
				flexDirection: {
					xs: 'column',
					md: 'row',
				},
			}}
			mt={1}
		>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: {
						xs: 'column',
						md: 'row',
					},
					alignSelf: 'bottom',
					minHeight: '3rem',
				}}
			>
				<Box
					mb={{
						xs: 2,
						md: 0,
					}}
					sx={{
						display: 'flex',
					}}
				>
					<IconLink
						id="email-share-link"
						muiIcon={<EmailShareIcon />}
						onClick={saveEmalFile}
						text={Strings.LINK_SHARE_BY_MAIL}
						mr={3}
						centerIcon={true}
					/>

					<IconLink
						id="handle-print-link"
						muiIcon={<PrintDetailsIcon />}
						onClick={printAppointment}
						text={Strings.LINK_PRINT_DETAILS}
						mr={3}
					/>
				</Box>
				<IconLink
					id="add-to-cal-link"
					muiIcon={<ConfirmCalendarIconCTA stroke="#13499F" />}
					onClick={saveCallInvite}
					text={Strings.APPOINTMENTBOOKINGS_BOOKINGSUCCESS_ADDTOCALENDER}
					mr={3}
				/>
			</Grid>

			<Grid
				sx={{
					flexGrow: 1,
					display: 'flex',
					justifyContent: {
						md: 'flex-end',
						xs: 'center',
					},
				}}
			>
				<CancelAppointmentButton
					handleClick={onCancelAppointment}
					addDialog={false}
				/>
			</Grid>
		</Grid>
	)
}

const UpcomingAppointmentCard = ({
	appointment = {},
	onCancelAppointment,
	children,
	...props
}) => {
	return (
		<Card
			sx={{
				backgroundColor: 'info.bg',
				padding: '1rem',
				marginBottom: '1rem',
				borderRadius: '1rem',
			}}
			{...props}
		>
			{children}
		</Card>
	)
}

UpcomingAppointmentCard.Header = AppointmentHeader
UpcomingAppointmentCard.Content = AppointmentContent
UpcomingAppointmentCard.Actions = AppointmentActions

export default UpcomingAppointmentCard
