import { useLayoutEffect } from 'react'

import { Grid, Stack } from '@mui/material'

import BookHome from 'components/BookHome'
import Search from 'components/Search'

import './SearchBar.scss'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'components/Text'
import useMobile from 'hooks/useMobile'

/* istanbul ignore next */
const SearchBar = ({ setShowMyVisit, postLocations, searchBarId, data }) => {
	const dispatch = useDispatch()
	const userAddressParams = useSelector((state) => state.userAddress)
	const filterParams = useSelector((state) => state.filter)
	const selectedProvince = useSelector((state) => state.selectedProvince)
	const isMobile = useMobile();
	const auth = useSelector((state) => state.auth);
	const applicationSettings = useSelector(
		(state) => state.config?.applicationSettings
	)
	const { staffAddress } = useSelector((state) => state.search)

	useLayoutEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const staffCustomerAddress = urlParams.get("addr");
		const staffSource = urlParams.get("source");

		const shouldPostLocations =
			(staffSource === 'D365' && (staffCustomerAddress?.length > 0 || staffAddress === '') && userAddressParams) ||
			(staffSource !== 'D365' && userAddressParams.address && !staffCustomerAddress)
		
		if (shouldPostLocations && userAddressParams?.lat !== '') {
			postLocations({
				...userAddressParams,
				...filterParams,
				applicationSettings
			});
		}
	}, [dispatch, filterParams, postLocations, userAddressParams, auth])

	return (
		<>
			<Grid
				container
				id={searchBarId}
				sx={{
					flexDirection: { xs: 'column', md: 'row' },
					padding: {
						xs: "40px 0px 24px 16px",
						md: '0'
					},
					paddingBottom: { md: '1rem' }
				}}
				className="search-bar"
				justifyContent="space-between"
				alignItems="center"
				justifyItems="center"
			>
				<Grid
					className="search"
					item
					md={7}
					sx={{
						width: '100%',
					}}
				>
					<Grid
						container
						sx={{
							justifyContent: {
								xs: "inherit",
								md: "center"
							}
						}}
						alignItems="center"
						justifyItems="center"
					>
						<Search searchId="find-location" />
					</Grid>
				</Grid>
				{
					!isMobile && (
						<Grid
							item
							md={5}
							sx={{
								width: '100%',
							}}
						>
							<Grid
								container
								sx={{
									justifyContent: {
										md: "center",
										lg: "inherit"
									},
									alignItems: {
										md: "center",
										lg: "inherit"
									},
									justifyItems: {
										md: "center",
										lg: "inherit"
									}
								}}
							>
								{selectedProvince === "ON" && <BookHome bookHomeId="book-home" setShowMyVisit={setShowMyVisit} />}
							</Grid>
						</Grid>
					)
				}
			</Grid>
			{
				isMobile && !data?.locations &&(
					<Grid
						container
						direction="column"
						sx={{
							backgroundColor: "white",
							padding: "24px",
							gap: "10px",
							alignItems: "center"
						}}
					>
						<Text
							i18nKey='LBL_USE_LOC_FINDER'
							sx={{
								fontWeight: '600',
								marginTop: '16px',
								marginBottom: "24px"
							}}
						/>
						<Grid
							item
							md={5}
							sx={{
								width: '100%',
							}}
						>
							<Grid
								container
								sx={{
									justifyContent: {
										md: "center",
										lg: "inherit"
									},
									alignItems: {
										md: "center",
										lg: "inherit"
									},
									justifyItems: {
										md: "center",
										lg: "inherit"
									}
								}}
							>
								{selectedProvince === "ON" ? <BookHome bookHomeId="book-home" setShowMyVisit={setShowMyVisit} />:null }
							</Grid>
						</Grid>
					</Grid>
				)
			}
		</>
	)
}

export default SearchBar
