import React from "react";
import FileNotFoundPage from 'features/Error/FileNotFoundPage'
import UnauthorizedPage from 'features/Error/UnauthorizedPage'
import ServiceUnavailablePage from 'features/Error/ServiceUnavailablePage' 
import GatewayTimeoutPage from 'features/Error/GatewayTimeoutPage'
import InternalServerErrorPage from 'features/Error/InternalServerErrorPage'
import GeneralErrorPage from 'features/Error/GeneralErrorPage'

const ErrorBoundary= ({
  errorType
}) => {
  return(
    <>
      {errorType === '401' && <UnauthorizedPage />}
      {errorType === '404' && <FileNotFoundPage />}
      {errorType === '500' && <InternalServerErrorPage />}
      {errorType === '503' && <ServiceUnavailablePage />}
      {errorType === '504' && <GatewayTimeoutPage />}
      {errorType === "" && <GeneralErrorPage />}
    </>
  )

}
export default ErrorBoundary