import { Grid, Stack, Typography, Link, Dialog } from '@mui/material'
import { MyVisitTM } from "components/CommonComponents";
import CircleButton from 'components/buttons/CircleButton';
import { Strings } from "utils/Strings";
import { REDURECTION_URLS } from 'utils/constants/redirection-urls';

const MyVisitPopover = ({ showMyVisitDialog, handleClose }) => {

    return (
        <Dialog open={showMyVisitDialog} sx={{ width: 'auto' }} PaperProps={{ sx: { borderRadius: "16px", maxWidth: { xs: 'auto', md:'700px' } }}}>
            <Stack sx={{
                padding: { xs: '1rem', md: '2rem' },
                borderRadius: '2rem',
            }}>
                <Grid container justifyContent='space-between' alignItems="center">
                    <Typography variant="subititle1" marginBottom={2}>
                        <MyVisitTM
                            variant="h5"
                            sx={{ fontWeight: '600'}}
                            appendString={Strings.APPOINTMENTS_CAPTS} />
                    </Typography>
                    <CircleButton
                        circleButtonId='myvisit-close-btn'
                        aria-label="Close confirm dialog"
                        onClick={handleClose}
                        style={{
                            marginBottom: "15px"
                        }}
                    />
                </Grid>

                <Typography variant='paragraph3' sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: '1.5rem' }}>
                    <MyVisitTM />{Strings.MY_VISIT_FEE}<MyVisitTM appendString={` portal?`} />
                </Typography>

                <Typography variant='paragraph3' marginTop={1} marginBottom={1} sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: '1.5rem'}}>
                    <MyVisitTM
                        prependString={Strings.LABEL_NOTE}
                        appendString={' ' + Strings.APPOINTMENTS} />
                    {Strings.MY_VISIT_NOTE}
                    <MyVisitTM appendString={Strings.PORTAL + '.'} />
                </Typography>

                <Typography variant='paragraph3'>
                    <Link target='_blank' rel='noopener' href={REDURECTION_URLS.MY_VISIT} >
                        <Typography 
                            variant='paragraph3'
                            sx={{ 
                                color: 'primary.main', 
                                fontWeight: '600', 
                            }}>{Strings.MY_VISIT_LEARN_MORE}{'MyVisit'}
                        </Typography>
                    </Link>
                    <sup
                        style={{
                            textDecoration: 'none !important',
                            fontSize: '12px',
                            color: '#13499F',
                            fontWeight: '600'
                        }}
                        aria-label='my-visit-label-tm'
                        role='superscript'
                    >
                        TM
                    </sup>
                    {'  '}{Strings.LABEL_OR}{' '}{Strings.MY_VISIT_LINK.toLowerCase()}
                    <Link target='_blank' rel='noopener' href={REDURECTION_URLS.MY_VISIT}>
                        <MyVisitTM
                            sx={{ color: 'primary.main', fontWeight: '600' }}
                            appendString={Strings.PORTAL + '.'}
                        />
                    </Link>
                </Typography>

            </Stack>
        </Dialog>
    )
}

export default MyVisitPopover;
