import { createApi } from '@reduxjs/toolkit/query/react'
import { EARLIEST_TIME_PATH } from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'
import { convertDateTimeForEarliestTime } from 'utils/dateTime.utils';

export const transformResponse = (data) => {
	if(data?.appointmentSlots && data?.appointmentSlots[0].slots){
		const earliestAppointmentTime = convertDateTimeForEarliestTime(
			data?.appointmentSlots[0]?.slots[0]?.time);
		return {
			earliestTimeFormatted: earliestAppointmentTime,
			earliestTimeOrg: data?.appointmentSlots[0]?.slots[0]?.time
		}
	} else {
		return undefined
	}
}

export const earliestAppointmentTimeApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'earliestAppointmentTime',
	endpoints: (build) => ({
		getEarliestAppointmentTime: build.query({
			query: (data) => ({
				url: `${EARLIEST_TIME_PATH}`,
				method: 'POST',
				body: {
					site_id: [...(data.site_id)],
					date: data?.date,
					tests: data?.tests?.id ? [data?.tests] : undefined
				},
			}),
			transformResponse,
			transformErrorResponse
		}),
	}),
})

export const {
    useLazyGetEarliestAppointmentTimeQuery
} = earliestAppointmentTimeApi
export const resetEarliestAppointmentTimeApi = earliestAppointmentTimeApi.util.resetApiState
