import Text from 'components/Text';
import { Box, Grid, Link, List, ListItem, Typography } from '@mui/material';
import { footerLinkList } from './FooterLinksList';
import './lifelabs.footer.scss';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';

const LifeLabsfooter = () => {
    const selectedProvince = useSelector((state) => state.selectedProvince)
    return (
        <Grid container className='footer__container'>
            <FooterTop />
            <FooterBody selectedProvince={selectedProvince}/>
            <FooterLinks selectedProvince={selectedProvince}/>
        </Grid>
    )
}

const FooterTop = () => {
    return (
        <Grid container className="footerTop">
            <Grid item xs={12}>
                <Text.Bold className="title">Land Acknowledgement</Text.Bold>
            </Grid>
            <Grid item xs={12} className="footer-heading">
                <Typography variant="body1" className="footer-heading-text">
                    {"At LifeLabs, we care about people's health and are committed to empowering a healthier you. We humbly acknowledge that our company conducts its operations across the vast and diverse lands of what is now called Canada, home to the traditional territories of First Nations, Inuit, and Métis Peoples, each with their distinct cultures, languages, and histories. We are mindful of their unique rights and perspectives, actively seeking engagement and partnership, and striving to build sustainable relationships. Our vision of a more inclusive and equitable future guides us in providing caring, efficient, reliable, and high-quality services, honoring the contributions of Indigenous cultures to Canada's tapestry."}
                </Typography>
            </Grid>
        </Grid>
    )
}

const FooterBody = ({ selectedProvince }) => {
    return (
        <Grid container className='footerbody'>
            {footerLinkList.map((menu, index) => (
                <Grid item key={index} className='footer-listitem'>
                    <MenuItem
                        title={menu.title}
                        link={menu.link}
                        subMenuItems={menu.menuItem}
                        selectedProvince={selectedProvince}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const FooterLinks = ({selectedProvince}) => {
    return (
        <Grid item className="footer-links-container">
            <Stack direction={{ xs: 'column', md: "row" }} alignItems="center">
                <Grid item md={9} sm={12} className="footer-links-section">
                    <Stack className="footer-links-list" direction="row">
                        <Box  className='footer-links-copyright'>
                            <Typography variant="body1" className="copyright">
                                {`Copyright ©${new Date().getFullYear()}`}
                            </Typography>
                        </Box>
                        <Box className='footer-links-responsive'>
                            <Link
                                href={"https://www.lifelabs.com/privacy-policy/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Privacy Policy
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/lifelabs-vulnerability-disclosure-program-policy/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Report A Vulnerability
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/accessibility/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Accessibility
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/terms-of-service/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                            >
                                Terms of Service
                            </Link>
                            <Link
                                href={"https://www.lifelabs.com/sitemap/" + "?myProvince="+selectedProvince?.toLowerCase()}
                                color="inherit"
                                underline="none"
                                className="footer-links-text"
                                sx={{
                                    display:{
                                        md: 'none',
                                        xs: "inherit"
                                    }
                                }}
                            >
                                Sitemap
                            </Link>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={3} className="footer-links-section ">
                    <List className="footer-links-list footer-kronos">
                        <ListItem className="footer-links-list-item">
                            <Link className="social-buttons" href="https://lifecentre.lifelabs.com" target="_blank" underline='none'>
                                LifeCentre
                            </Link>
                        </ListItem>
                        <ListItem className="footer-links-list-item footer-kronos">
                            <Link className="social-buttons" href="https://kronos.lifelabs.com/wfc/navigator/logon" target="_blank" underline='none'>
                                Kronos
                            </Link>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={4} className="social-links">
                    <div>
                        <Link className="linkedin" href="https://www.linkedin.com/company/lifelabs-medical-laboratory-services" title="Linkedin" aria-label="Linkedin">
                            <LinkedInIcon />
                        </Link>
                        <Link className="twitter" href="https://twitter.com/intent/follow?source=followbutton&amp;variant=1.0&amp;screen_name=lifelabs" title="Twitter" aria-label="Twitter">
                            <XIcon />
                        </Link>
                        <Link className="facebook" href="https://www.facebook.com/LifeLabsLaboratory" title="Facebook" aria-label="Facebook">
                            <FacebookIcon />
                        </Link>
                        <Link className="youtube" href="https://www.youtube.com/channel/UC6uFwF_U0Yej4mVxHQMAy8g" title="Youtube" aria-label="Youtube">
                            <YouTubeIcon />
                        </Link>
                    </div>
                </Grid>
            </Stack>
            <Link
                href={"https://www.lifelabs.com/sitemap/" + "?myProvince="+selectedProvince?.toLowerCase()}
                color="inherit"
                underline="none"
                className="footer-links-text"
                sx={{
                    display:{
                        md: 'inherit',
                        xs: "none"
                    }
                }}
            >
                Sitemap
            </Link>
        </Grid>
    )
}

const MenuItem = ({ title, link, subMenuItems, selectedProvince }) => {
    return (
        <div>
            <Typography variant="subtitle1">
                <Link href={link+"?myProvince="+selectedProvince?.toLowerCase()} className="link" underline="none">
                    {title}
                </Link>
            </Typography>
            {subMenuItems && (
                <List className='footer-section-list'>
                    {subMenuItems.map((item, index) => (
                        <ListItem key={index} className="listItem">
                            <Link href={item.link+"?myProvince="+selectedProvince?.toLowerCase()} className="subMenuItem" underline="none">
                                {item.title}
                            </Link>
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default LifeLabsfooter