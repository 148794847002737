import { Stack, Snackbar, SnackbarContent } from '@mui/material'
import { testAttr } from 'utils/test/test.utils'
import { Strings } from 'utils/Strings'
import { ReactComponent as NotificationIcon } from 'assets/AlertIcons/NotificationIcon.svg'
import { ReactComponent as SuccessNotificationIcon } from 'assets/AlertIcons/SuccessNotificationIcon.svg'
import { ReactComponent as NotificationCloseIcon } from 'assets/AlertIcons/NotificationCloseIcon.svg'
import { ReactComponent as SuccessCloseIcon } from 'assets/AlertIcons/SuccessCloseIcon.svg'
import { useSelector } from 'react-redux'

const COLORS = {
	NOTFN: { backgroundColor: '#FFF4DB', color: '#B8520A' },
	SUCCESS: { backgroundColor: '#EDFEEF', color: '#437048' },
}

const ALERTS = [
	{
		code: 100,
		text: Strings.ALERT_MESSAGES.RECOMMENDED_PSC,
		autoHideDuration: 6000
	},
	{
		code: 200,
		text: Strings.ALERT_MESSAGES.NA_SELECTED_SERVICES
	},
	{
		code: 250,
		text: Strings.ALERT_MESSAGES.NA_APPT_TIMESLOT,
		autoHideDuration: 6000
	},
	{
		code: 275,
		text: Strings.ALERT_MESSAGES.APPOINTMENT_TIMEOUT,
		autoHideDuration: 6000
	},
	{
		code: 300,
		text: Strings.ALERT_MESSAGES.ZERO_RESULTS,
		autoHideDuration: 6000
	},
	{
		code: 400,
		text: Strings.ALERT_MESSAGES.NA_SPECIALTY_TEST,
	},
	{
		code: 500,
		text: Strings.ALERT_MESSAGES.LOCATIONS_UPDATED,
		autoHideDuration: 6000
	},
	{
		code: 600,
		text: Strings.ALERT_MESSAGES.LOCATIONS_UPDATED,
		//Duplicate: Modify
		autoHideDuration: 6000
	},
	{
		code: 700,
		text: Strings.ALERT_MESSAGES.ACCOUNT_UPDATED,
		autoHideDuration: 6000
	},
	{
		code: 750,
		text: Strings.ALERT_MESSAGES.SUCCESS_REMOVE_REGISTRATION,
		autoHideDuration: 6000
	},
	{
		code: 800,
		text: Strings.ALERT_MESSAGES.APPT_CANCELLATION,
		autoHideDuration: 6000
	},
	{
		code: 0,
		text: 'An Error Occured',
		autoHideDuration: 1000
	}
]

const Toast = ({
	open,
	handleClose,
	alertCode,
	iconType = 'NOTFN',
	toastId,
	...props
}) => {

	const alert = ALERTS.find((alert) => alert.code === alertCode)

	const { backgroundColor, color } =
		alertCode >= 500 ? COLORS.SUCCESS : COLORS.NOTFN;

	const IconComponent = alertCode >= 500 ? SuccessNotificationIcon : NotificationIcon;
	const CloseIconComponent = alertCode >= 500 ? SuccessCloseIcon : NotificationCloseIcon;
	const source = useSelector((state) => state.search?.source)

	return (
		!alert ? <></> :
			<Snackbar
				id={toastId}
				open={open}
				autoHideDuration={alert.autoHideDuration}
				onClose={handleClose}
				{...props}
				sx={{
					width: { sm: '95%', md: '46%' },
					boxShadow: 3,
					position: 'fixed',
					bottom: source === 'D365' ? 'auto' : '20px',
				}}
				anchorOrigin={{
					horizontal: source === 'D365' ? 'center' : '',
					vertical: source === 'D365' ? 'top' : '' 
				}}
				{...testAttr(toastId)}
			>
				<Stack
					direction='row'
					alignItems='center'
					className='full-width full-height'
					sx={{
						padding: '0.3rem 1rem',
						backgroundColor: backgroundColor,
						color: color,
					}}
				>
					<IconComponent style={{ color: color }} />
					<SnackbarContent
						message={alert.text}
						color='green'
						sx={{
							width: '100%',
							fontSize: '0.875rem',
							color: color,
							background: 'transparent',
							boxShadow: 'none',
						}}
					/>
					<CloseIconComponent
						// style={{ color: COLORS[iconType].color }}
						onClick={handleClose}
						{...testAttr(`${toastId}-closeButton`)}

					/>
				</Stack>
			</Snackbar>
	)
}

export default Toast
