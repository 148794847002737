import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Stack
} from '@mui/material'
import Text from 'components/Text'
import CircleButton from 'components/buttons/CircleButton'
import React, { useEffect, useState } from 'react'
import { testAttr } from 'utils/test/test.utils'
import { ReactComponent as ArrowDown } from 'assets/FilterIcons/DropDownArrow.svg'
import { useDispatch, useSelector } from 'react-redux'
import ServicesOfferedFilter from './ServicesOfferedFilter'
import SpecialtyTestFilter from './SpecialtyTestFilter'
import DateFilter from './DateFilter'
import Filters from './Filters'
import { resetFilters, updateFilter } from './filterSlice'
import moment from 'moment'

const MobileViewFilters = ({
    setShowFilters,
}) => {
    const dispatch = useDispatch()
    const specialtyTests = useSelector(state => state.config.specialityTest) || []
    const toleranceTests = useSelector(state => state.config.toleranceTest) || []
    const [expanded, setExpanded] = useState(false);

    const appliedFilters = useSelector((state) => state.filter)
    const [filter, setFilter] = useState(appliedFilters || {
        services: [],
        specialityTests: '',
        date: null
    })

    const [appliedFilterCount, setAppliedFilterCount] = useState(0);

    useEffect(() => {
        let dateFilter = filter?.date ? 1 : 0;
        let servicesOfferedFilter = filter?.services?.length;
        let specialityTestFilter = filter?.specialityTests?.id ? 1 : 0;
        let appliedFilterCount = dateFilter + servicesOfferedFilter + specialityTestFilter;
        setAppliedFilterCount(appliedFilterCount);
    }, [filter])

    const checkIsFilterSelected = (filter = {}) => {
        return (
            filter.services?.length > 0 ||
            filter.specialityTests?.name?.length > 0 ||
            filter.date !== null
        )
    }

    const [isApplySelected, setIsApplySelected] = useState(false)

    const handleFilterChange = (name, value) => {
        let updatedFilters = {
            ...filter,
        }
        if (name === 'services') {
            const index = updatedFilters[name].indexOf(value)
            if (index > -1) {
                updatedFilters[name] = [
                    ...updatedFilters[name].filter(
                        (filterValue) => filterValue !== value
                    ),
                ]
            } else {
                updatedFilters[name] = [value, ...(updatedFilters[name] || [])]
            }
        } else {
            updatedFilters[name] = value
        }
        setFilter(updatedFilters)
        setIsApplySelected(false)
    }

    const handleClearAll = () => {
        setFilter({
            services: [],
            specialityTests: '',
            date: null
        })
        dispatch(resetFilters())
        setIsApplySelected(false)
    }

    const handleApply = () => {
        dispatch(updateFilter(filter))
        setIsApplySelected(true)
        setShowFilters(false)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid height='100%'>
            <Grid
                alignItems="center"
                sx={{
                    height:'80%',
                    overflowY: 'scroll'
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        padding: "16px",
                    }}
                >
                    <Text
                        i18nKey={`Filters (${appliedFilterCount})`}
                        sx={{
                            color: "#13499F"
                        }}
                    />
                    <CircleButton
                        {...testAttr(`filter-closeBtn`)}
                        aria-label="Close filter dialog"
                        onClick={() => setShowFilters(false)}
                    />
                </Stack>

                <Accordion
                    elevation={0}
                    defaultExpanded={true}
                    disableGutters
                    expanded={expanded === 'services'}
                    onChange={handleChange('services')}
                    sx={{
                        '&.MuiAccordion-root': {
                            borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "none"
                        },
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDown />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Text.Bold
                            variant="paragraph1"
                            sx={{
                                color: "#13499F"
                            }}
                            {...testAttr('services-offered')}
                        >
                            Services Offered ({filter?.services?.length})
                        </Text.Bold>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            padding: "0px"
                        }}
                    >
                        <ServicesOfferedFilter.ServicesOfferedList
                            handleFilterChange={handleFilterChange}
                            selectedFilters={filter.services}
                            isMobile={true}
                            textProps={{
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    disableGutters
                    expanded={expanded === 'specialityTest'}
                    onChange={handleChange('specialityTest')}
                    sx={{
                        '&.MuiAccordion-root': {
                            borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "none"
                        },
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDown />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Text.Bold
                            variant="paragraph1"
                            sx={{
                                color: "#13499F"
                            }}
                        >
                            Specialty Test ({filter?.specialityTests?.id ? 1 : 0})
                        </Text.Bold>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            padding: "0px",
                            maxHeight: '300px',
                            maxWidth: 'auto',
                            overflowY: 'auto',
                            overflowX: "hidden"
                        }}
                    >
                        <SpecialtyTestFilter.SpecialtyTestFilterList
                            selectedFilters={filter.specialityTests}
                            handleFilterChange={handleFilterChange}
                            specialtyTests={[
                                ...specialtyTests,
                                ...toleranceTests
                            ]}
                            isMobile={true}
                            textProps={{
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* DateFilter */}
                <Accordion
                    disableGutters
                    elevation={0}
                    expanded={expanded === 'date'}
                    onChange={handleChange('date')}
                    sx={{
                        '&.MuiAccordion-root': {
                            borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "none",
                        },
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDown />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            borderBottom: '2px solid rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <Stack direction="row" gap='10px'>
                            <Text.Bold
                                variant="paragraph1"
                                sx={{
                                    color: "#13499F"
                                }}
                            >
                                Filter by Date
                            </Text.Bold>
                            {filter.date ? (<Text
                                variant="paragraph1"
                                sx={{
                                    color: "#13499F"
                                }}
                            >
                                <Box>{'('}{moment(filter.date).format('MMM DD')}{')'}</Box>
                            </Text>) : null}
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            padding: "0px"
                        }}
                    >
                        <DateFilter.CustomDatePicker
                            selectedDate={filter.date}
                            handleFilterChange={handleFilterChange}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Filters.FilterApplyButtons
                filter={filter}
                checkIsFilterSelected={checkIsFilterSelected}
                handleClearAll={handleClearAll}
                isApplySelected={isApplySelected}
                handleApply={handleApply}
                isMobile={true}
                filterButtonProps={{
                    bottom: "0",
                    left: "0",
                    padding: "16px",
                    position: 'fixed',
                    borderTop: "1px solid black",
                    width: '100%',
                    height: '5%'
                }}
            />
        </Grid>
    )
}

export default MobileViewFilters