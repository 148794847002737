function TrashIcon({ fillColor }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="15"
			viewBox="0 0 18 20"
			fill="none"
		>
			<path
				d="M1.5 7.5C1.5 6.94772 1.94772 6.5 2.5 6.5H12.5C13.0523 6.5 13.5 6.94772 13.5 7.5V17.5C13.5 18.0523 13.0523 18.5 12.5 18.5H2.5C1.94772 18.5 1.5 18.0523 1.5 17.5V7.5Z"
				stroke={fillColor}
				strokeWidth="1.5"
			/>
			<line
				x1="1.25"
				y1="3.75"
				x2="13.75"
				y2="3.75"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5.5 3.5V2.5C5.5 1.94772 5.94772 1.5 6.5 1.5H8.5C9.05228 1.5 9.5 1.94772 9.5 2.5V3.5"
				stroke={fillColor}
				strokeWidth="1.5"
			/>
			<path
				d="M7.5 8.5L7.5 16.5"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M4.5 8.5L4.5 16.5"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M10.5 8.5L10.5 16.5"
				stroke={fillColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default TrashIcon
