import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import moment from 'moment'
import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { ReactComponent as ArrowLeft } from 'assets/Arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/Arrow-Right.svg'

const DatePicker = ({ selectedDate, onmonthChange, timeslot, onChange, shouldDisableDate, sx, from }) => {
	const [isTodatDate, setIsTodayDate] = useState(false);


	useEffect(() => {
		const checkSelectedDate = moment(selectedDate);
		const today = moment();
		if (checkSelectedDate.isSame(today, 'day')) {
			setIsTodayDate(true);
		} else {
			setIsTodayDate(false);
		}
	}, [selectedDate])

	const dateBackground = () => {
		if (!isTodatDate && timeslot?.length < 1) {
			return {
				bg: 'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)'
			};
		}
		if (isTodatDate && timeslot?.length < 1) {
			return {
				bg: 'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)'
			};

		}
		else if (isTodatDate && timeslot?.length > 0) {
			return {
				bg: '#fff'
			};
		}
		else {
			return {
				bg: 'rgb(19, 73, 159)'
			};
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} dateFormats={{ monthAndYear: 'MMM yyyy' }}>
			<DateCalendar
				shouldDisableDate={shouldDisableDate}
				disablePast
				sx={{
					backgroundColor: from === 'filters' ? 'white': '#F3F6FA',
					padding: from === 'filters' ? '4px' : '0px',
					'& .MuiPickersDay-dayWithMargin': {
						background: '#fff',
						border: '1.5px solid',
						borderColor: '#E0E0E0',
						color: '#000000',
						fontWeight: 'bold'
					},
					'& .MuiPickersDay-today': {
						backgroundColor: 'text.light',
						border: '.1rem solid',
						borderColor: 'info.contrastText',
					},
					'& .Mui-disabled.MuiPickersDay-dayWithMargin': {
						background: '#E0E0E0',
						border: 'none',
					},
					'& .Mui-selected.MuiPickersDay-dayWithMargin': {
						color: isTodatDate ? '#2D75E9' : '#D2D2D2',
						background: dateBackground().bg,
						fontWeight: '600',
					},
					'& .MuiPickersArrowSwitcher-button': {
						background: 'transparent',
					},
					'& .MuiDayCalendar-weekDayLabel': {
						color: '#000000',
						fontWeight: 'bold',
						padding: '0.5rem'
					},
					'& .MuiDayCalendar-weekContainer':{
						margin: '8px 0',
						maxHeight: '36px',
					},
					'& .MuiPickersDay-root': {
						margin: '0 5px'
					},
					...sx,
				}}
				minDate={moment.utc(new Date())}
				value={selectedDate ? moment(new Date(selectedDate)) : null}
				onMonthChange={onmonthChange}
				dayOfWeekFormatter={(_day, date) => (
					<Typography fontSize="0.81rem" sx={{ fontWeight: '600', color: 'black' }}>
						{moment(date, 'weekdayLong')
							.format('dddd')
							.substring(0, 2)
							.toUpperCase()
						}
					</Typography>
				)}
				onChange={onChange}
				slots={{
					leftArrowIcon: () => <ArrowLeft />,
					rightArrowIcon: () => <ArrowRight />,
					switchViewIcon: () => null,
				}}
				slotProps={{
					calendarHeader: {
						sx: {
							position: "relative",
							'& .MuiPickersArrowSwitcher-root': {
								width: 0,
								alignItems: 'center',
							},
							"& .MuiPickersCalendarHeader-labelContainer": {
								margin: "auto",
								fontWeight: 'bold',
								paddingRight: '9px',
								cursor: 'unset'
							},
							"& .MuiIconButton-edgeEnd": {
								position: "absolute",
								left: 60,
								top: 0,
								bottom: 0
							},
							"& .MuiIconButton-edgeStart": {
								position: "absolute",
								right: 60,
								top: 0,
								bottom: 0
							}
						}
					},
					leftArrowIcon: {
						sx: { color: "#000", fontSize: "2rem" }
					},
					rightArrowIcon: {
						sx: { color: "#000", fontSize: "2rem", }
					},
				}}
				views={['day']}
			/>
		</LocalizationProvider>
	)
}

export default DatePicker
