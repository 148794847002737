import { Link, Box } from '@mui/material'

import Home from 'assets/House'
import './BookHome.scss'
import { testAttr } from 'utils/test/test.utils'
import Text from 'components/Text'

//TODO: Add a click function to open book appointment drawer

const BookHome = ({ bookHomeId, setShowMyVisit }) => {
	return (
		<Box {...testAttr(bookHomeId)} className="book-home">
			<span>
				<Home fillColor="#13499F" />{' '}
				<Text
					variant="paragraph1"
					color="primary"
					i18nKey='MSG_BOOK_HOME_VISIT' 
					sx={{
						fontSize:{
							xs: "0.95rem",
						},
					}}
				/>
			</span>

			<Link {...testAttr(`${bookHomeId}-link`)} href='#'
				onClick={(e) => {
					e.preventDefault();
					setShowMyVisit(true)
				}}
			>
				<Text
					variant="paragraph3"
					color="primary"
					i18nKey={'LINK_BOOK_HOME_VISIT'}
					sx={{
						fontWeight: '600',
						textTransform: 'underline',
						fontSize: '1rem'
					}} />
			</Link>
		</Box>
	)
}

export default BookHome
