import { Box, Grid, Stack } from '@mui/material'
import CircleButton from 'components/buttons/CircleButton'

import { testAttr } from 'utils/test/test.utils'
import './Drawer.scss'

export default function Drawer({
	ariaLabel,
	drawerId,
	isOpen,
	onClickHandlerCloseBtn,
	children,
	...props
}) {
	return (
		<Stack
			{...testAttr(drawerId)}
			className="appointment-drawer"
			role="dialog"
			aria-label={ariaLabel}
			sx={{
				width: "auto",
				overflowX: "hidden",
				top: "71px"
			}}
			{...props}

		>
			<Grid
				container
				justifyContent="flex-end"
				sx={{
					backgroundColor: 'info.bg',
					padding: '10px 40px 24px',
				}}
			>
				<CircleButton
					circleButtonId={drawerId + '-closeBtn'}
					aria-label="Close Drawer"
					onClick={onClickHandlerCloseBtn}
				/>
			</Grid>
			<Box>{children}</Box>
		</Stack >
	)
}
