import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	token: null,
	user: {},
	isAuthorized: false,
	idToken: null,
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setJWTToken: (state, action) => {
			state.token = action?.payload
		},
		setUser: (state, action) => {
			state.user = action?.payload
		},
		setIsAuthorized: (state, action) => {
			state.isAuthorized = action?.payload
		},
		logOut: (state) => {
			state.token = null;
		},
		setIDToken: (state, action) => {
			state.idToken = action?.payload
		},
	},
})

export const { setJWTToken, setUser, setIsAuthorized, logOut, setIDToken } = authSlice.actions

export default authSlice.reducer
