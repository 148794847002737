import { useState } from 'react'
import {
	Button,
	Menu,
	FormControlLabel,
	Typography,
	Stack,
	Box,
	MenuItem,
} from '@mui/material'
import { testAttr } from 'utils/test/test.utils'
import { alpha } from '@mui/material/styles'
import './SortDropdown.scss'
import theme from 'utils/theme'

const SortDropdown = ({
	selectedOption,
	setSelectedOption,
	dropdownIcon,
	dropdownId,
	dropdownLabel,
	dropdownItems,
	dropdownControl,
	setSelectedSort,
	setRadioSelected
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<Stack
			{...testAttr(dropdownId)}
			id={dropdownId}
			className="dropdown-component"
		>
			<Button
				role="button"
				id={`${dropdownId}-btn`}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={dropdownIcon}
				sx={{
					minWidth: '5rem',
					border: open ? '2px solid' : 0,
					borderColor: 'info.border',
					backgroundColor:  "primary.light",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "4px",
					paddingLeft: {
						xs: '0',
						md: '2rem'
					},
					paddingRight: {
						xs: '0',
						md: '2rem'
					},
					'&:hover': {
					  backgroundColor: 'primary.light',
					},
				}}
			>
				<Typography variant="paragraph2">{dropdownLabel}</Typography>
			</Button>
			<StyledMenu
				theme={theme}
				MenuListProps={{
					'aria-labelledby': `${dropdownId}-menu`,
					id: `${dropdownId}-menu`,
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{dropdownItems.map((item, index) => {
					return dropdownControl ? (
						<li 
							key={index + 1} 
							className="dropdown-options"
							tabIndex={0}
							onKeyDown={(e)=>{
								if(e?.code === "Enter"){
									setSelectedSort(item?.value)
									setRadioSelected(true)
								}
							}}
						>
							<FormControlLabel
								name={item.label}
								value={item.value}
								control={dropdownControl}
								label={item.label}
								checked={selectedOption === item.value}
								sx={{
									color: 'primary.dark',
									'& .MuiFormControlLabel-label': {
										fontSize: '.91rem',
									},

								}}
							/>
						</li>
					) : (
						<li key={index + 1} className="dropdown-options">
							<Typography
								variant="paragraph3"
								color="#13499F"
								id={`${dropdownId}-${item.value}`}
								onClick={() => {
									setSelectedOption(item.label)
								}}
							>
								{item.label}
							</Typography>
						</li>
					)
				})}
			</StyledMenu>
		</Stack >
	)
}

const StyledMenu = ({ theme, ...props }) => {
	return (<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		sx={{
			'& .MuiPaper-root': {
				background: '#F3F6FA',
				padding: '16px',
				borderRadius: '4px',
				marginTop: {
					md: theme.spacing(1),
					xs: theme.spacing(2),
				},
				boxShadow: "0px 6px 6px 2px #59595926",
				width: {
					xs: '100%',
					md: '240px'
				},
				'& .MuiMenu-list': {
					padding: '4px 0',
					display: 'flex',
					flexDirection: 'column',
				},
				'&. MuiFormControlLabel-label': {
					fontSize: '0.1rem'
				},
				'& .MuiMenuItem-root': {
					'&:active': {
						backgroundColor: alpha(
							theme.palette.primary.main,
							theme.palette.action.selectedOpacity
						),
					},
				},
			}
		}}
		{...props}
	/>)
}

export default SortDropdown
