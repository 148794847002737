import { useSelector } from "react-redux";
import { getItemFromBrowserStorage, getProvinceInitials } from "utils/common.utils";
import { calculateEndTime } from "utils/dateTime.utils";
import useAppointmentActions from "./useAppointmentActions";

/* istanbul ignore next */
export const useSaveCallInvite = (user, appointment) => {

    const { appointmentConfirmationICS } = useAppointmentActions(user, appointment)

    const saveCalInvite = () => {
        const url = appointmentConfirmationICS();
        let blob = new Blob([url], { type: 'text/calendar;charset=UTF-8' });
        /* istanbul ignore next */
        if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
            // Open/Save link in IE and Edge
            window.navigator.msSaveBlob(blob,'InsertAppointmentIntoCalendar.ics');
        } else {
            // Open/Save link in Modern Browsers
            window.open(decodeURIComponent("data:text/calendar;charset=UTF-8," + url));
        }
    }

    return saveCalInvite;
}