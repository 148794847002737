import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom'

import ErrorBoundary from 'features/Error/ErrorBoundary'
import CancelAppointment from 'features/ViewAppointment/CancelAppointment'

import Home from 'pages/home/Home'
import HomeLayout from 'pages/layout/HomeLayout'
import AuthLayout from 'pages/layout/AuthLayout'
import ProtectedLayout from 'pages/layout/ProtectedLayout'
import Login from 'pages/login/Login'
import LoginReturn from 'pages/login/LoginReturn'
import MyCareCompass from 'pages/mcc/MyCareCompass'

import {
	APPOINTMENTS,
	HOME,
	LOGIN,
	LOGIN_RETURN,
	MCC_CONFIRM_APPT,
	LOCATION_FINDER,
	CANCEL_APPT,
} from 'utils/constants/routes-constants'

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<AuthLayout />}>
			<Route element={<HomeLayout />}>
				<Route path={HOME} element={<Home />} />
				<Route path={LOGIN} element={<Login />} />

				{/* FOR ID4 ReturnURL */}
				<Route
					path={LOGIN_RETURN}
					element={<LoginReturn redirectTo={MCC_CONFIRM_APPT} />}
				/>
			</Route>

			<Route
				element={<ProtectedLayout />}
				errorElement={<ErrorBoundary errorType="401" />}
			>
				<Route path={APPOINTMENTS} element={<MyCareCompass />}>
					<Route exact path=":apptState" />
				</Route>

				<Route path={LOCATION_FINDER} element={<Home />} />

				<Route path={CANCEL_APPT} element={<CancelAppointment />} />
			</Route>

			<Route path="*" element={<ErrorBoundary errorType="404" />} />
		</Route>
	)
)

export default router
