import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	address: '',
	lat: '',
	long: '',
}

export const userAddressSlice = createSlice({
	name: 'userAddress',
	initialState,
	reducers: {
		updateUserAddress: (state, action) => {
			return {
				address: action.payload.address,
				lat: action.payload.lat || '',
				long: action.payload.long || '',
			}
		},
		resetUserAddress: () => {
			return {
				...initialState,
			}
		},
	},
})

export const { updateUserAddress, resetUserAddress } = userAddressSlice.actions

export default userAddressSlice.reducer
