import { Grid, Stack, Typography, Link, Popover, Box } from '@mui/material'
import { useEffect, useState } from 'react'

import Calendar from 'assets/Calendar'
import LeftPanelCard from 'features/ViewAppointment/LeftPanelCard'
import { Strings } from 'utils/Strings'
import AppointmentList from 'features/ViewAppointment/AppointmentList'
import ApptsTab from 'features/ViewAppointment/ApptsTab'
import { MyVisitTM } from 'components/CommonComponents'
import { REDURECTION_URLS } from 'utils/constants/redirection-urls'
import LaunchOutlined from '@mui/icons-material/LaunchOutlined'
import Home from 'assets/House'
import Text from 'components/Text'
import CallPopover from './components/CallPopover'
import MyVisitPopover from './components/MyVisitPopover'
import TextButton from 'components/buttons/TextButton'
import { testAttr } from 'utils/test/test.utils'
import { useLazyPostAppointmentsQuery } from 'store/services/appointmentApi'
import { useLazyGetCustomerDataQuery } from 'store/services/customerApi';
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'hooks/useAuth'
import { getAuthToken, removeItemFromBrowserStorage } from 'utils/common.utils'
import { LOCATION_FINDER } from 'utils/constants/routes-constants'
import { MCCRedirectionUrls } from 'utils/constants/mccRedirectionUrls'
import { resetBookSessionApi } from 'store/services/reservationApi'
import { updateCustomerDetails } from 'store/reducers/customerDetailsReducer'

const MY_VISIT_ID = 'my-visit-id'

const MCCDashboard = ({ ...props }) => {
    const dispatch = useDispatch();
    const [getCustomerData, { data: customerData }] = useLazyGetCustomerDataQuery('');
    const [postAppointments,{ data, error, loading }] = useLazyPostAppointmentsQuery();
    const { source, customerId } = useSelector(state => state.search);
    const [userId, setUserId] = useState('')
    const { user } = useAuth();
	const selectedProvince = useSelector((state) => state.selectedProvince)

    useEffect(() => {
        const get_customer = getCustomerData(user.accountId);
        get_customer.then(datalist => {
            dispatch(updateCustomerDetails(datalist?.data));
            setUserId(datalist?.data.id);
        }).catch(error => {
            console.error('Error fetching customer data:', error);
        }); 
    }, []);

    const accessToken = useSelector(
		(state) => state.auth?.token
	)
    const idToken = useSelector(
		(state) => state.auth?.idToken
	)
    useEffect(() => {
        if(userId || customerId){
            postAppointments({
                customerId: source === 'D365' ? customerId : userId
            });
        }
    }, [source, customerId, userId, postAppointments]);


    const getData = () => {
        postAppointments({
            customerId: source === 'D365' ?
                customerId :
                userId
        });
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const authToken = getAuthToken();
    const appointmentDetails = useSelector((state) => state.appointment)

    useEffect(() => {
        if (accessToken === null && !authToken) {
            removeItemFromBrowserStorage('user');
            window.location.href = MCCRedirectionUrls.Logout.replace('{ID_TOKEN}', idToken).replace('{LOGOUT_REDIRECTION_URL}',selectedProvince.toUpperCase() === 'BC' ? `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_BC_URL}/?province=${selectedProvince}` : `${process.env.REACT_APP_ID4_ACCOUNT_SETTINGS_BASE_ON_URL}/?province=${selectedProvince}`);
        }
    }, [accessToken])

    const handleBeforeUnload = () => {
        localStorage.setItem('appointment', JSON.stringify(appointmentDetails));
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        dispatch(resetBookSessionApi());
        localStorage.removeItem('appState')
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            localStorage.removeItem('appointmentBookingTimeLeft')
        };
    }, [])

    return (
        <Grid container
            sx={{
                flexDirection: { xs: 'column', md: 'row' }
            }}
        >   
            <Box data-testid="mcc-dashboard" display="none"></Box>
            <Grid item
                xs={12} md={4}
                order={{ xs: 2, md: 1 }}
                sx={{ backgroundColor: 'info.dark' }}>
                <Stack sx={{
                    padding: { xs: '1rem', md: '2rem' },
                    paddingLeft: { md: '9rem' }
                }}>
                    <LeftPanelCard
                        muiIcon={<Calendar fillColor="#13499F" />}
                        text={Strings.BOOK_APPOINTMENT_TEXT}
                        linkText={Strings.LINK_LAB_APPOINTMENT_BOOKINGS}
                        link={`${LOCATION_FINDER}?province=${selectedProvince}&source=mcc`}
                    />
                    <LeftPanelCard
                        muiIcon={<Home fillColor="#13499F" />}
                        text={Strings.BOOK_HOME_VISIT_TEXT}
                        linkText={Strings.BOOK_HOME_VISIT_LINK}
                        link={REDURECTION_URLS.HOME_VISIT}
                        showLinkOrButton={false}
                        handleClick={handleClick}
                        id={MY_VISIT_ID}
                        {...testAttr(MY_VISIT_ID)}
                    />

                    <Box
                        marginTop={2}
                        sx={{
                            padding: '.5rem',
                            paddingLeft: '1.5rem',
                            borderLeft: '1px solid black',
                            background: 'transparent'
                        }}>
                        <Grid marginBottom={1}>
                            <MyVisitTM
                                appendString={' ' + Strings.APPOINTMENTS}
                                sx={{ fontWeight: '600' }} />
                        </Grid>
                        <MyVisitTM
                            prependString={Strings.MY_VISIT_TEXT}
                            appendString={Strings.PORTAL + '.'} />
                        <Link
                            sx={{ width: '100%' }}
                            href={REDURECTION_URLS.MY_VISIT}
                            target="_blank"
                            rel="noopener"
                        >
                            <Grid container alignItems='center' sx={{ margin: '0.5rem 0' }}>
                                <MyVisitTM
                                    prependString={Strings.MY_VISIT_LINK}
                                    appendString={Strings.PORTAL}
                                    sx={{
                                        marginRight: '.5rem',
                                        textTransform: 'underline',
                                        fontWeight: '600',
                                        fontSize: '0.81rem',
                                        color: 'primary.dark'
                                    }}
                                />
                                <LaunchOutlined
                                    fontSize="small"
                                    sx={{ fontSize: '1rem' }} />
                            </Grid>
                        </Link>
                    </Box>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12} md={8}
                order={{ xs: 1, md: 2 }}
                sx={{
                    padding: {
                        xs: '1rem',
                        md: '2rem'
                    }
                }}>
                <Stack>
                    <AppointmentList getData={getData}
                        appointments={[...data?.upcomingAppointments || []]} />

                    <ApptsTab
                        previousAppointments={data?.pastAppointments}
                        cancelledAppointments={data?.cancelledAppointments}
                    />
                </Stack>
                <Grid
                    item
                    xs={12} md={8}
                    sx={{
                        marginTop: '3rem',
                        display: {
                            xs: "none",
                            md: "flex"
                        },
                        flexDirection: {
                            xs: "column",
                            md: "row"
                        },
                        padding: {
                            xs: '1rem',
                            md: '2rem'
                        },
                    }}
                >
                    <Grid item
                        sx={{ paddingLeft: '1rem', borderLeft: '1px solid grey', marginBottom: { xs: "1rem", md: "0rem" } }}>
                        <Text.Bold
                            sx={{ marginBottom: '1rem' }}
                            variant='subtitle1'
                            i18nKey='LBL_AUTISM_PATIENT_SUPPORT' />
                        <Typography variant='paragraph3'>
                            {Strings.MESSAGES.AUTISM_SUPPORT}
                            {
                                selectedProvince?.toUpperCase() === 'BC' ? undefined :
                                    (<>{", "}<TextButton
                                        onClick={handleClick}
                                        {...testAttr('call-here-id')}
                                        i18nKey={'LINK_CALL_HERE'}
                                        sx={{ marginLeft: "1px", padding: "0px" }}
                                    /></>)
                            }
                        </Typography>
                    </Grid>
                    <Grid item
                        sx={{
                            paddingLeft: '1rem',
                            borderLeft: '1px solid grey',
                            marginLeft: {
                                xs: "0px",
                                md: "100px"
                            }
                        }}>
                        <Text.Bold
                            variant='subtitle1'
                            sx={{ marginBottom: '1rem' }}
                        >{Strings.FAQ_TITLE}</Text.Bold>
                        <Typography variant='paragraph3'>
                            {Strings.MESSAGES.FIND_ANSWERS}
                            <Link href={REDURECTION_URLS.FAQ} target='_blank'>
                                <Typography variant='paragraph3'>
                                    {Strings.FAQ_LINK}
                                </Typography>
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12} md={8}
                order={3}
                sx={{
                    marginTop: '3rem',
                    display: {
                        xs: "inherit",
                        md: "none"
                    },
                    flexDirection: {
                        xs: "column",
                        md: "row"
                    },
                    padding: {
                        xs: '1rem',
                        md: '2rem'
                    },
                }}
            >
                <Grid item
                    sx={{ paddingLeft: '1rem', borderLeft: '1px solid grey', marginBottom: { xs: "1rem", md: "0rem" } }}>
                    <Text.Bold
                        sx={{ marginBottom: '1rem' }}
                        variant='subtitle1'
                        i18nKey='LBL_AUTISM_PATIENT_SUPPORT' />
                    <Typography variant='paragraph3'>
                        {Strings.MESSAGES.AUTISM_SUPPORT}
                        {selectedProvince?.toUpperCase() === 'BC' ? undefined : (
                            <>{", "}<TextButton
                                onClick={handleClick}
                                {...testAttr('call-here-id')}
                                i18nKey={'LINK_CALL_HERE'}
                                sx={{ marginLeft: "1px", padding: "0px" }}
                            /></>)}
                    </Typography>
                </Grid>
                <Grid item
                    sx={{
                        paddingLeft: '1rem',
                        borderLeft: '1px solid grey',
                        marginLeft: {
                            xs: "0px",
                            md: "100px"
                        }
                    }}>
                    <Text.Bold
                        variant='subtitle1'
                        sx={{ marginBottom: '1rem' }}
                    >{Strings.FAQ_TITLE}</Text.Bold>
                    <Typography variant='paragraph3'>
                        {Strings.MESSAGES.FIND_ANSWERS}
                        <Link href={REDURECTION_URLS.FAQ} target='_blank'>
                            <Typography variant='paragraph3'>
                                {Strings.FAQ_LINK}
                            </Typography>
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Popover
                {...testAttr('mcc-popover')}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    maxWidth: { xs: '99vw', md: '60vw' },
                    borderRadius: '16px'
                }}
            >
                {anchorEl?.id === MY_VISIT_ID ?
                    <MyVisitPopover
                        showMyVisitDialog={anchorEl?.id === MY_VISIT_ID}
                        handleClose={handleClose} /> :
                    <CallPopover
                        handleClose={handleClose} />
                }
            </Popover>

        </Grid>
    );
}

export default MCCDashboard;
