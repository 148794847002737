import { createApi } from '@reduxjs/toolkit/query/react'
import {
	APPOINTMENTS_PATH,
	APPOINTMENT_PATH,
	BOOKING_PATH,
	CANCEL_PATH,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'
import { calculateDurationInHoursBasedOnTimeZone } from 'utils/dateTime.utils'

export const transformResponse = (data, meta, arg) => {
	const sortByStartTimeDesc = (a, b) => new Date(b.startTime) - new Date(a.startTime);
	return {
		upcomingAppointments: data?.flat().filter(
			(appt) =>
				appt?.category === 'Upcoming' &&
				calculateDurationInHoursBasedOnTimeZone(appt.startTime, appt?.timeZone) > 0
		).sort(sortByStartTimeDesc),
		pastAppointments: data?.flat().filter(
			(appt) =>
				(appt?.category === 'Past' || appt?.category === 'Upcoming') &&
				calculateDurationInHoursBasedOnTimeZone(appt.startTime, appt?.timeZone) <= 0
		).sort(sortByStartTimeDesc),
		cancelledAppointments: data?.flat().filter(
			(appt) => appt?.category === 'Canceled'
		).sort(sortByStartTimeDesc),
	}
}

export const appointmentApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'appointments',
	endpoints: (build) => ({
		postAppointments: build.query({
			query: (data) => {
				return {
					url: APPOINTMENTS_PATH,
					method: 'POST',
					body: {
						id: [data.customerId],
					},
				}
			},
			transformResponse,
			transformErrorResponse
		}),

		putUpdateAppointmentDetails: build.mutation({
			query: (data) => {
				return {
					url: APPOINTMENT_PATH,
					method: 'PUT',
					body: { ...data },
				}
			}		
		}),
		postConfirmAppointment: build.query({
			query: (data) => ({
				url: APPOINTMENT_PATH + `/${data?.apptType}` + BOOKING_PATH,
				method: 'POST',
				body: {
					ReservationId: data?.ReservationId,
					customerId: data?.customerId,
					TestTypeId: data?.TestTypeId,
					TestNameId: data?.TestNameId,
					slotId: data?.slotId,
					BookingType: data?.bookingType
				},
			}),
		}),
	}),
})

export const {
	usePostAppointmentsQuery,
	useLazyPostAppointmentsQuery,
	useLazyPostConfirmAppointmentQuery,
	usePutUpdateAppointmentDetailsMutation,
} = appointmentApi
export const resetAppointmentApi = appointmentApi.util.resetApiState
