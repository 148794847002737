import { createSlice } from '@reduxjs/toolkit'

const initialState = {
}

export const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setConfigs: (state, action) => {
			return {
				...state,
				...action.payload
			}
		},
	},
})

export const { setConfigs } = configSlice.actions
export default configSlice.reducer
