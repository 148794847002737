import { Button } from '@mui/material';
import { ReactComponent as Eye } from 'assets/Eye.svg'

const EyeButton = ({ onClick, ...props }) => {
    return (
        <Button onClick={onClick} {...props}>
            <Eye />
        </Button>
    );
}

export default EyeButton;
