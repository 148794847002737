import { createApi } from '@reduxjs/toolkit/query/react'
import {
	CUSTOMER_PATH,
} from './url'
import { baseQueryWithReauth, transformErrorResponse } from './service-utils'

export const transformResponse = (data) => {
	return data || {}
}

export const customerApi = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'customers',
	endpoints: (build) => ({
		getCustomerData: build.query({
			query: (id) => `${CUSTOMER_PATH}/${id}`,
			transformResponse,
			transformErrorResponse
		}),
		postCustomerData: build.mutation({
			query: (data) => ({
				url: `${CUSTOMER_PATH}/${data.customerId}/${data.operationType}`,
				method: 'POST',
				body: {
					...data.customerDetails,
				},
			}),
		}),
		createCustomer: build.query({
			query: (data) => ({
				url: `${CUSTOMER_PATH}`,
				method: 'POST',
				body: {
					...data,
				},
			}),
		}),
	}),
})

export const {
	useLazyGetCustomerDataQuery,
	usePostCustomerDataMutation,
	useLazyCreateCustomerQuery,
} = customerApi
