import {
    Checkbox,
    MenuItem,
    Typography,
} from '@mui/material'
import FilterSelect from 'components/FilterSelect'
import { useSelector } from 'react-redux'
import { Strings } from 'utils/Strings'
import { ReactComponent as FilledCheckbox } from 'assets/FilterIcons/FilledCheckbox.svg'
import { ReactComponent as UncheckedCheckbox } from 'assets/FilterIcons/UncheckedCheckbox.svg'

const ServicesOfferedFilter = ({
    selectedFilters = [],
    handleFilterChange,
    ...props
}) => {
    return (
        <FilterSelect
            formSelectId="services-offered"
            formSelectlabel={Strings.SERVICE_FILTER_TILE}
            count={selectedFilters?.length}
            value={["SERVICES"]}
            multiple
            {...props}
        >
            <ServicesOfferedList 
                handleFilterChange = {handleFilterChange}
                selectedFilters= {selectedFilters}
            />
        </FilterSelect>
    )
}

const ServicesOfferedList = ({
    selectedFilters = [],
    handleFilterChange,
    isMobile,
    textProps
}) => {
    const SERVICES = useSelector(state => state.config.serviceProvided);
    return (
        <>
            {SERVICES?.map((service, index) => (
                <MenuItem
                    key={index + 1}
                    elevation={0}
                    sx={{
                        backgroundColor: isMobile ? 'white' : '#F3F6FA',
                        height: '38px',
                        whiteSpace: 'normal',
                        '&:focus': {
                            backgroundColor: isMobile ? 'white' : 'rgba(25, 118, 210, 0.2)'
                        }
                    }}
                    onClick={(e)=>{
                        e.stopPropagation(e);
                        handleFilterChange('services', service.serviceId)
                    }}
                    tabIndex={0}
                >
                    <Checkbox
                        checked={selectedFilters.indexOf(service.serviceId) > -1}
                        value={service.serviceId}
                        icon={<UncheckedCheckbox width="20px" height="20px" />}
                        checkedIcon={<FilledCheckbox width="20px" height="20px" />}
                    />
                    <Typography
                        variant="paragraph3"
                        sx={{ 
                            color: '#13499F',
                            ...textProps
                        }}
                    >
                        {service.name}
                    </Typography>
                </MenuItem>
            ))}
        </>
    )
}

ServicesOfferedFilter.ServicesOfferedList = ServicesOfferedList;

export default ServicesOfferedFilter

