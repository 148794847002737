import Stack from '@mui/material/Stack'
import SearchResultHeader from './SearchResultHeader'
import Location from './location/Location'
import Availability from './location/Availability'
import RoundedButton from 'components/buttons/RoundedButton'
import { Grid, List, ListItem, ListItemButton } from '@mui/material'
import { Trans } from 'react-i18next'
import Text from 'components/Text'
import useMobile from 'hooks/useMobile'
import { useEffect, useState } from 'react'
import { getProvinceInitials } from 'utils/common.utils'

const LocationsList = ({
	locations,
	setSelectedSort,
	selectedSort,
	handleClickDetails,
	handleEarlApptClick,
	handleClickViewAppt,
	setShowMyVisit,
	selectedLocation,
	setSelectedLocation,
	setUpdateEarliestApptTime,
	handleViewMore,
	paginatedLocationList,
	setUpdateWaitTime
}) => {

	const isMobile = useMobile();

	return (
		<>
			{paginatedLocationList?.length > 0 ? (
				<Stack>
					<SearchResultHeader
						selectedSort={selectedSort}
						locationsCount={paginatedLocationList.length}
						setSelectedSort={setSelectedSort}
					/>
					<List>
						{paginatedLocationList.map((location, index) => {
							return (
								<ListItem
									key={index}
									onClick={() => setSelectedLocation(location)}
									sx={{
										marginTop: {
											xs: 0
										},
										paddingTop: {
											xs: 0
										}
									}}
								>
									<Location
										sx={{
											boxShadow: 'none',
											backgroundColor: location.siteId === selectedLocation?.siteId ? 'info.dark' : 'transparent',
										}}
										id={index + 1}
										location={location}
									>
										<Location.Address location={location} />
										<Location.WalkInTag location={location} />
										<Location.Content
											location={location}
											id={index + 1}
											handleClickDetails={() => handleClickDetails(location)}
											handleEarlApptClick={() => handleEarlApptClick(location)}
											setUpdateEarliestApptTime={setUpdateEarliestApptTime}
											setUpdateWaitTime={setUpdateWaitTime}
										/>
										<ListItemButton
											aria-label='List Item Button'
											autoFocus={location.siteId === selectedLocation?.siteId}
										>
										</ListItemButton>
										<Availability location={location} />
										<Location.Actions
											location={location}
											id={index + 1}
											handleClickViewAppt={() => handleClickViewAppt(location)}
											handleClickBookHomeVisit={(e) => {
												e.preventDefault();
												setShowMyVisit(true)
											}}
											showHomeVisit={getProvinceInitials(location?.address?.province) != "BC"}
										/>
									</Location>
								</ListItem>
							)
						})}
					</List>
					{
						locations?.length !== paginatedLocationList?.length ?
							(<Grid container justifyContent='center' alignItems='center'>
								<RoundedButton
									variant='outlined'
									sx={{
										width: 'fit-content',
										border: '2px solid',
										marginTop: '2rem',
										padding: '1rem',
										fontSize: '1.2rem'
									}}
									onClick={() => handleViewMore()}
								>
									<Trans i18nKey='BTN_VIEW_MORE_LOCS' />
								</RoundedButton>
							</Grid>) : null
					}
				</Stack>
			) : (
				<>
					{
						!isMobile && (
							<Text
								i18nKey='LBL_USE_LOC_FINDER'
								sx={{
									fontWeight: '600',
									textAlign: 'center',
									marginTop: '3rem'
								}}
							/>
						)
					}
				</>
			)}
		</>
	)
}

export default LocationsList
