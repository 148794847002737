import { Typography } from "@mui/material";
import RoundedButton from "../RoundedButton";
import { ReactComponent as ConfirmCalendarIconCTA } from 'assets/ConfirmCalendarCTA.svg'
import { useSaveCallInvite } from "hooks/useSaveCallInvite";
import { testAttr } from "utils/test/test.utils";
import { STRINGS } from "utils/constants/string.constants";

const AddToCalendarButton = ({
    text = STRINGS.APPOINTMENTBOOKINGS_BOOKINGSUCCESS_ADDTOCALENDER,
    appointment = {},
    user = {}
}) => {

    const saveCallInvite = useSaveCallInvite(user,appointment)

    return (
        <RoundedButton
            onClick={saveCallInvite}
            sx={{
                margin: 0,
                padding: 0,
                marginTop: {
                    xs: 0,
                    md: 4
                },
                background: "#13499F",
                width: "15.625rem"
            }}
            {...testAttr('add-to-calendar-btn')}
        >
            <Typography
                variant="paragraph3"
                sx={{
                    color: '#FFFFFF',
                    marginRight: 1
                }}
            >
                {text}
            </Typography>
            <ConfirmCalendarIconCTA stroke='#fff' />
        </RoundedButton>
    )
}

export default AddToCalendarButton;
