import {
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material'
import SmallMap from 'assets/SmallMap.png'
import { ReactComponent as Brightness1Icon } from 'assets/Brightness1.svg'
import Text from 'components/Text'
import { REDURECTION_URLS } from 'utils/constants/redirection-urls'
import LocationPscMap from './LocationPscMap'

const Services = ({ locationDetails }) => {
	return (
		<Stack>
			{locationDetails && Object.keys(locationDetails).length > 0 && <LocationPscMap marker={locationDetails} />}
			<Stack sx={{ backgroundColor: 'info.dark', padding: '1rem 1.5rem' }}>
				<Text variant="subtitle2"
					sx={{ fontWeight: 'bold' }}
					i18nKey='LBL_SERVICES_AVAILABLE' />
				<List>
					{locationDetails?.serviceProvided?.map((service, index) => {
						return (
							<ListItem key={index} sx={{ padding: 0 }}>
								<ListItemIcon sx={{ minWidth: '1.5rem' }}>
									<Brightness1Icon />
								</ListItemIcon>
								<ListItemText>
									{service.name?.indexOf('Autism') > 0 ?
										<Link
											href={REDURECTION_URLS.AUTISM}
											target="_blank"
											rel="noopener"
										>
											<Typography variant='paragraph2'
												sx={{
													color: 'info.light',

												}}
											>
												{service.name}
											</Typography>
										</Link>
										:
										<Typography variant='paragraph2'>
											{service.name}
										</Typography>
									}
								</ListItemText>
							</ListItem>
						)
					})}
				</List>
			</Stack>
		</Stack>
	)
}

export default Services
