import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Stack, Button, MenuItem, MenuList, TextField, FormControl, InputLabel, Input, InputAdornment, IconButton, OutlinedInput } from '@mui/material'

import CircleButton from 'components/buttons/CircleButton'
import { useAddressPredictions } from 'hooks/useAddressPredictions'
import useGeoLocation from 'hooks/useGeoLocation'
import {
	resetUserAddress,
	updateUserAddress,
} from 'store/reducers/userAddressReducer'
import { getHighlightedText } from 'utils/common.utils'
import { testAttr } from 'utils/test/test.utils'

import SearchIcon from 'assets/Search-icon'
import MyLocation from 'assets/Usemylocation'
import LocationPin from 'assets/LocationPin'
import Cross from 'assets/Cross'

import './Search.scss'
import Text from 'components/Text'
import { STRINGS } from 'utils/constants/string.constants'
import useMobile from 'hooks/useMobile'

/* istanbul ignore next */
const Search = ({ searchId }) => {
	const dispatch = useDispatch()

	const userAddress = useSelector((state) => state.userAddress)
	const { source, staffAddress } = useSelector((state) => state.search)
	const { findLocationForAddress, findMyLocation, geoLocationState } =
		useGeoLocation()
	const isMobile = useMobile()

	useEffect(() => {
		if (source === 'D365') {
			if (geoLocationState.loading && !geoLocationState.coordinates) {
				findLocationForAddress(staffAddress)
				setAddress(staffAddress)
			} else {
				setAddress(staffAddress)
				dispatch(
					updateUserAddress({
						address: staffAddress,
						lat: geoLocationState.coordinates?.latitude,
						long: geoLocationState.coordinates?.longitude,
					})
				)
			}
		} else {
			if (geoLocationState?.loading === false) {
				if (geoLocationState.errorMessage) {
					setErrorMessage(geoLocationState.errorMessage)
				}

				if (geoLocationState.geoAddress) {
					setAddress(geoLocationState.geoAddress)
					setIsFocused(true)
				}
			}
		}
	}, [source, dispatch, staffAddress, geoLocationState])

	const [isFocused, setIsFocused] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [isSearching, setIsSearching] = useState(false)

	const [errorMessage, setErrorMessage] = useState('')
	const [address, setAddress] = useState('')

	const predictions = useAddressPredictions(address)

	const resetfindLocation = () => {
		dispatch(resetUserAddress())
		setErrorMessage('')
		setAddress('')
		setIsOpen(false)
		setIsFocused(false)
		setIsSearching(false)
	}

	const handleInputChange = useCallback((input) => {
		setAddress(input)
		setIsFocused(!!input)
		setIsOpen(!!input)
	}, [])

	const handleOnClickPredicition = (value) => {
		if (value) {
			fetch(
				`https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_GEO_CODING_API_KEY}`
			)
				.then((res) => res.json())
				.then((address) => {
					if (address.status === 'OK') {
						geoLocationState.coordinates = {
							latitude: address.results[0].geometry.location.lat,
							longitude: address.results[0].geometry.location.lng,
						}
						setIsFocused(true)
					}
				})
		}
		setAddress(value)
		setErrorMessage('')
		setIsOpen(false)
	}

	const handleOnClick = () => {
		let updatedAddress = {
			...userAddress,
		}

		updatedAddress.address = address

		if (geoLocationState.coordinates) {
			updatedAddress.lat = geoLocationState.coordinates.latitude
			updatedAddress.long = geoLocationState.coordinates.longitude
		}

		dispatch(updateUserAddress({ ...updatedAddress }))
		setIsSearching(true)
	}
	const handleOnClickResetAddress = () => {
		resetfindLocation()
	}

	useEffect(() => {
		if (userAddress?.address) {
			setAddress(userAddress?.address)
			setIsSearching(true)
		}
	}, [userAddress])

	return (
		<Stack {...testAttr(searchId)} id={searchId}>
			<label htmlFor={`${searchId}-txt`}>
				<Text.Bold
					variant="h1"
					sx={{
						lineHeight: { xs: '2.125rem' },
					}}
				>
					<span className="dot" />
					{STRINGS.FIND_LOCATION_LABEL}
				</Text.Bold>

				<Text
					variant="paragraph2"
					sx={{
						padding: '0.45rem 0',
						paddingLeft: {
							xs: '24px',
							md: '0',
						},
					}}
					i18nKey="NOTE_LOCATION"
				/>
			</label>

			<Stack
				alignItems="center"
				sx={{
					flexDirection: { sm: 'column', md: 'row', xs: 'row' },
					gap: {
						xs: '10px',
						md: '0',
					},
				}}
			>
				<Stack className="locationFinder">
					{/* <input
						{...testAttr(`${searchId}-txt`)}
						id={`${searchId}-txt`}
						placeholder={
							isMobile
								? STRINGS.FIND_LOCATION_PLACEHOLDER_MOBILE
								: STRINGS.FIND_LOCATION_PLACEHOLDER
						}
						value={address}
						onChange={(e) => {
							handleInputChange(e.target.value)

							if (e.target.value.replace(/ /g, '').length > 2) {
								setAddress(e.target.value)
								setErrorMessage('')
								setIsSearching(false)
							} else {
								setErrorMessage(STRINGS.ERROR_MINIMUM_STRING)
							}
						}}
					/> */}
						<OutlinedInput
							{...testAttr(`${searchId}-txt`)}
							id={`${searchId}-txt`}
							placeholder={
								isMobile
									? STRINGS.FIND_LOCATION_PLACEHOLDER_MOBILE
									: STRINGS.FIND_LOCATION_PLACEHOLDER
							}
							value={address}
							onChange={(e) => {
								handleInputChange(e.target.value)
								if (e.target.value.replace(/ /g, '').length > 2) {
									setAddress(e.target.value)
									setErrorMessage('')
									setIsSearching(false)
								} else {
									setErrorMessage(STRINGS.ERROR_MINIMUM_STRING)
								}
							}}
							sx={{
								borderRadius: '80px',
								background: 'white',
								border: '1px solid #e0e0e0',
								padding: '0px'
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
									>
										{!isSearching ? (
											<Button
												sx={{
													padding: !isFocused ? '10px 16px' : '10px 0px',
													'& .MuiButton-endIcon': {
														marginRight: 'unset',
														marginLeft: 'unset',
													},
												}}
												{...testAttr(`${searchId}-btn`)}
												id={`${searchId}-btn`}
												variant="contained"
												color="primary"
												endIcon={<SearchIcon fillColor="#FFF" />}
												onClick={() => {
													if (address?.replace(/ /g, '').length > 2) {
														handleOnClick()
														setErrorMessage('')
													} else {
														setErrorMessage(STRINGS.ERROR_MINIMUM_STRING)
													}
												}}
											>
												{!isFocused && (!isMobile && STRINGS.FIND_LOCATION_BTN)}
											</Button>
										) : (
											<Button
												aria-label="Remove Address"
												sx={{
													padding: '10px 0px',
													'& .MuiButton-endIcon': {
														marginRight: 'unset',
														marginLeft: 'unset',
													},
												}}
												{...testAttr(`${searchId}-reset-btn`)}
												id={`${searchId}-reset-btn`}
												variant="contained"
												color="primary"
												endIcon={<Cross fillColor="#FFF" />}
												onClick={handleOnClickResetAddress}
											/>
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
					{isOpen && (
						<MenuList
							{...testAttr('address-predictions')}
							className="predictions"
							sx={{
								padding: '1rem 0',
								position: 'absolute',
							}}
						>
							{predictions?.map((predition, index) => (
								<MenuItem
									key={index + 1}
									onClick={(e) => handleOnClickPredicition(predition)}
									sx={{
										textWrap: {
											xs: 'wrap',
											md: 'inherit',
										},
									}}
								>
									<LocationPin fillColor="#000" />
									{getHighlightedText(predition, address)}
								</MenuItem>
							))}
						</MenuList>
					)}

					{/* {!isSearching ? (
						<Button
							sx={{
								padding: !isFocused ? '10px 16px' : '10px 0px',
								'& .MuiButton-endIcon': {
									marginRight: 'unset',
									marginLeft: 'unset',
								},
							}}
							{...testAttr(`${searchId}-btn`)}
							id={`${searchId}-btn`}
							variant="contained"
							color="primary"
							endIcon={<SearchIcon fillColor="#FFF" />}
							onClick={()=>{
								if (address?.replace(/ /g, '').length > 2) {
									handleOnClick()
									setErrorMessage('')
								} else {
									setErrorMessage(STRINGS.ERROR_MINIMUM_STRING)
								}
							}}
						>
							{!isFocused && (!isMobile && STRINGS.FIND_LOCATION_BTN)}
						</Button>
					) : (
						<Button
							aria-label="Remove Address"
							sx={{
								padding: '10px 0px',
								'& .MuiButton-endIcon': {
									marginRight: 'unset',
									marginLeft: 'unset',
								},
							}}
							{...testAttr(`${searchId}-reset-btn`)}
							id={`${searchId}-reset-btn`}
							variant="contained"
							color="primary"
							endIcon={<Cross fillColor="#FFF" />}
							onClick={handleOnClickResetAddress}
						/>
					)} */}
					<span className="error">{errorMessage}</span>
				</Stack>

				<Stack className="find-my-location" sx={{ marginRight: { xs: '8px' } }}>
					<CircleButton
						title={STRINGS.USE_MY_LOCN}
						circleButtonId="find-my-location"
						onClick={findMyLocation}
						showCross={false}
					>
						<MyLocation fillColor="#13499F" />
					</CircleButton>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default Search
