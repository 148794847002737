import { Card, Link, Stack, Typography } from '@mui/material'
import TextButton from 'components/buttons/TextButton';

const LeftPanelCard = ({ muiIcon, text, linkText, link, showLinkOrButton = true, ...props }) => {
    return (
        <Card
            sx={{ padding: '1rem', marginBottom: '1rem', boxShadow: 'none', borderRadius: '16px', ...props.sx }}
            role='note'
            {...props}>
            <Stack 
                direction={{
                    xs: "row",
                    md: "column"
                }}
            >
                {muiIcon}
                <Typography
                    variant='paragraph3'
                    sx={{ 
                        margin: {
                            xs: '-3px 0 0 12px',
                            md: '0.51rem 0' 
                        }
                    }}
                    aria-label='note'
                    role="note"
                    color='primary.main'
                >
                    {text}
                </Typography>
            </Stack>
            <Stack 
                marginLeft={{
                    xs: '27px',
                    md: '0'
                }}
                marginTop={{
                    xs: '8px',
                    md: '0'
                }}
            >
                {showLinkOrButton ?
                    <Link href={link} rel='noopener' aria-label={linkText} role='link'>
                        <Typography variant='paragraph3' role='note' sx={{ color: 'primary.main', fontWeight: '600' }}>
                            {linkText}
                        </Typography>
                    </Link> :
                    <TextButton
                        onClick={props.handleClick}
                        id={props.id}
                        sx={{ justifyContent: 'flex-start', padding: 0, textDecoration: 'underline' }}>
                        <Typography variant='paragraph3' role='note' sx={{ color: 'primary.main', fontWeight: '600' }}>
                            {linkText}
                        </Typography>
                    </TextButton>}
            </Stack>
        </Card>
    );
}

export default LeftPanelCard;
